import React, { useEffect, useRef } from 'react'
import { GetAdminUser } from '../../../../api/auth/Auth'
import { useDispatch } from 'react-redux'
import { addAllUserData } from '../../../../slice/Admin/allUserSlice/AllUSerSlice'


type Props={
    isStart:boolean,
    resetAllUser:()=>void
}
const GetAllUser = ({isStart,resetAllUser}:Props) => {
    const isApi= useRef(true)
    const dispatch= useDispatch()
    useEffect(()=>{
        if(isStart && isApi.current){
            isApi.current= false
            getAdminuser()
        }
    },[isStart])

    const getAdminuser=async()=>{
        try{
            const response= await GetAdminUser();
            console.log("all user",response)
            if(response && response.status==200){
               dispatch(addAllUserData({
                user:response.data,
                response:"get all user"
               }))

               isApi.current= true
               resetAllUser()
            }

        }catch(err){
                  
            alert("Error in getting all users")
            isApi.current= true
            resetAllUser()
        }
    }
  return (
    <>
    </>
  )
}

export default GetAllUser