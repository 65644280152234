import axios from 'axios';
import store from "../../store/Store"; // Moved this import to the top

const serverApi = process.env.REACT_APP_SERVER_URL;

// Create an Axios instance
const apiClient = axios.create({
  baseURL: serverApi,
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Get the token from the Redux store
    const state = store.getState();
    const currentUserToken = state.user.user[0]?.token; 
     //console.log("currentUserToken",currentUserToken)
    if (currentUserToken) {
      config.headers['Authorization'] = `Bearer ${currentUserToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
