import React from 'react'
import ShareProjectPage from '../../module/sharePage/ShareProjectPage'

const ShareproPage = () => {
  return (
    <div>
     <ShareProjectPage/>
    </div>
  )
}

export default ShareproPage
