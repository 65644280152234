import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMasterArray } from '../../../slice/canvas/masterArray/MasterArraySlice'
import { JobSegmentModel, UpdateJobModel } from '../../../Model/Job/JobModel'
import { NewSegment } from '../../../Model/Job/SamModel'
import { MasterModel } from '../../../Model/masterArray/MasterArrayModel'
import { addJobData } from '../../../slice/userJobSlice/UserJobSlice'

  type Props={
    resetJobUser:(data:UpdateJobModel)=>void
  }

const UpdateJobToUserJob = ({resetJobUser}:Props) => {

    const dispatch= useDispatch()
    const getMasterArrays = useSelector(getMasterArray)
     const isApi= useRef(true)
     
    useEffect(()=>{
        if(getMasterArrays && getMasterArrays.allSeg && isApi.current){
            isApi.current= false
            const newallseg:JobSegmentModel[]=[]
            const allSegMents= getMasterArrays.allSeg
            if(allSegMents && allSegMents.length>0){
                allSegMents.map(item=>{
                      const childSeg= item.segmentation
                   if(childSeg && childSeg.length>0){
                    childSeg.map(cSeg=>{
                        Object.keys(cSeg).forEach(grp=>{
                            cSeg[grp].map(eachSeg=>{
                                if(eachSeg.segName && eachSeg.details){
                                    const data={
                                        [eachSeg.segName]:{
                                            details:eachSeg.details,
                                            designer:eachSeg.designer
                                        }
                                    }
    
                                    newallseg.push(data)
                                }
                               
                            })
                           
                       })
                    })
                    
                      
                    }
                })
            }
            if(newallseg && newallseg.length>0){
                
                upadatedJobStructure(getMasterArrays,newallseg)
            }
        }
    },[getMasterArrays])


    const upadatedJobStructure=(masterArray:MasterModel, newallseg:JobSegmentModel[])=>{
        if(newallseg && newallseg.length>0){
            const data:UpdateJobModel={
                id:masterArray.jobId ??0,
                project_id: masterArray.project_id??0,
                job_json:JSON.stringify(newallseg)??"",
              image:masterArray.image ,
              compressImage:masterArray.compressionImage,
              job_view: "Front",
              isArch: false,
              // base64:"",
              seg_labels_count:{},
              dimension_pixel:masterArray.dimension_pixel,
              segments:newallseg,
             // base_64: JSON.stringify(getModelDatas[0].Base64)
            }
            //dispatch(addJobData(data))
            isApi.current=true
           resetJobUser(data)
        }
    }
  return (
    <div>
        
    </div>
  )
}

export default UpdateJobToUserJob