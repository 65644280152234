import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CanvasControlModel, GroupMasterSegment, JobSegmentationModel, MasterModel, ReAnnotationModel } from "../../../Model/masterArray/MasterArrayModel";


interface canvasControlStates {
   
    canvasControl:CanvasControlModel,
    isSelectedCanvas:boolean,
    isDeleteSegment:boolean
    isDelete:boolean,
    isFillPolygon:boolean
}

const initialState: canvasControlStates = {
    canvasControl:{},
    isDelete:false,
    isSelectedCanvas:false,
    isDeleteSegment:false,
    isFillPolygon:false
    

};


const CanvasControlSlice = createSlice({
    name: "canvasControl",
    initialState,
    reducers: {
      resetCanvasControlState:(state)=>{
   return initialState
      }, 
        addCanavasControl:(state,action)=>{
            const {groupName,subGroupName,segShortName,segName,isMainComment}= action.payload
            
            state.canvasControl.groupName=groupName
            state.canvasControl.subGroupName= subGroupName
            state.canvasControl.segShortName= segShortName
            state.canvasControl.segName= segName
            state.canvasControl.isMainComment=isMainComment
            state.isSelectedCanvas=true
        },
        updateisSeletedCanvas:(state)=>{
          state.isSelectedCanvas= false
        },
         resetCanvasControl:(state)=>{

                state.canvasControl={}
        }, 
        
        openCloseDeleteSegment:(state,action)=>{
           state.isDelete= action.payload
        },
        startDeletingSegment:(state)=>{
            state.isDeleteSegment=true
        },
        stopDeletingSegment:(state)=>{
            state.isDeleteSegment= false
        },
         updateFillPolygon:(state,action)=>{
            state.isFillPolygon= action.payload
        }
       
    }
})

export const {addCanavasControl,
    resetCanvasControl,
    startDeletingSegment,
    stopDeletingSegment,
    openCloseDeleteSegment,
    updateFillPolygon,
    updateisSeletedCanvas,
    resetCanvasControlState
}=CanvasControlSlice.actions;
 export const getCanvasControl=  (state:{canvasControl:canvasControlStates})=>state.canvasControl.canvasControl
 export const getDeleteStatus=  (state:{canvasControl:canvasControlStates})=>state.canvasControl.isDeleteSegment
 export const getDeleteModalStatus=  (state:{canvasControl:canvasControlStates})=>state.canvasControl.isDelete
 export const getFillPolygon=  (state:{canvasControl:canvasControlStates})=>state.canvasControl.isFillPolygon
 export const getIsSelectedCanvas=  (state:{canvasControl:canvasControlStates})=>state.canvasControl.isSelectedCanvas
export default CanvasControlSlice.reducer