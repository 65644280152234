import React, { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import ReAnnoatationModal from '../modal/ReAnnoatationModal';
import { getCanvasSize } from '../../../../slice/userScreenSlice/userScreenResolution';
import {  getSelectedSegment, getUserJobBase64 } from '../../../../slice/userJobSlice/UserJobSlice';
import { getMasterArray, getReAnnotationSegment, updateAnnotationPoint } from '../../../../slice/canvas/masterArray/MasterArraySlice';
import MagnifierCanvas from './MagnifierCanvas';
import { PointModel } from '../../../../Model/masterArray/MasterArrayModel';
import "./Mangnifier.css"
import { startLoading, stopLoading } from '../../../../slice/loading/LoadingSlice';
import { StartUpateMasterArrayModel } from '../../../../Model/masterArray/UpdateIndexModel';
import { changeSegmentTab, switchToOutline } from '../../../../slice/tabControl/TabControlSlice';
import { getSegregateSegment, updateAnnoatationInSegegtated } from '../../../../slice/segegratedSlice/SegregatedSlice';
import AnnotationUpdateApi from '../../masterDataAnotationApi/AnnotationUpdateApi';
import { SegmentDetails } from '../../../../Model/Job/SamModel';
import { getToolActiveNumber, UpdateToolActive } from '../../../../slice/toolActive/ToolActiveSlice';
interface CustomCircleOptions extends fabric.ICircleOptions {
  id?: number;
  className?: string;
}
interface CustomLineOptions extends fabric.ILineOptions {
  id?: number;
  className?: string;
}

interface CustomFabricObject extends fabric.Object {
  id?: string | number;
}
const ReAnnotationCanvas = () => {
  const navigate = useNavigate()
    const canvasRef = useRef<fabric.Canvas|null>(null);
    const canvasElementRef = useRef<HTMLCanvasElement | null>(null);
  
    
    const getUserJobBase64s = useSelector(getUserJobBase64);
    const [scaleX, setScalex] = useState<number|null|undefined>();
    const [scaleY, setScaley] = useState<number|null|undefined>();
   

    const getMasterArrays= useSelector(getMasterArray)
    const getSegregateSegments= useSelector(getSegregateSegment)
    const getReAnnotationSegments= useSelector(getReAnnotationSegment)
   
    const [allpoints, setAllPoints]= useState<number[]>([])
    const allPointsRef = useRef<number[]>([]);
    const [lastPOintId,setLastPOintId]= useState<number[]>([])
    const lastPOintIdRef= useRef<number[]>([])

    const [firstpoints, setFirstpoints]= useState<number[]>([])
    const [isShowDistance, setIsShowDistance]= useState<boolean>(false)
    const [mousePoint, setMousePoint]= useState<number[]>([])
    const [zoomFactor, setZoomFactor] = useState(2); // Control zoom level
    const [zoomPosition, setZoomPosition] = useState<PointModel>(); // Position of the zoom on hover
    const [segName, setsegName]= useState<string|null>(null)
    const [segShortName, setSegShortName]= useState<string|null>(null)
    const [groupName, setGroupName]= useState<string|null>(null)
    const [childGroupName, setChildGroupName]= useState<string|null>(null)
  const getSelectedSegments = useSelector(getSelectedSegment)
  const getToolActiveNumbers= useSelector(getToolActiveNumber)
  
    const isApi= useRef(true)
    let polygonMode: boolean = true;
    const dispatch= useDispatch()
    let pointArray: { x: number; y: number }[] = []
    let lineArray: fabric.Line[] =new Array()
    //let activeLine: fabric.Line | undefined|null;
    let activeLine: fabric.Line | null = null; 
    const lineArrays: fabric.Line[] = [];
    const pointArrays: fabric.Circle[] = [];
    let hoverCircle: fabric.Circle | null = null;
    let activeShape: fabric.Polygon | undefined = undefined;
    const [jobId, setJobId]= useState<number|null>(null)
   
    const[ isShowModal, setIsShowModal]= useState<boolean>(false)
    const [ isUpdatemasterdata, setIsUpdatemasterdata]= useState<boolean>(false)
    const [annotation, setAnnotation]= useState<number[]>([])
  


    let min = 99;
    let max = 999999;
    
    // update the first point
    const isFirst= useRef(true)
    useEffect(()=>{
      if(allpoints && 
        allpoints.length>0 &&
         canvasRef.current &&
         isFirst.current){
          isFirst.current= false
          //setFirstpoints([allpoints])
          allPointsRef.current=allpoints
        //console.log("hello")
        // Create a new circle if none exists
        const circle = new fabric.Circle({
          name: 'firstpoint',
          radius:2 ,
          fill: "black",
          stroke: "black",
          strokeWidth: 2,
          left: allpoints[0],
          top: allpoints[1],
          selectable: false,
          hasBorders: false,
          hasControls: false,
          originX: 'center',
          originY: 'center',
          visible: true
        } as CustomCircleOptions)
       
        const hoverCircle = new fabric.Circle({
          name: 'finalPoint',
          radius:6 ,
          fill:"transparent",
          stroke: "black",
          strokeWidth: 2,
          left: 0,
          top: 0,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          originX: 'center',
          originY: 'center',
          visible: false
        } as CustomCircleOptions)
        canvasRef.current.add(hoverCircle);
        canvasRef.current.add(circle);
      
        canvasRef.current.renderAll();
        canvasRef.current.hoverCursor = "none";
      }
    },[allpoints,canvasRef])
   
  // update id  wrt to ref
  useEffect(()=>{
    if(lastPOintId && lastPOintId.length>0){
      lastPOintIdRef.current= lastPOintId
    }
  }
  ,[lastPOintId])

    // get the new segment name
      useEffect(()=>{
        if(getSelectedSegments && getSelectedSegments.length>0){
          const selected=getSelectedSegments[0]
           const grpseg=selected.details
           if(grpseg &&
            grpseg.seg_short &&
            grpseg.label &&
            grpseg.seg_type &&
            grpseg.group
          ){
            setSegShortName(grpseg.seg_short)
            setsegName(grpseg.label)
            setGroupName(grpseg.seg_type)
            setChildGroupName(grpseg.group)
           }
        }
      },[getSelectedSegments])

     

   
     // create canvas 
    const isCanvas= useRef(true)
    useEffect(() => {
      if (canvasElementRef.current && isCanvas.current) {
        isCanvas.current= false
        // Initialize Fabric canvas
        const rightSection = document.querySelector('.canvas-right') as HTMLElement;
        const canvas = new fabric.Canvas(canvasElementRef.current, {
          width:rightSection==null? window.innerWidth: window.innerWidth - rightSection.offsetWidth, // Adjust width
          height: window.innerHeight,
          backgroundColor: '#f0f0f0', // Canvas background
        });

        const dimension = new fabric.Group([], { 
          selectable: false, 
          name: 'dimension',
          visible: true ,
          hasBorders: false,
            hasControls: false,
        });
        canvas.add(dimension);
      canvas.requestRenderAll();
        canvasRef.current = canvas;
        canvas.on('mouse:down', (event) => {
          // console.log("event",event)
          handleMouseDown(event);
        });
        
        canvas.on('mouse:up',
          (event) => {
            handleMouseUp(event);
         });
         canvas.on('mouse:move', (event) => {
          handleMouseMove(event); // Add mouse move event to show the dynamic point/line
        });
         canvas.on('mouse:over', (event) => {
          handleMouseOver(event); // Add mouse move event to show the dynamic point/line
        });

        canvas.on('mouse:wheel',
          (event) => {
            //handleMouseWheel(event);
         });
        //  canvas.on('mouse:out', (event) => {
        //   handleMouseOut(event); // Add mouse move event to show the dynamic point/line
        // });
         
        return () => {
          // Clean up the canvas when the component unmounts
          if (canvasRef.current) {
            canvasRef.current.off('mouse:down', handleMouseDown);
            canvasRef.current.off('mouse:up', handleMouseUp);
            canvasRef.current.off('mouse:move', handleMouseMove);
          }
        
        }
        
      } else{
        isCanvas.current= true
      }
    }, []);
    

   useEffect(()=>{
   
    if(mousePoint.length>0  &&
       allpoints &&
        allpoints.length==0){
       setIsShowDistance(true)
      showDistanceCordinate([],mousePoint,false)
    }else if(allpoints && allpoints.length>0 &&
      mousePoint &&mousePoint.length>0){
        const pointLength= allpoints.length
       const points=[allpoints[pointLength-2],allpoints[pointLength-1 ]]
        showDistanceCordinate(points,mousePoint,true)
    }
   },[allpoints, mousePoint, canvasRef.current, isShowDistance])

    // handle mouse move
    const handleMouseMove = (options: fabric.IEvent) => {
      showMagnifier(options)
      const pointer = options.pointer;
     
      
      handleZoom(options)
      if(pointer && 
        canvasRef.current &&
         canvasRef.current.width &&
         canvasRef.current?.height){
        drawLines(pointer.x??0, pointer.y??0);
        setMousePoint( [ pointer?.x??0, pointer?.y??0])
      
      }
    
     
    };
    
    const drawLines = (mouseX: number, mouseY: number) => {
      // Ensure canvasRef.current is a valid Fabric.js Canvas instance
      const canvas = canvasRef.current;
  
      // Check if the canvas exists before proceeding
      if (!canvas) {
          return; // Exit if canvas is null
      }
  
      // Clear previous lines (assuming you want to only display the latest lines)
      canvas.getObjects('line').forEach(line => {
          canvas.remove(line);
      });
  
//       // Change cursor to plus icon
// canvas.defaultCursor = 'crosshair'; // 'crosshair' shows a plus-like cursor


      // Optionally, you can also change the cursor when hovering over objects
canvas.hoverCursor = 'crosshair';

     // Create horizontal dashed line
const horizontalLine = new fabric.Line([0, mouseY, canvas.width ?? 0, mouseY], {
  stroke: 'white', // White color for the stroke
  strokeWidth: 2,
  selectable: false,
  strokeDashArray: [8, 8], // Dashed pattern: 5px dashes and 5px gaps
  opacity: 0.5, // Makes the line semi-transparent
});

// Create vertical dashed line
const verticalLine = new fabric.Line([mouseX, 0, mouseX, canvas.height ?? 0], {
  stroke: 'white', // White color for the stroke
  strokeWidth: 2,
  selectable: false,
  strokeDashArray: [8, 8], // Dashed pattern: 5px dashes and 5px gaps
  opacity: 0.5, // Makes the line semi-transparent
});
      // Add lines to canvas
      canvas.add(horizontalLine);
      canvas.add(verticalLine);
      canvas.renderAll();
  };
  

      const showDistanceCordinate=(allpoints:number[],mousePoint:number[],isShowDistance:boolean)=>{
     
        if (canvasRef.current && mousePoint) {
          const canvasObjects = canvasRef.current.getObjects();
          if (canvasObjects) {
            // Find the "dimension" group (if necessary)
            const matchingGroup = canvasObjects.find(grp => grp.name === "dimension") as fabric.Group | undefined;
            let existingText = canvasRef.current.getObjects().find(obj => obj.name === 'showDim') as fabric.Text | undefined;
      
            // If the text doesn't exist, create it and add it to the canvas
            if (!existingText) {
              existingText = new fabric.Text(``, {
                left: mousePoint[0] + 10,  // Adjust position to avoid cursor
                top: mousePoint[1] - 20,  // Adjust position to avoid cursor
                name: 'showDim',
                fontFamily: 'Arial',
                fontSize: 12,         // Smaller font size for crisp appearance
                fill: 'white',       // Text color
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black background with slight opacity
                selectable: false,  // Disable selection of the text
                evented: false,     // Ensure text is not selectable
                visible: true,
              });
      
              // Add the new text to the canvas
              canvasRef.current.add(existingText);
              canvasRef.current.requestRenderAll();
            }
      
            // Update the text content and position dynamically
            if(!isShowDistance){
              
              existingText.set({
                left: mousePoint[0]+ 10,  // Adjust position to avoid cursor
                top: mousePoint[1] - 20,  // Adjust position to avoid cursor
                text: `(${mousePoint[0].toFixed(2)}, ${mousePoint[1].toFixed(2)})`,  // Update the displayed coordinates
              });
            }
            else if (isShowDistance ){
              const distanceX=allpoints[0]-mousePoint[0]
              const distanceY=allpoints[1]-mousePoint[1]
              const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
              existingText.set({
                left: mousePoint[0]+ 10,  // Adjust position to avoid cursor
                top: mousePoint[1] - 20,  // Adjust position to avoid cursor
                text: `${distance.toFixed(2)}`,  // Update the displayed coordinates
              });
            }
           
      
            // Update the canvas rendering after modifying the text
            existingText.setCoords();  // Ensure the internal coordinates are updated
      
            // Ensure the text object is brought to the front
            canvasRef.current.bringToFront(existingText);
            canvasRef.current.requestRenderAll();  // Render the canvas
          }
        }
      }
    
    
    // ad image on canavas
    useEffect(() => {
      if (getUserJobBase64s && canvasRef.current) {
        const image = getUserJobBase64s;
        const encodedImage = "data:image/png;base64," + image;
        //const encodedImage =  image;
        const decodedImage = atob(encodedImage.split(",")[1]);
        const uint8Array = new Uint8Array(decodedImage.length);
        for (let i = 0; i < decodedImage.length; i++) {
          uint8Array[i] = decodedImage.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "image/png" });
        const imageUrl = URL.createObjectURL(blob);
  
        fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
          const canvas = canvasRef.current;
          if (canvas && canvas.width && canvas.height && img.width && img.height) {
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
  
            img.scaleToWidth(canvasWidth);
            img.scaleToHeight(canvasHeight);
  
            canvas.setBackgroundImage(img, canvas.requestRenderAll.bind(canvas), {
              scaleX: canvasWidth / img.width,
              scaleY: canvasHeight / img.height,
              originX: 'left',
              originY: 'top',
            });
            setScalex(canvasWidth / img.width);
            setScaley(canvasHeight / img.height);
            canvas.requestRenderAll();
           
          }
        });
      }
    }, [getUserJobBase64s]);

    const handleMouseDown = (options:fabric.IEvent) => {
      const pointer = canvasRef.current?.getPointer(options.e);
      // console.log("pointer",pointer)
      // console.log("allpoints.ref",allPointsRef.current)
       // const target=options.target as CustomFabricObject
        if( pointer && allPointsRef.current && allPointsRef.current.length>0){
          const point1=allPointsRef.current[0]
          const point2=allPointsRef.current[1]

          const top = pointer.y; 
          const left = pointer.x;
         
          const tolerance = 5; // Adjust this tolerance value as needed
              
           if (Math.abs(top - point2)< tolerance && Math.abs(point1 - left) < tolerance) {
            setIsShowModal(true)
               polygonMode=false
           }
          
        
        }
       
    if(polygonMode && canvasRef.current ){
      //console.log("canvasRef.current 4" )
     
       addPoint(options);
    }
      
    
    };


    const handleMouseWheel=(event:fabric.IEvent)=>{
      //console.log("mouse Wheel",event)
      const deltaE = event.e as WheelEvent
      if(deltaE && canvasRef.current){
        const delta = deltaE.deltaY;
        let zoom = canvasRef.current.getZoom();
        zoom *= 0.999 ** delta; 
       
       if (zoom > 20) zoom = 20; // Set maximum zoom level
      if (zoom < 0.01) zoom = 0.01; // Set minimum zoom level
      canvasRef.current.zoomToPoint({ x: deltaE.offsetX, y: deltaE.offsetY }, zoom);
     deltaE.preventDefault();
     deltaE.stopPropagation();
      }
    
    
    }

    const handleMouseUp = (options:fabric.IEvent) => {
     
     drawPolygon()
      
    };

    const handleMouseOver = (options: fabric.IEvent) => {
      
      // Get the pointer location
      const pointer = canvasRef.current?.getPointer(options.e);
     
      if (pointer ) {
        const target = options.target as CustomFabricObject;
        const top = pointer.y; 
        const left = pointer.x;
      
        if (target &&
          target.name &&target.name==="firstpoint" &&
           canvasRef.current && top && left) {
          const targetId = target?.id || 0;
        
          const canvasObjects = canvasRef.current.getObjects();
    
          // Find the matching object by ID
          const matchingObject = canvasObjects?.find((obj: CustomFabricObject) => obj.name === "finalPoint");

          
          if (matchingObject) {
        
              // Move the existing circle to the new position (with some tolerance)
              const tolerance = 30; // Adjust this tolerance value as needed
              const circleTop = matchingObject.top || 0;
              const circleLeft = matchingObject.left || 0;
    
              if (Math.abs(circleTop - top) > tolerance || Math.abs(circleLeft - left) > tolerance) {
                // Update the position of the existing circle
                matchingObject.set({
                
      
                  stroke: "red",
                  left: left,
                  top: top,
                  visible:true
                });
                canvasRef.current.renderAll();
              }
           
              
          } 
          
        }else if(canvasRef.current 
          && target &&
          target.name!="firstpoint"
        ) {
          const canvasObjects = canvasRef.current.getObjects();
    
          const matchingObject = canvasObjects?.find((obj: CustomFabricObject) => obj.name === "finalPoint");
           
          if (matchingObject) {
        

              matchingObject.set({
              
    
                stroke: "red",
                left: left,
                top: top,
                visible:false
              });
              canvasRef.current.renderAll();
            
         
            
        } 
        }
      }
    };
    
    
    // Handle mouse out to remove the hover circle
    const handleMouseOut = (options: fabric.IEvent) => {
 
    
      // Remove the hover circle if it exists
      if (hoverCircle && canvasRef.current) {
        canvasRef.current.remove(hoverCircle);
        hoverCircle = null; // Reset the hoverCircle
        canvasRef.current.renderAll();
      }
    };
    const drawPolygon = () => {

      polygonMode = true;
     
     
    };

    const addPoint = (options: fabric.IEvent) => {
      const random = Math.floor(Math.random() * (max - min + 1)) + min;
      const id = new Date().getTime() + random;
      setLastPOintId(prev => [...prev, id]);
      const circle = new fabric.Circle({
        radius: 1,
        fill: '#000000',
        stroke: 'rgb(255, 1, 154)',
        strokeWidth: 3,
        id:id,
        className:'point',
        left: options.pointer?.x,
        top: options.pointer?.y,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
      }  as CustomCircleOptions);
  
     
      if (!circle.left || !circle.top) return;
  
      if (pointArrays.length === 0) {
        circle.set({ fill: '#000000' }); // First point is black
      } else {
        circle.set({ fill: '#ff0000' }); // Other points can be a different color (e.g., red)
      }
       pointArray.push({x:circle.left,y:circle.top})
       const points: [number, number, number,number] = [circle.left, circle.top,circle.left,circle.top];
    
        
  
      if ( activeShape ) {
        canvasRef.current?.remove(activeShape);
        const pos = canvasRef.current?.getPointer(options.e);
        if (pos) {
          const points = activeShape?.get('points') ?? [];
          points.push(new fabric.Point(pos.x, pos.y)); // Use fabric.Point
          activeShape.set({ points: points });
          var polygon = new fabric.Polygon(points,{
            stroke:'rgb(7 239 253)',
              strokeWidth:2,
              fill: 'transparent',
              opacity: 0.9,
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: false,
              name:"polygon"
          });
         canvasRef.current?.remove(activeShape);
           canvasRef.current?.add(polygon);
            
           activeShape = polygon;
        
          canvasRef.current?.renderAll();
        }
        
       
      } else {
        const x = options.pointer?.x ?? 0; // Ensure x is a number, use 0 if undefined
        const y = options.pointer?.y ?? 0; // Ensure y is a number, use 0 if undefined
        
        const newPolyPoints = [{ x, y }];
        const polygon = new fabric.Polygon(newPolyPoints,{
          stroke:'#333333',
          strokeWidth:1,
          fill: '#cccccc',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          name:"poly"
      });
      activeShape=polygon
       canvasRef.current?.add(polygon)
      }
     // activeLine = line;
        // console.log("poinst",points)
        setAllPoints((prev) => [
          ...prev,           
          points[0],       
          points[1],
        ]);
      pointArrays.push(circle);
     // lineArray.push(line);
      //canvasRef.current?.add(line);
      canvasRef.current?.add(circle);
      //console.log("canvasRef.current====>",canvasRef.current)
    };
  
  
    const [isCompletedPolygon, setIsCompletedPolygon]= useState(false)



    const handleCloseModal=()=>{
      setIsShowModal(false)
      
    }

    const handleOk=()=>{

      setIsShowModal(false)
     // setAllReAnnoatationPoints.push(pointArrays)
     convertToBoundingBox(allpoints)
    }
   

 

  function convertToBoundingBox(flattenedPoints:number[]) {
    if(scaleX && scaleY){
 
     let  newFlattenedPoints=[]
      const points = flattenedPoints;
 
       
      for (let i = 0; i < points.length; i += 2) {
        const x = points[i]/(scaleX??1);
        const y = points[i + 1]/(scaleY??1);
        newFlattenedPoints.push(parseFloat(x.toFixed(2)),parseFloat(y.toFixed(2)))
       
      }
  
    
     if( newFlattenedPoints.length>0 &&
      segName &&
      childGroupName &&
      segShortName  && 
      groupName ){
       
        setIsUpdatemasterdata(true)
           setAnnotation(newFlattenedPoints)

      }
  
  }
 
}


     useEffect(()=>{
        if(getMasterArrays && getMasterArrays.jobId){
            setJobId(getMasterArrays.jobId)
        }else{
            setJobId(null)
        }
     },[getMasterArrays])
  const handlehoverLayer=()=>{

    // localStorage.setItem("getMasterArray",JSON.stringify(getMasterArray))
    // localStorage.setItem('getSegregateSegment',JSON.stringify(getSegregateSegment))
    dispatch(switchToOutline("segment"))
    dispatch(changeSegmentTab('segment'))
      //dispatch(changeCanvasMode("hoverLayer"))
  }

 // add maginier kens
 const handleZoom = (options: fabric.IEvent) => {
    const canvas = canvasRef.current; // Store the reference in a variable
    if (!canvas || canvas.width === undefined || canvas.height === undefined) return; // Check if canvasRef.current is null or dimensions are undefined

    const pointer = canvas.getPointer(options.e);
    
    // Clear previous magnifier effect
    // canvas.getObjects().forEach(obj => {
    //     if (obj.name === 'magnifier') {
    //         canvas.remove(obj);
    //     }
    // });

    const magnifierSize = 100; // Size of the magnifier area
    const magnifiedImage = new fabric.Image(canvas.toDataURL(), {
        left: pointer.x - magnifierSize / 2,
        top: pointer.y - magnifierSize / 2,
        scaleX: magnifierSize / canvas.width,
        scaleY: magnifierSize / canvas.height,
        selectable: false,
        evented: false,
        name: 'magnifier',
    });

    canvas.add(magnifiedImage);
    canvas.renderAll();
};

const handleResetAnotation=(data:SegmentDetails)=>{
  setAnnotation([])
  setIsUpdatemasterdata(false)
  dispatch(stopLoading())

  const masterValue={
    segName:segName,
    childgroupName:childGroupName,
    seg_short:segShortName,
    groupName:groupName,
    masterData:data
  }
  
  dispatch(startLoading())
  // update in segretaed  Array
  dispatch(updateAnnoatationInSegegtated(masterValue))
     
      dispatch(switchToOutline("segment"))
       dispatch(changeSegmentTab('segment'))
}
const handleFailResetAnnotation=()=>{
  setAnnotation([])
  setIsUpdatemasterdata(false)
  dispatch(stopLoading())

  dispatch(switchToOutline("segment"))
  dispatch(changeSegmentTab('segment'))
}

 //  call Undo from tool
 useEffect(()=>{
  
  if(getToolActiveNumbers==3){
    console.log("called undo")
    undoLastAction()
    dispatch(UpdateToolActive(0))
  }
},[getToolActiveNumbers])

// undo Action
const undoLastAction = () => {
 

  const idLength=lastPOintIdRef.current.length
  if (allpoints.length > 0 && 
    canvasRef.current && 
    lastPOintIdRef.current &&
    idLength
  ) {
    const getPolygonObject = canvasRef.current.getObjects().filter(item => item.name === "polygon") as fabric.Polygon[];
    const getLastPoint = canvasRef.current.getObjects().filter((item: CustomCircleOptions) => item?.id === lastPOintIdRef.current[idLength-1]);
    console.log("getLastPoint", getLastPoint);
    if (getPolygonObject && getPolygonObject.length > 0 && getLastPoint.length > 0) {
      // Remove the last point circle
      canvasRef.current.remove(getLastPoint[0]);

     
      const points = getPolygonObject[0].points;
      if (points && points.length > 0) {
        points.pop(); // Remove the last point

        // Update the polygon with the new points array
        getPolygonObject[0].set({ points });
        
        // Redraw polygon to refresh connections
        getPolygonObject[0].dirty = true;
        canvasRef.current.renderAll();
        
        // Optional: Update your points array (allpoints) if needed for further undo actions
        allpoints.pop(); // Assuming allpoints tracks the polygon point coordinates
        
        console.log("Updated Polygon Points:", points);
         // update the id
        // Update the lastPOintId after deletion
        setLastPOintId(prev => {
          const updatedIds = [...prev]; // Create a copy of the previous state
          updatedIds.pop(); // Remove the last ID
          lastPOintIdRef.current = updatedIds; // Update the ref
          return updatedIds; // Return the updated state
      });
      }
    }
  }
};



// Function to show magnifier
const showMagnifier = (options: fabric.IEvent) => {
  const canvas = canvasRef.current as fabric.Canvas; // Type assertion to fabric.Canvas
  if (!canvas || canvas.width === undefined || canvas.height === undefined) return;

  const pointer = canvas.getPointer(options.e); // Get pointer position

  // Create a new magnified image based on the current canvas
  const zoomedCanvas = document.createElement('canvas');
  
  zoomedCanvas.width = 140; // Size of the zoomed area
  zoomedCanvas.height = 140; // Size of the zoomed area
  const zoomedContext = zoomedCanvas.getContext('2d');
  // Draw the zoomed area onto the new canvas
  zoomedContext?.drawImage(
    canvas.getElement(),
    pointer.x - 70, // Center the zoomed area
    pointer.y - 70, // Center the zoomed area
    140, // Width of the zoomed area
    140, // Height of the zoomed area
    0,
    0,
    140,
    140
  );


 // Create a fabric image from the zoomed canvas
 const magnifiedImage = new fabric.Image(zoomedCanvas, {
  left: 10, // Position it over the magnifier
  top:50, // Position it over the magnifier
  selectable: false,
  evented: false,
  name: 'magnifiedImage', // Name for the magnified image
});
  // Remove previous magnified images to prevent multiple layers
  const existingMagnifiedImage = canvas.getObjects().find(obj => obj.name === 'magnifiedImage');
  if (existingMagnifiedImage) {
    canvas.remove(existingMagnifiedImage); // Remove the existing magnified image
  }

  // Add the new magnified image to the canvas
  canvas.add(magnifiedImage);

}




  return (
    < >
    <div className='position-relative'>
    <div className='re-anno-section'  onClick={handlehoverLayer}>
      <button className=' d-flex  align-items-center btn  rounded-pill custom-back-button border-0 '> 
      <span className='fs-4'>
      <i className="bi bi-arrow-left-short pe-1"></i>
      </span>
        
        {getReAnnotationSegments.name}-{getReAnnotationSegments.segName}
   </button> 
      
      </div>
     
    <canvas  ref={canvasElementRef} className='dzi-van' ></canvas>
       
       
       
      </div>
    

     <ReAnnoatationModal
       isShow={isShowModal}
       onClose={handleCloseModal}
       ok={handleOk}
     />


{isUpdatemasterdata &&
annotation &&
childGroupName &&
<AnnotationUpdateApi
segmentationInt={annotation}
segName={childGroupName}
resetAnnotation={handleResetAnotation}
resetFailAnnotation={handleFailResetAnnotation}
/>}
    
  </>
  )
}

export default ReAnnotationCanvas