import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getIsStartUploadGenAi, getProjectGenAiImage } from '../../../../slice/genAi/GenAiAttributesSlice'
import { GenAiImageModel } from '../../../../Model/genAiAttributes/GenAiImages'
import CallApiToUpload from './CallApiToUpload'
import { getUserLogin } from '../../../../slice/user/UserSlice'
import UpdateCredit from '../updateCredits/UpdateCredit'

const UploadGenAiIHome = () => {
 const[genAiImage, setGenAiImages]= useState<GenAiImageModel|null>(null)

 const[isUpload, setIsUpload]=useState<boolean>(false)
 const[isUpdateCredit, setIsUpdateCredit]=useState<boolean>(false)

  const getIsStartUploadGenAis= useSelector(getIsStartUploadGenAi)

  const getUserLogins= useSelector(getUserLogin)


  const[email, setEmail]= useState<string|null>(null)
  const[credit, setCredit]= useState<number|null>(null)
  
  // update email, credits 

  useEffect(()=>{

        if(getIsStartUploadGenAis &&
          getUserLogins &&
          getUserLogins[0] &&
          getUserLogins[0].email &&
          getUserLogins[0].credit
        ){
          if(getUserLogins[0].credit>0){
            setEmail(getUserLogins[0].email)
            setCredit( getUserLogins[0].credit-1)
            setIsUpdateCredit(true)
          }else{
            
          }
          
        }
  },[getIsStartUploadGenAis,getUserLogins])


  // control Upload
  useEffect(()=>{
    if(getIsStartUploadGenAis){
      setIsUpload(true)
     
    }else{
      
      setIsUpload(false)
    }
  },[getIsStartUploadGenAis])



  const handleResetCredit=()=>{
    setEmail(null)
    setCredit(null)
  }
  
  return (
    <>
    {/* Save Gen Image path into Db */}
  {  isUpload &&
 
  <CallApiToUpload
 

  />
  }

  {/* update the credits */}
  {isUpdateCredit &&
  email&&
  credit &&
  <UpdateCredit
  email={email}
  credit={credit}
  resetUpdateCredit={handleResetCredit}
  />}
    </>
  )
}

export default UploadGenAiIHome