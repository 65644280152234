import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Input, InputRef, Table, TableColumnsType } from 'antd';
import Search from 'antd/es/transfer/search';
import { MaterialCategoryModel } from '../../../Model/material/MaterialCategoryModel';
import { getMaterialCategorys, addEditcategory } from '../../../slice/materialSlice/MaterialCategorySlice';
import { getLoading, stopLoading } from '../../../slice/loading/LoadingSlice';
import DelteCategoryApi from '../../admin/AdminMaterial/category/api/Delete CategoryApi';
// import CategoryCreateForm from './CategoryCreateFrom';
import Loading from '../../loading/Loading';
import DataTable from 'react-data-table-component';
import { userModel } from '../../../Model/user/UserModel';
import { getAllUserData } from '../../../slice/Admin/allUserSlice/AllUSerSlice';
// import "./CategoryTable.css";

const UserTableData: React.FC = () => {
  const [allUsers, setAllUsers] = useState<userModel[]>([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getLoadings = useSelector(getLoading);
  const getAllUserDatas = useSelector(getAllUserData) 
  const [isEditProjectForm, setIsProEditForm] = useState(false);
  const navigate = useNavigate();
  const searchInput = useRef<InputRef>(null);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (getAllUserDatas && getAllUserDatas.length > 0) {
      setAllUsers(getAllUserDatas);
    }
  }, [getAllUserDatas]);

  useEffect(() => {
    setIsLoading(getLoadings);
  }, [getLoadings]);

  const handleEditCategory = (record: MaterialCategoryModel) => {
    dispatch(addEditcategory(record));
    navigate(`/dashboard/swatch-categories/${record.id}/edit`);
  };

  const handleDelete = (record: MaterialCategoryModel) => {
    setIsDelete(true);
    setDeleteId(record.id ?? 0);
  };

  const handleResetDelete = (mess: string) => {
    setIsDelete(false);
    setDeleteId(0);
    dispatch(stopLoading());
    alert(mess === "success" ? "Category deleted successfully" : "Error in deleting category");
  };

  // const columns: TableColumnsType<MaterialCategoryModel> = [
  //   {
  //     title: 'Id',
  //     dataIndex: 'id',
  //     key: 'id',
  //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
  //       <div
  //         style={{
  //           padding: 8,
  //           width: "300px",
  //           position: "absolute",
  //           top: -90,
  //           zIndex: 1,
  //         }}
  //       >
  //         <Input
  //           placeholder="Search Id"
  //           value={selectedKeys[0]}
  //           onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}

  //           onKeyUp={(e) => {
  //             confirm({ closeDropdown: false });
  //           }}

  //           style={{ width: 188, marginBottom: 8, display: "block" }}
  //         />

  //       </div>
  //     ),
  //     onFilterDropdownVisibleChange: (visible) => {
  //       if (visible) {
  //         setTimeout(() => searchInput.current?.select());
  //       }
  //     },
  //     onFilter: (value, record) => record.id?.toString().includes(value.toString()) || false,
  //     filterSearch: true,
  //   },
  //   {
  //     title: 'Title',
  //     dataIndex: 'title',
  //     key: 'title',
  //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
  //       <div
  //         style={{
  //           padding: 8,
  //           width: "300px",
  //           position: "absolute",
  //           top: -90,
  //           zIndex: 1,
  //         }}
  //       >
  //         <Input
  //           ref={searchInput}
  //           placeholder="Search Title"
  //           value={selectedKeys[0]}
  //           onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}

  //           // onPressEnter={() => confirm({ closeDropdown: false })}
  //           onKeyUp={(e) => {
  //             confirm({ closeDropdown: false });
  //           }}

  //           style={{ width: 188, marginBottom: 8, display: "block" }}
  //         />
  //       </div>
  //     ),
  //     onFilterDropdownVisibleChange: (visible) => {
  //       if (visible) {
  //         setTimeout(() => searchInput.current?.select(), 1000);
  //       }
  //     },
  //     onFilter: (value, record) => record.title?.toLowerCase().includes(value.toString().toLowerCase()) || false,
  //     filterSearch: true,
  //   },
  //   {
  //     title: 'ID ',
  //     dataIndex: 'id',
  //     key: 'id',
  //   },
  //   {
  //     title: 'Placeholder',
  //     dataIndex: 'placeholder',
  //     key: 'placeholder',
  //   },
  //   {
  //     title: 'Description',
  //     dataIndex: 'sort_order',
  //     key: 'sort_order',
  //   },
  //   {
  //     title: 'Status',
  //     dataIndex: 'status',
  //     key: 'status',
  //     render: (status: number) => (status === 1 ? 'Active' : 'Inactive'),
  //   },
  //   {
  //     title: 'Action',
  //     dataIndex: 'action',
  //     key: 'action',
  //     render: (value, record) => (
  //       <div className="d-flex action">
  //         <div className="action-icon-table" onClick={() => handleEditCategory(record)}>
  //           <i className="bi bi-pencil-square mx-0"></i>
  //         </div>
  //         <div className="action-icon-table">
  //           <i className="bi bi-copy mx-0"></i>
  //         </div>
  //         <div className="action-icon-table">
  //           <i className="bi bi-box-arrow-up-right mx-0"></i>
  //         </div>
  //         <div className="action-icon-table trash-icon-media" onClick={() => handleDelete(record)}>
  //           <i className="bi bi-trash3 mx-0"></i>
  //         </div>
  //       </div>
  //     ),
  //   },
  // ];

  const columns = [
    {
      name: "Id",
       selector: (row: userModel) => row.id??0, // Selector returns a primitive value
      sortable: true,
    },
    {
      name: "Name",
       selector: (row: userModel) => row.name??"",
      sortable: true,
    },
    {
      name: "Email",
       selector: (row: userModel) => row.email??"",
      sortable: true,
    },
    {
      name: "Role",
      cell: (row: userModel) =>{
        const project= row.role
        return project
      } ,
      sortable: true,
    },
    {
      name: "Status",
       selector: (row: userModel) => row.isActive?"true":"false",
      sortable: true,
    },
    
    {
      name: "Action",
      cell: (row: userModel) => {
        return(
          <div className="d-flex action">
                 <div className="action-icon-table"
                 // onClick={() => handleEditCategory(record)}
                  >
                   <i className="bi bi-pencil-square mx-0"></i>
                 </div>
                 <div className="action-icon-table">
                   <i className="bi bi-copy mx-0"></i>
                 </div>
                 <div className="action-icon-table">
                   <i className="bi bi-box-arrow-up-right mx-0"></i>
                 </div>
                 <div className="action-icon-table trash-icon-media" 
                 //onClick={() => handleDelete(record)}
                 >
                   <i className="bi bi-trash3 mx-0"></i>
                 </div>
               </div>
        )
      }
    },
  ];


  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <>

      <div className='card mx-3'>
        <div className='card-header bg-white p-2 d-flex justify-content-between align-items-center'>
          <input
            type="search"
            className="form-control w-auto"
            placeholder="Search"
          />
          <button type='button' className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#exampleModal">Create new entry</button>
        </div>
        
        <div className='card-body p-0'>
          {isLoading && <Loading />}
          <div className="p-0">
            {allUsers.length === 0 ? (
              <Loading />
            ) : (
           

              <DataTable 
              columns={columns} 
              data={allUsers.map((item) => ({ ...item, key: item.id }))}
              pagination
              fixedHeader
            />

            )}
          </div>
          {isDelete && deleteId !== 0 && (
            <DelteCategoryApi deleteId={deleteId} resetDelete={handleResetDelete} />
          )}

        </div>
      </div>
      <div>
        <div className=" brand-title">
          <p>{allUsers.length} entries Found</p>
        </div>
      </div>



    </>
  );
};

export default UserTableData;
