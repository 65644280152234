import React, { useEffect, useState } from "react";
import { NameModel, SearchSwatchModel } from "../../../../Model/material/MaterialModel";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryName } from "../../../../slice/materialSlice/MaterialCategorySlice";
import { getAllBrandName, getSearchBrandName } from "../../../../slice/materialSlice/MaterialBrandSlice";
import { getStyleName } from "../../../../slice/materialSlice/MaterialStyleSlice";
import CallSearchApi from "./CallSearchApi";
import SearchBrand from "./searchBrand/SearchBrand";
import { updateIsSearch } from "../../../../slice/materialSlice/MaterialSlice";
import { useDisclosure } from "@chakra-ui/react";
import { startLoading } from "../../../../slice/loading/LoadingSlice";

const SearchSwatches = () => {
    const dispatch= useDispatch()
    const [categorySearchTerm, setCategorySearchTerm] = useState("");
    const [categorySearchId, setCategorySearchId] = useState<number|null>(null);
   
    const [brandSearchTerm, setBrandSearchTerm] = useState("");
    const [brandSearchId, setBrandSearchId] =  useState<number|null>(null);
    
    const [styleSearchTerm, setStyleSearchTerm] = useState("");
    const [styleSearchId, setStyleSearchId] =  useState<number|null>(null);
    
    const [allStyleName, setAllStyleName] = useState<NameModel[]>([]);
    const [allCatName, setAllCatName] = useState<NameModel[]>([]);
    const [allBrandeName, setAllBrandeName] = useState<NameModel[]>([]);
   
    const [searchSwatch, setSearchSwatch] = useState<SearchSwatchModel|null>(null);

    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [isBrand, setIsBrand] = useState<boolean>(false);
    const getCategoryNames = useSelector(getCategoryName);
    const getAllBrandNames = useSelector(getAllBrandName);
    const getSearchBrandNames = useSelector(getSearchBrandName);

    const getStyleNames = useSelector(getStyleName);

    useEffect(() => {
        if (getStyleNames?.length > 0) setAllStyleName(getStyleNames);
    }, [getStyleNames]);

    useEffect(() => {
        if (getSearchBrandNames?.length > 0) setAllBrandeName(getSearchBrandNames);
    }, [getSearchBrandNames]);

    useEffect(() => {
        if (getCategoryNames?.length > 0) setAllCatName(getCategoryNames);
    }, [getCategoryNames]);



    const handleCat = (e: React.ChangeEvent<HTMLSelectElement>) => {
        try {
            const value = e.target.value;
    
            // Skip JSON parsing if the value is empty
            if (!value) {
                setCategorySearchId(null);
                setCategorySearchTerm("");
                return;
            }
    
            const parsedValue = JSON.parse(value);
    
          
            if (parsedValue && parsedValue.id && parsedValue.title) {
                //console.log("Selected category:", parsedValue);
                setCategorySearchId(parsedValue.id);
                setCategorySearchTerm(parsedValue.title);
                setIsBrand(true)
            } else {
                console.warn("Parsed value is missing required properties:", parsedValue);
            }
        } catch (error) {
            console.error("Error parsing category value:", error);
        }
    };
    
    const handleBrand=(e: React.ChangeEvent<HTMLSelectElement>) => {
       // setBrandSearchTerm(e.target.value);
        try {
            const value = e.target.value;
    
            // Skip JSON parsing if the value is empty
            if (!value) {
                setBrandSearchId(null);
                setBrandSearchTerm("");
                return;
            }
    
            const parsedValue = JSON.parse(value);
    
          
            if (parsedValue && parsedValue.id && parsedValue.title) {
                //console.log("Selected brand:", parsedValue);
                setBrandSearchId(parsedValue.id);
                setBrandSearchTerm(parsedValue.title);
            } else {
                console.warn("Parsed value is missing required properties:", parsedValue);
            }
        } catch (error) {
            console.error("Error parsing category value:", error);
        }
    }
    const handleStyle=(e: React.ChangeEvent<HTMLSelectElement>) => {
        
        try {
            const value = e.target.value;
    
            // Skip JSON parsing if the value is empty
            if (!value) {
                setStyleSearchId(null);
                setStyleSearchTerm("");
                return;
            }
    
            const parsedValue = JSON.parse(value);
    
          
            if (parsedValue && parsedValue.id && parsedValue.title) {
                //console.log("Selected category:", parsedValue);
                setStyleSearchId(parsedValue.id);
                setStyleSearchTerm(parsedValue.title);
            } else {
                console.warn("Parsed value is missing required properties:", parsedValue);
            }
        } catch (error) {
            console.error("Error parsing category value:", error);
        }

    }

    const handleReset=()=>{
        setCategorySearchId(null)
        setBrandSearchId(null)
        setStyleSearchId(null)
        setCategorySearchTerm("")
        setBrandSearchTerm("")
        setStyleSearchTerm("")
       dispatch(updateIsSearch(false))

    }

    const handleSearchCatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("event", e.target.value);
        setCategorySearchTerm(e.target.value);
    
        if (allCatName && allCatName.length > 0) {
            const filteredCategories = allCatName.filter((item: NameModel) =>
                item?.title?.toLowerCase().includes(e.target.value.toLowerCase())
            );
            if(filteredCategories &&
                 filteredCategories.length>0){
                setCategorySearchTerm(filteredCategories[0]?.title??"");
            }
           
            //setAllCatName(filteredCategories)
            //console.log("Filtered Categories: ", filteredCategories);
        }
    };
    

  
   const handleSearch=()=>{
    dispatch(startLoading())
    dispatch(updateIsSearch(true))
    const data:SearchSwatchModel={     
  catId:categorySearchId??0,
   brandId:brandSearchId??0,
  styleId: styleSearchId??0
    }
    setSearchSwatch(data)
    setIsSearch(true)
   }

   const handleResetSearch=()=>{
    setIsSearch(false)
    setSearchSwatch(null)
   }


   const handleResetsearchBrand=()=>{
    setIsBrand(false)
   }
    return (
        <>
        <div className="d-flex align-items-center">
            {/* Category Dropdown */}
            <div className="btn-group">

            
    <select 
        className="form-select" 
        aria-label="Category Select" 
        value={categorySearchId ? JSON.stringify(allCatName.find(item => item.id === categorySearchId)) : ""}
        onChange={(e) => handleCat(e)}
    >
        <option value="">Select Category</option>
        {allCatName.length > 0 ? (
            allCatName.map((item, index) => (
                <option key={index} value={JSON.stringify(item)}>
                    {item?.title || "Untitled"}
                </option>
            ))
        ) : (
            <option value="">No categories available</option>
        )}
    </select>
</div>


            <div className="btn-group">

            {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={categorySearchTerm}
                          onChange={(e) => handleSearchCatChange(e)}
                        /> */}
    <select 
        className="form-select" 
        aria-label="Brand Select" 
        value={brandSearchId ? JSON.stringify(allBrandeName.find(item => item.id === brandSearchId)) : ""}
       
        onChange={(e) => handleBrand(e)}
    >
        <option value="">Select Brand</option>
        {allBrandeName.length > 0 ? (
            allBrandeName.map((item, index) => (
                <option key={index} value={JSON.stringify(item)}>
                    {item?.title || "Untitled"}
                </option>
            ))
        ) : (
            <option value="">No Brand available</option>
        )}
    </select>
</div>
            <div className="btn-group">

            {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={categorySearchTerm}
                          onChange={(e) => handleSearchCatChange(e)}
                        /> */}
    <select 
        className="form-select" 
        aria-label="style Select" 
        value={styleSearchId ? JSON.stringify(allStyleName.find(item => item.id === styleSearchId)) : ""}
        onChange={(e) => handleStyle(e)}
    >
        <option value="">Select Style</option>
        {allStyleName.length > 0 ? (
            allStyleName.map((item, index) => (
                <option key={index} value={JSON.stringify(item)}>
                    {item?.title || "Untitled"}
                </option>
            ))
        ) : (
            <option value="">No style available</option>
        )}
    </select>
</div>

            

           

            <div className="sea-button mx-4">
                <button className="btn btn-primary"
                onClick={handleSearch}
                >Search</button>
            </div>
            <div className="sea-button mx-4">
                <button className="btn btn-primary"
               onClick={handleReset}
                >Reset</button>
            </div>
        </div>

        {isSearch &&
        searchSwatch &&
        <CallSearchApi
        searchSwatch={searchSwatch}
        resetSearchSwatch={handleResetSearch}
        />}

        {/* serach brand based on category */}
       { isBrand &&categorySearchId &&
        <SearchBrand
        catId={categorySearchId}
        resetSearch={handleResetsearchBrand}
        />}
        </>
        
    );
};

export default SearchSwatches;
