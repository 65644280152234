import React, { useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addBrandDoc, addBrandResponse, addIsDownloadBrand, addMaterialBrand, getIsDownloadBrand, getMaterialBrand, getSearchBrand } from '../../../slice/materialSlice/MaterialBrandSlice'

import GetSwatchBrand from './GetSwatchBrand'
import { GetAllMaterialBrands } from '../../../api/material/brand/BrandApi'
import { addMessage } from '../../../slice/messageToast/ToastSlice'
import axios from 'axios'


const GetMaterialBrand = () => {
const dispatch= useDispatch()
   const getMaterialBrands= useSelector(getMaterialBrand)
   const getIsDownloadBrands= useSelector(getIsDownloadBrand)
   const getSearchBrands= useSelector(getSearchBrand)
    // useEffect(()=>{
    //   if(getMaterialBrands && 
    //     getMaterialBrands.length===0){
    //     getAllMaterials_Brand()
    //   }
       
    // },[getMaterialBrands])
    const isApi= useRef(true)
    useEffect(()=>{

      if(getIsDownloadBrands &&
        getSearchBrands&&
        getSearchBrands.page &&
         getSearchBrands.pageSize &&
         isApi.current){
          isApi.current= false
          dispatch(addIsDownloadBrand(false))
        getAllMaterials_Brand(getSearchBrands.page , getSearchBrands.pageSize)
      }
       
    },[getIsDownloadBrands,getSearchBrands])



    const getAllMaterials_Brand= async(page:number, pageSize:number)=>{
      // const data={
      //  page:page,
      //   pageSize:pageSize
      // }
        try{
     
             const response= await GetAllMaterialBrands(page,pageSize)
               console.log("response brand data", response)
              if(response.status===200 &&
                 response.data){
                dispatch(addMaterialBrand(response.data.data))
                const docvalue={
                  totalDocuments: response?.data.totalDocuments,
                    totalPages: response?.data.totalPages,
                    currentPage: response?.data.currentPage,
                    pageSize:response?.data.pageSize
                }
                dispatch(addBrandDoc(docvalue))
                dispatch(addBrandResponse("successfully get brand"))
                isApi.current= true
              }
        }catch(err){
          isApi.current= true
          if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }
        }

    }
  return (
    <div>
   
    </div>
  )
}

export default GetMaterialBrand