import useSelection from 'antd/es/table/hooks/useSelection'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserJobData, resetJobData, } from '../../../slice/userJobSlice/UserJobSlice'
import { JobSegmentationModel } from '../../../Model/masterArray/MasterArrayModel'

import { JobModel, JobSegmentModel, NewJobSegmentModel, Segment } from '../../../Model/Job/JobModel'
import { getNewSegment, getSegregatedSliceData, getSegregateSegment, resetNewSegment, segegratedJobSegment, updateSortSeg } from '../../../slice/segegratedSlice/SegregatedSlice'
import { useUpdateEffect } from '@chakra-ui/react'
import { addSortedLayer, getMasterArray } from '../../../slice/canvas/masterArray/MasterArraySlice'
import { ConstantColorFactor } from 'three'

const SegegrateSegementBaseonPixel = () => {
  const dispatch = useDispatch()

  const getNewSegments= useSelector(getNewSegment)
const getUserJobDatas= useSelector(getUserJobData) as JobModel
 const isUpdate= useRef(true)
 const getSegregateSegments= useSelector(getSegregateSegment)
   const getMasterArrays= useSelector(getMasterArray)
   const getSegregatedSliceDatas=useSelector(getSegregatedSliceData)
   const [sortedLayers, setSortedlayer]= useState<string[]>([]) 
 useEffect(() => {
  if (getUserJobDatas && 
    getUserJobDatas.segments && 
    isUpdate.current) {
    isUpdate.current=false
    const allSeg :JobSegmentModel= getUserJobDatas.segments||{};

     //console.log("allSeg---->",allSeg)
    //  console.log("getUserJobDatas.segments---->",getUserJobDatas.segments)
    segregateSegments(allSeg)
  
  }
  else if(!getUserJobDatas) {
      isUpdate.current=true
  }
}, [getUserJobDatas]);

// update the segment into segrated slice

useEffect(()=>{
  
  if(getNewSegments &&
    Object.keys(getNewSegments).length>0
  ){
 //  console.log("getNewSegments",getNewSegments)
  segregateSegments(getNewSegments)
  dispatch(resetNewSegment())
   // console.log("getNewSegments",getNewSegments)
  }
},[getNewSegments])

//segrate the job
const segregateSegments=(allSeg:JobSegmentModel)=>{
 // console.log("allSeg--->",allSeg)

  if (allSeg) {
    
    const segmentsArray = Object.keys(allSeg).map((key) => ({
        
      [key]:{
        ...  allSeg[key],
        swatch:{}
       
      } ,
    }));
    
     console.log("segmentsArray",segmentsArray)
    const sortedSegments = segmentsArray.sort((a, b) => {
     
      const keyA = Object.keys(a)[0];
      const keyB = Object.keys(b)[0];
    
    
      const perimeterA = a[keyA].details?.annotation_area_pixel || 0;
      const perimeterB = b[keyB].details?.annotation_area_pixel || 0;
    
  
      return perimeterB - perimeterA;
    })
    
   // console.log("sortedSegments",sortedSegments)
    if (sortedSegments.length > 0) {
      // dispatch(startSegregation())
      const sortedlayer:string[]=[]
   
      sortedSegments.map(item=>{
        Object.keys(item).forEach((key) => {
          const value= item[key]
         // console.log("value", value)
          if(value && value.details && value.details.seg_type){
            const segName= value.details?.seg_short??""
            //console.log("segName",segName)
            sortedlayer.push(segName)
          }
            
        });
       })
        if(sortedlayer.length>0){
          setSortedlayer(sortedlayer)
        }
     //  c  onsole.log("sortedSegments before redux",sortedlayer)
      dispatch(segegratedJobSegment(sortedSegments));
     
    }
    
  }

}

// update sorted layer
useEffect(()=>{

  if(sortedLayers&& 
    sortedLayers.length>0 
    ){
      // console.log("sortedLayers",sortedLayers)
      dispatch(addSortedLayer(sortedLayers))
     

  }
},[sortedLayers,getMasterArrays])

useEffect(()=>{
  if(getSegregatedSliceDatas && 
    getSegregatedSliceDatas.isUpdateseg &&
    getSegregatedSliceDatas.segreDatedSegments &&
    getSegregatedSliceDatas.segreDatedSegments.length>0
  ){
    const sortedlayer:string[]=[]
     dispatch(updateSortSeg(false))
    getSegregatedSliceDatas.segreDatedSegments.map(item=>{
        Object.keys(item).forEach(key => {
          const value= item[key]
         // console.log("value", value)
          if(value && value.details && value.details.seg_type){
            const segName= value.details?.seg_short??""
            //console.log("segName",segName)
            sortedlayer.push(segName)
          }
            
        });
       })
       if(sortedlayer.length>0){
    
    dispatch(addSortedLayer(sortedlayer))
    
       }
  }
},[getSegregatedSliceDatas])


  return (
    <>
    
    </>
  )
}

export default SegegrateSegementBaseonPixel