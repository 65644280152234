import { Box, Input, Text, FormLabel, Card, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, useRadio, useRadioGroup, HStack, Select, Textarea, Button } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Example from '../RadioCard';
// import "./EditProjectFrom.css"
import "../EditProjectFrom.css"
import { useNavigate } from 'react-router-dom';
import useSelection from 'antd/es/table/hooks/useSelection';
import { getEditCategorys } from '../../../slice/materialSlice/MaterialCategorySlice';
import { MaterialCategoryModel } from '../../../Model/material/MaterialCategoryModel';
import { useSelector } from 'react-redux';


type Props = {
    updateCategory: (data: MaterialCategoryModel) => void
}
const EditCategory = ({ updateCategory }: Props) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const navigate = useNavigate()


    const handleBackToProjects = () => {
        navigate("/dashboard/swatch-categories")

    }
    const [editCategoryData, setEditCategoryData] = useState<MaterialCategoryModel>()
    const getEditCategory = useSelector(getEditCategorys) as MaterialCategoryModel
    const [editTitle, setEditTitle] = useState<string>('');
    const [editSlug, setEditSlug] = useState<string>('');
    const [editDescription, setEditDescription] = useState<string>('');
    const [editSortOrder, setEditSortOrder] = useState<string>('');
    const [status, setStatus] = useState<number>(0);

    useEffect(() => {

        if (getEditCategory && getEditCategory.id) {
            setEditCategoryData(getEditCategory)

            setEditTitle(getEditCategory.title || '');
            setEditSlug(getEditCategory.slug || '');
            setEditDescription(getEditCategory.description || '');
            setEditSortOrder(getEditCategory.sort_order?.toString() || '');
            setStatus(getEditCategory.status || 0);
        }
    }, [getEditCategory])

    const updateStatus = (data: number) => { // Ensure this accepts a number
        setStatus(data);
    };

    // handle cancel
    const handleCancel = () => {
        handleBackToProjects()
    }


    const handleSortOrde = (data: string) => {

        setEditSortOrder(data)
    }
    const handleSave = () => {

        const data: MaterialCategoryModel = {
            id: editCategoryData?.id,
            title: editTitle,
            slug: editSlug,
            description: editDescription,
            sort_order: parseInt(editSortOrder),
            status: status



        }
        updateCategory(data)
    }

    return (
        <div className=" mx-3">
            <div className='d-flex align-items-end back-icons' onClick={handleBackToProjects}>
                <i className="bi bi-arrow-left-short fs-4"></i>
                <h5>Back</h5>
            </div>

            <div className="card mt-4">
                {/* <div className='card-title'>
              
                </div> */}
                <div className="card-body">
                    <div className='row g-2'>
                        <div className='col-12 col-md-6'>
                            <label className='form-label'>Title</label>
                            {/* <input type='text' className='form-control' placeholder='Enter your title'/> */}
                            <Input className="form-control"
                                value={editTitle}
                                onChange={(e) => setEditTitle(e.target.value)}
                            />
                        </div>

                        <div className='col-12 col-md-6'>
                            <label className='form-label'>Slug</label>
                            {/* <input type='text' className='form-control' placeholder='Enter your title'/> */}
                            <Input className="form-control"
                                value={editSlug}
                                onChange={(e) => setEditSlug(e.target.value)}
                            />
                        </div>

                        <div className='col-12 col-md-6'>
                            <label className='form-label'>Description</label>
                            {/* <input type='text' className='form-control' placeholder='Enter your title'/> */}
                            <Textarea className='form-control'
                                value={editDescription}
                                onChange={(e) => setEditDescription(e.target.value)}
                            />
                        </div>


                   



                        <div className='col-12 col-md-6'>
                            <label className='form-label'>Sort order</label>
                            {/* <input type='text' className='form-control' placeholder='Enter your title'/> */}
                            <select className="form-select"
                                value={editSortOrder}
                                onChange={(e) => handleSortOrde(e.target.value)}>
                                <option value="" disabled>Select sort order</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>

                          

                            

{/* <div className='col-12 col-md-6'>
    <label className='form-label'>Status</label>
    <div className='radio-button radio-button-from'>
        <label>
            <input 
                type="radio" 
                value="active" 
                checked={editCategoryData?.status === 1} 
                onChange={() => updateStatus(1)} 
            />
            Active
        </label>
        <label>
            <input 
                type="radio" 
                value="inactive" 
                checked={editCategoryData?.status === 0} 
                onChange={() => updateStatus(0)} 
            />
            Inactive
        </label>
    </div>
</div> */}



                        </div>

                       

<div className='col-12 col-md-6'>
    <label className='form-label'>Status</label>
    <div className="form-check form-switch">
        <input 
            className="form-check-input" 
            type="checkbox" 
            role="switch" 
            id="flexSwitchCheckDefault" 
            checked={status === 1} // Check if status is active
            onChange={() => updateStatus(status === 1 ? 0 : 1)} // Toggle status
        />
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
            {status === 1 ? "Active" : "Inactive"} 
            {/* // Display current status */}
        </label>
    </div>
</div>



                    </div>
                    {/* <h6 className="card-subtitle mb-2 text-body-secondary">Card subtitle</h6> */}

                    <div className="card-footer bg-white d-flex justify-content-end">
            <button type="button" className="btn btn-link text-secondary border mx-3 text-decoration-none" data-bs-dismiss="modal" onClick={handleCancel}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleSave}>Save changes</button>
          </div>

                </div>
            </div>

            {/* <Card className="card-edit py-4 mt-4">
                <div className='row g-2'>
                    <div className="row container from-edit">
                        <div className="col-6 from-list">
                            <FormLabel>Title</FormLabel>
                            <Input className="input-primary"
                                value={editTitle}
                                onChange={(e) => setEditTitle(e.target.value)}
                            />

                        </div>

                        <div className="col-6 from-list">
                            <FormLabel>Slug</FormLabel>
                            <Input className="input-primary"
                                value={editSlug}
                                onChange={(e) => setEditSlug(e.target.value)}
                            />
                        </div>

                        <div className="col-6 from-list mt-2" >
                            <FormLabel>Description</FormLabel>
                            <Textarea style={{ backgroundColor: "#fff" }}
                                value={editDescription}
                                onChange={(e) => setEditDescription(e.target.value)}
                            />
                        </div>



                        <div className="col-6 mt-2">
                            <FormLabel>Sort order</FormLabel>
                            <NumberInput style={{ backgroundColor: "#fff" }}
                                value={editSortOrder}
                                onChange={handleSortOrde}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>

                            </NumberInput>
                        </div>

                        {/* <div className="col-3 mt-2">
                            <FormLabel>Status</FormLabel>
                            <div className='radio-button radio-button-from'>
                                {editCategoryData?.status !== undefined &&
                                    <Example
                                        statusValue={editCategoryData?.status}
                                        sendValue={updateStatus}
                                    />}
                            </div>

                        </div> */}

                        {/* <div className='btn-from'>
                            <Button className='from-btn' onClick={handleCancel}>Cancel</Button>
                            <Button className='from-save-btn' onClick={handleSave}>Save</Button>
                        </div>



                    </div>
                </div>
            </Card>  */}
        </div>
    );
};

export default EditCategory;