import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

const socialicon1 = process.env.PUBLIC_URL + "/img/linkedin.svg";
const socialicon2 = process.env.PUBLIC_URL + "/img/whatsapp.svg";
const socialicon3 = process.env.PUBLIC_URL + "/img/social-icon.svg";
const socialicon4 = process.env.PUBLIC_URL + "/img/facebook.svg";
const socialicon5 = process.env.PUBLIC_URL + "/img/twitter.svg";

// Define the props interface
type Props = {
  openModal: boolean;
  onclose: () => void;
};

const GenAiCanvasModel = ({ openModal, onclose }: Props) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const urlToCopy = "https://example.com";

  const handleClose = () => {
    onclose();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(urlToCopy)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch(err => console.error('Failed to copy: ', err));
  };

  console.log("opening model", openModal);
  return (
    <>
      <Modal show={openModal} onHide={handleClose}  centered  className="modal-dialog-centered">
        <Modal.Header closeButton >
          {/* <Modal.Title>Share for free</Modal.Title> */}
          <h5 className="modal-title fs-5" id="exampleModalLabel">
            Design Theme
          </h5>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          <div className="share-model ">
            <div className="share-pro-img mb-3">
              <img
                className="rounded-2"
                src="https://betadzinly.s3.us-east-2.amazonaws.com/projects/869/image_869_compress.jpg"
                alt="Share Preview"
              />
            </div>
            {/* <div className="d-flex justify-content-between mb-3"> */}
              <div className="d-flex gap-2 social_icons mb-3">
                <img src={socialicon1} alt="LinkedIn" className="social-icon cursor-pointer" />
                <img src={socialicon2} alt="WhatsApp" className="social-icon cursor-pointer" />
                <img src={socialicon3} alt="Social Icon" className="social-icon cursor-pointer" />
                <img src={socialicon4} alt="Facebook" className="social-icon cursor-pointer" />
                <img src={socialicon5} alt="Twitter" className="social-icon cursor-pointer" />
              </div>
           
           <div className="d-flex">
           <input type="text" className="form-control rounded-end-0 " id="generatedImageUrl" 
  placeholder="Image URL"/>
            <button className="btn btn-primary d-flex  rounded-start-0" onClick={handleCopy}>
                <i className="bi bi-link-45deg fs-6 pe-1"></i> 
                {copySuccess ? "Copied!" : "Copy URL"}
              </button>
              </div>

          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <button  onClick={handleClose}>
            Close
          </button>
          <button  onClick={handleClose}>
            Save Changes
          </button>
        </Modal.Footer> */}
      </Modal>

      
    </>
  );
};

export default GenAiCanvasModel;
