import React, { useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addIsDownloadSwatch, addMaterial, addSwatchDoc, addSwatchResponse, getIsDownloadSwatch, getMaterial, getSearchSwatch } from '../../slice/materialSlice/MaterialSlice'
import { GetAllMaterial } from '../../api/material/swatch/SwatchApi'
import { addMessage } from '../../slice/messageToast/ToastSlice'
import axios from 'axios'

const GetMaterials = () => {
   const dispatch= useDispatch()

  

    const getMaterials= useSelector(getMaterial)
    const getSearchSwatchs= useSelector(getSearchSwatch)
    const getIsDownloadSwatchs= useSelector(getIsDownloadSwatch)
      const isApi= useRef(true)
    
    useEffect(()=>{
        if(getSearchSwatchs &&
          getSearchSwatchs.page &&
          getSearchSwatchs.pageSize&&
          getIsDownloadSwatchs &&
          isApi.current
        ){
          isApi.current=false
          dispatch(addIsDownloadSwatch(false))
          getAllMaterials(getSearchSwatchs.page,getSearchSwatchs.pageSize)
        }
    },[getSearchSwatchs,getIsDownloadSwatchs])

    
    const getAllMaterials= async(page :number, pageSize:number)=>{
        try{
      
             const response= await GetAllMaterial(page, pageSize);
             
                 if(response && 
                  response.status===200 && 
                  response.data){
                  dispatch(addMaterial(response.data.data))
                  dispatch(addSwatchResponse("successfully get style"))
                  const docvalue={
                    totalDocuments: response?.data.totalDocuments,
                      totalPages: response?.data.totalPages,
                      currentPage: response?.data.currentPage,
                      pageSize:response?.data.pageSize
                  }
                  dispatch(addSwatchDoc(docvalue))
                  isApi.current= true
                 }
              // console.log("resposne", response)
        }catch(err){
          isApi.current= true
          if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }
        }

    }
  return (
    <div>

    </div>
  )
}

export default GetMaterials