import React, { useEffect, useState } from "react";


import Sidebar from "../../dasboardpages/components/Sidebar";
import PageContentHeader from "../../dasboardpages/components/pageContent/PageContentHeader";
import SearchAdd from "../../dasboardpages/components/pageContent/SearchAdd";

import Header from "../../header/Header";
import UserTableData from "../../brandPage/users/UserTableData";
import GetAllUser from "../../brandPage/users/calApi/GetAllUser";

import { useDispatch, useSelector } from "react-redux";
import { addIsUserSearch, getAllUserData, getIsSearchUser } from "../../../slice/Admin/allUserSlice/AllUSerSlice";

const AdminUser = () => {
    const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
        {}
      );
      const [selectAll, setSelectAll] = useState(false);
      const [ isStart, setIsStart]= useState<boolean>(false)
     

      const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>('Material Library'); 
  const [secondHeader, setSecondHeader] =useState<string>('User') 
   
  const dispatch= useDispatch()
  const [breadCum1, setbreadCum1] =useState<string>('Home') 
  const [breadCum2, setbreadCum2] =useState<string>('Library') 
  const [breadCum3, setbreadCum3] =useState<string>('User') 
   const getIsSearchUsers= useSelector(getIsSearchUser )
  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };

  // when all user lengh is 0 then call Api
  useEffect(()=>{
    if(getIsSearchUsers ){
        setIsStart(true)
    }else{
        setIsStart(false)
    }
  },[getIsSearchUsers])

  const handleResetAllUser=()=>{
    dispatch(addIsUserSearch(false))
    setIsStart(false)
  }

  return (
    <>
    <div className="mt-5 pt-4">
<Header/>
    <div  className={`wrapper ${isActive ? 'active' : ''}`}>
         <Sidebar toggleActiveClass={toggleActiveClass} />
        {/* content header */}
        
         <PageContentHeader
         firstHeader={firstHeader}
         secondHeader={secondHeader}
         breadCum1={breadCum1}
         breadCum2={breadCum2}
         breadCum3={breadCum3}
         
          />
        

        <SearchAdd/>

          {/* table */}
         
          <UserTableData/>



         
    </div>
    </div>

        {/* get all user */}
       { <GetAllUser
       isStart={isStart}
       resetAllUser={handleResetAllUser}
       />}
    </>


 
  )
}

export default AdminUser
