import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { GetallProject, userProject } from '../../../api/projects/ProjectApi'
import { useDispatch, useSelector } from 'react-redux'
import { getUserLogin } from '../../../slice/user/UserSlice'
import { userModel } from '../../../Model/user/UserModel'
import { addProjectResponse, addUserProject } from '../../../slice/user/UserProject'
import { addMessage } from '../../../slice/messageToast/ToastSlice'


type Props={
   
   resetGetAllProject:()=>void
}
const GetAllProject = ({resetGetAllProject}:Props) => {
   const dispatch= useDispatch();
   const getUserLogins= useSelector(getUserLogin) as userModel[]
   const isApi= useRef(true)
     useEffect(()=>{
      if(getUserLogins &&
          getUserLogins.length>0 &&
          isApi.current
         ){
         isApi.current= false
         const projectList=getUserLogins[0].projects
         const role= getUserLogins[0].role?getUserLogins[0].role:""
         getuserProjectS(projectList?projectList:[], role)
      }
       
     },[getUserLogins])

    const getuserProjectS=async (projectList:[], role:string)=>{
      
         try{
            
            const projects= await userProject(projectList, role);
            // console.log("project", projects)
            if(projects.status===200 ){
               if(projects.data.length==0){
                 
                  dispatch(addProjectResponse("No Project"))


               }else if(projects.data.length>0){
                  dispatch(addUserProject(projects.data))
               }
               dispatch(addMessage({
                  isShow:true,
                  mess: "Got all projects",
                  toastType:"success"
                 }))
               isApi.current= true
               resetGetAllProject()
               
            }
           

         }
         catch(err ){
            if(axios.isAxiosError(err)){
            
               dispatch(addMessage({
                   isShow:true,
                   mess: err.response?.data.message,
                   toastType:"Error"
                  }))
           }
              // console.error("Unknown error type:", err);
           
           isApi.current= true
           resetGetAllProject()
         }
    }
  return (
    <></>
  )
}

export default GetAllProject