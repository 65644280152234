import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SerachMaterialStates {
     isFilter:boolean,
     searchCatId:number,
     searchBrandId:number[],
     searchStyleId:number
     
   
}

const initialState: SerachMaterialStates = {
  isFilter:false,
  searchCatId:0,
     searchBrandId:[],
     searchStyleId:0,
     
};

const SearchMaterialSlice = createSlice({
    name: "searchMaterial",
    initialState,
    reducers: {
      resetSearchMaterial:(state)=>{
        return initialState
      },
       
      updateIsFilter:(state,action)=>{
        state.isFilter= action.payload
      },
       addSearchCatId:(state,action)=>{
        state.searchCatId=action.payload
       },
       resetSearchCatId:(state)=>{
        state.searchCatId=0
       },
       addSearchBrandId:(state,action)=>{ 
        const {id}=action.payload 
        const allBrand= state.searchBrandId 
        if(allBrand && allBrand.length==0){ 
          state.searchBrandId.push(id) 
        } else{ 
          const brandIndex= state.searchBrandId.findIndex(item=>item===id) 
            if(brandIndex==-1){ 
              state.searchBrandId.push(id) 
            } 
            else{ 
              state.searchBrandId.splice(brandIndex, 1) // Corrected to use splice
            } 
        } 
       },
       resetSearchBarndId:(state)=>{
        state.searchBrandId=[]
       }
     
      
    
    
}
}
)

export const {
    resetSearchMaterial,
    updateIsFilter,
    addSearchCatId,
    resetSearchCatId,
    addSearchBrandId,
    resetSearchBarndId
    }=SearchMaterialSlice.actions;

        export const getIsFilter=  (state:{searchMaterial:SerachMaterialStates})=>state.searchMaterial.isFilter;
        
        export const getSearchCatId=  (state:{searchMaterial:SerachMaterialStates})=>state.searchMaterial.searchCatId;
        
        export const getSearchBrandIds=  (state:{searchMaterial:SerachMaterialStates})=>state.searchMaterial.searchBrandId;
        
export default SearchMaterialSlice.reducer