import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Action from "../../module/ImageView/LeftSections/EachSegments/action/Action";


interface ImageDepthStates {
    imageDepthData:{
        current_image_url:string,
        projectId:number,
    }
   
   isStartCalCulateDepth:boolean,
   screenShot_image_url:string,
   depth_image_url:string,
   isUploadScreenShot:boolean,
   refinedScreenShot:string
   
  
}

const initialState: ImageDepthStates = {

        imageDepthData:{
            current_image_url:"",
            projectId:0,
        },
    isStartCalCulateDepth:false,
    screenShot_image_url:"",
    depth_image_url:"",
    isUploadScreenShot:false,
    refinedScreenShot:""
   

};

const ImageDepthSlice = createSlice({
    name: "imageDepth",
    initialState,
    reducers: {
        resetImageDepth:(state)=>{
            return initialState
        },
        addImageDepthData:(state,action)=>{
            const {current_image, projectId}=action.payload
            state.imageDepthData.current_image_url=current_image
            state.imageDepthData.projectId= projectId
        },
        addIsStartImageDepth:(state,action)=>{
            state.isStartCalCulateDepth= action.payload
        },
        addImageDepthUrl:(state,action)=>{
            state.depth_image_url= action.payload
        },
        updateStartUploadScrenShot:(state,action)=>{
            state.isUploadScreenShot= action.payload
        },
        addScreenShotImageUrl:(state,action)=>{
            state.screenShot_image_url= action.payload
        }, resetScreenShotImageUrl:(state)=>{
            state.screenShot_image_url=""
            state.depth_image_url=""
        },
        addRefinedScreenShot:(state,action)=>{
            state.refinedScreenShot= action.payload
        },
        resetRefinedScreenShot:(state)=>{
            state.refinedScreenShot=""
        }
        
        
      
    }
}
)

export const {
    resetImageDepth,
    addImageDepthData,
    addIsStartImageDepth,
    addImageDepthUrl,
    updateStartUploadScrenShot,
    addScreenShotImageUrl,
    resetScreenShotImageUrl,
    addRefinedScreenShot,
    resetRefinedScreenShot
}=ImageDepthSlice.actions;
export const getImageDepthData=  (state:{imageDepth:ImageDepthStates})=>state.imageDepth.imageDepthData
export const getIsStartCalculateDepth=  (state:{imageDepth:ImageDepthStates})=>state.imageDepth.isStartCalCulateDepth
export const getDepthImageUrl=  (state:{imageDepth:ImageDepthStates})=>state.imageDepth.depth_image_url
export const getStartUploadScreenShort=  (state:{imageDepth:ImageDepthStates})=>state.imageDepth.isUploadScreenShot
export const getScreenShortUrl=  (state:{imageDepth:ImageDepthStates})=>state.imageDepth.screenShot_image_url
export const getRefinedScreenShot=  (state:{imageDepth:ImageDepthStates})=>state.imageDepth.refinedScreenShot

export default ImageDepthSlice.reducer