import React, { useEffect, useRef } from 'react'
import { GetScreenShotDepthImage } from '../../../../api/imageDepth/ImageDepthApi'
import { useDispatch } from 'react-redux'
import { addRefinedScreenShot } from '../../../../slice/imageDepthSlice/ImageDepthSlice'
import axios from 'axios'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'

const urlpath = process.env.REACT_APP_Project_Image
type Props={
    screenShotImage:string,
     projectId:number
     resetCallDepth:()=>void
}

const CallApiTogetDepthImage = ({projectId,screenShotImage,resetCallDepth}:Props) => {

    //https://dzinlystrapi.s3.us-east-2.amazonaws.com/projects/822/depth_img/image_822_original_depth.png"
   // https://betadzinly.s3.us-east-2.amazonaws.com/projects/822/depth_img/image_822_original_depth.png
  const isApi= useRef(true)
  const dispatch= useDispatch()
    useEffect(()=>{
        if(projectId &&
            screenShotImage &&
         isApi.current
        ){
            isApi.current= false
           const depthImage=`${urlpath}/${projectId}/depth_img/image_${projectId}_original_depth.png`

           callApiDepth(depthImage,screenShotImage,projectId)
        }
    },[projectId,screenShotImage])
    const callApiDepth=async(depthImage:string, screenShotImage:string, projectId:number)=>{
        try {
        
             const response= await GetScreenShotDepthImage(depthImage, screenShotImage, projectId);
                if(response && response.status==200){

                    console.log("image depth wale --",response)
                    dispatch(addRefinedScreenShot(response.data[0]))
                    isApi.current= false
                    resetCallDepth()
                }
        }
        catch(err){
           // console.log("Error on calll depth Api", err)
            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))
            }
            isApi.current= false
            resetCallDepth()
        }
    }

  return (
    <></>
  )
}

export default CallApiTogetDepthImage