import {
    Box,
    Input,
    Text,
    FormLabel,
    Card,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useRadio,
    useRadioGroup,
    HStack,
    Select,
    Textarea,
    RadioGroup,
    Radio,
    Button,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Example from "../style/StyleRadioCard";
import { useDispatch, useSelector } from "react-redux";
import {
    getEditBrand,
    resetEditBrand,
} from "../../../slice/materialSlice/MaterialBrandSlice";
import { MaterialBrandModel } from "../../../Model/material/MaterialBrandModel";
import { MaterialCategoryModel } from "../../../Model/material/MaterialCategoryModel";
import { getMaterialCategorys } from "../../../slice/materialSlice/MaterialCategorySlice";
import { useNavigate } from "react-router-dom";

type Props = {
    updatData: (data: MaterialBrandModel) => void;
};

const EditBrandForm = ({ updatData }: Props) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const getEditBrands = useSelector(getEditBrand);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [categoryId, setCategoryId] = useState<number>();
    const [brandId, setBrandId] = useState<number>();
    const [title, setTitle] = useState<string>();
    const [slug, setSlug] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [sort_order, setSort_order] = useState<number>();
    const [status, setStatus] = useState<number | undefined>();
    const [category, setCategory] = useState<MaterialCategoryModel>();

    const [allCategory, setAllCategory] = useState<MaterialCategoryModel[]>([]);

    const getMaterialCategoryss = useSelector(
        getMaterialCategorys
    ) as MaterialCategoryModel[];

    useEffect(() => {
        if (getEditBrands && getEditBrands.id && getMaterialCategoryss) {
            setBrandId(getEditBrands.id);
            setCategory(getEditBrands.category);
            setCategoryId(getEditBrands.category?.id);
            setTitle(getEditBrands.title);
            setSlug(getEditBrands.slug);
            setDescription(getEditBrands.description);
            setSort_order(getEditBrands.sort_order);
            setStatus(getEditBrands.status);
        }
    }, [getEditBrands, getMaterialCategoryss]);

    const handleSortOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSort_order(parseInt(event.target.value));
    };

    const handleStatus = (val: string) => {
        if (val) {
            setSort_order(parseInt(val));
        }
    };

    const handleSelectCategory = (val: number) => {
        if (val) {
            console.log("val", val);
            setCategoryId(val);
        }
    };

    const updateStatus = (data: number) => { // Ensure this accepts a number
        setStatus(data);
    };

    // back
    const handleBack = () => {
        dispatch(resetEditBrand());
        navigate("/dashboard/swatch-brands");
    };

    // cancel
    const handleCancel = () => {
        dispatch(resetEditBrand());
        navigate("/dashboard/swatch-brands");
    };

    const handleSave = () => {
        const data: MaterialBrandModel = {
            id: brandId,
            material_category_id: categoryId,
            title: title,
            slug: slug,
            description: description,
            sort_order: sort_order,
            status: status,
        };

        updatData(data);
    };
    return (
        <div className="mx-3">
            <div className="d-flex align-items-end back-icons" onClick={handleBack}>
                <i className="bi bi-arrow-left-short fs-4 "></i>
                <h5>Back</h5>
            </div>

            <div className="card mt-4">
                <div className="card-title"></div>
                <div className="card-body">
                    <div className="row g-2">
                        <div className="col-12 col-md-6">
                            <label className="form-label">Material Brand</label>
                            <Input
                                className='form-control'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="form-label">Material Category</label>
                            <select
                                className="form-select"
                                value={categoryId}
                                onChange={(e) => handleSelectCategory(Number(e.target.value))}>
                                <option value="" disabled>Job View</option> {/* Placeholder option */}
                                {getMaterialCategoryss &&
                                    getMaterialCategoryss.length > 0 &&
                                    getMaterialCategoryss.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.title}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="form-label">Slug</label>
                            <Input
                                className='form-control'
                                value={slug}
                                onChange={(e) => setSlug(e.target.value)}
                            />
                        </div>

                        <div className="col-12 col-md-6">
                            <label className="form-label">Description</label>
                            <Textarea
                                className='form-control'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />


                        </div>

                        <div className='col-12 col-md-6'>
                            <label className='form-label'>Sort Order</label>
                            <input
                                type="number"
                                className="form-control" // Bootstrap form-control class
                                value={sort_order}
                                onChange={handleSortOrder}
                            />


                        </div>

                        <div className='col-12 col-md-6'>
                            <label className='form-label'>Status</label>
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    checked={status === 1} // Check if status is active
                                    onChange={() => updateStatus(status === 1 ? 0 : 1)} // Toggle status
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                    {status === 1 ? "Active" : "Inactive"}
                                    {/* Display current status */}
                                </label>
                            </div>
                        </div>
                        <div className="card-footer bg-white d-flex justify-content-end">
            <button type="button" className="btn btn-link text-secondary border mx-3 text-decoration-none" data-bs-dismiss="modal" onClick={handleCancel}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
          </div>

                    </div>
                </div>
                {/* <Card className="card-edit py-4 mt-4">
                    <div className="row container from-edit">
                        <div className="col-6 from-list">
                            <FormLabel> Material Brand </FormLabel>
                            <Input
                                className="input-primary"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="col-6 from-list">
                            <FormLabel> Material Category </FormLabel>
                            <Select
                                className="input-primary"
                                placeholder="JobView"
                                value={categoryId}
                                onChange={(e) => handleSelectCategory(Number(e.target.value))}>
                                {getMaterialCategoryss &&
                                    getMaterialCategoryss.length > 0 &&
                                    getMaterialCategoryss.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.title}
                                        </option>
                                    ))}
                            </Select>
                        </div>

                        <div className="col-6 from-list">
                            <FormLabel> Slug </FormLabel>
                            <Input
                                className="input-primary"
                                value={slug}
                                onChange={(e) => setSlug(e.target.value)}
                            />
                        </div>

                        <div className="col-6 from-list">
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                style={{ backgroundColor: "#fff" }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>

                        <div className="col-6 from-list">
                            <FormLabel>Sort Order</FormLabel>
                            <NumberInput
                                style={{ backgroundColor: "#fff" }}
                                value={sort_order}
                                onChange={handleSortOrder}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </div>

                        <div className="col-3 mt-2">
                            <FormLabel>Status</FormLabel>
                            <div className="radio-button radio-button-from">
                                <RadioGroup
                                    className="radio-btn"
                                    onChange={handleStatus}
                                    value={status !== undefined ? status.toString() : ""}>
                                    <Radio value="1">Active</Radio>
                                    <Radio value="0">Inactive</Radio>
                                </RadioGroup>
                            </div>
                        </div>

                        <div className="btn-from">
                            <Button className="from-btn" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button className="from-save-btn" onClick={handleSave}>
                                Save
                            </Button>
                        </div>
                    </div>
                </Card> */}
            </div>
        </div>
    );
};

export default EditBrandForm;
