import axios from "axios";


 const serverApi =process.env.REACT_APP_BACKEND_URL


//call Api to calculate the depth and save image into s3 bucket
export function GetStartcalculateImageDepth(currentImage:string, projectId:number){
    
    const data={
        master_img_url:currentImage,
        projectid:projectId
    }
   
    return axios.post(`${serverApi}/api/depth-map-path`, data)
    .then(response => {
        // console.log("fiolder ", response)
        return response
    }).catch(error => {
        throw error;
    });
}

//call Api to get 
export function GetScreenShotDepthImage(depthImage:string, screenShotImage:string, projectId:number){

    
    const data={
        depth_map_img:depthImage ,
        swatched_img_canvas:screenShotImage ,
        projectid: projectId
    }
   
    return axios.post(`${serverApi}/api/depth-trans-img`, data)
    .then(response => {
        // console.log("fiolder ", response)
        return response
    }).catch(error => {
        throw error;
    });
}