import React from 'react'
import AdminSwatchesTables from '../../../module/admin/AdminMaterial/swatches/AdminSwatchesTables'

const AdminSwatchesTablePage = () => {
  return (
    <div className="mt-5 pt-4">
        <AdminSwatchesTables/>
    </div>
  )
}

export default AdminSwatchesTablePage
