import React, { useEffect, useRef } from 'react'
import { GetAllDoorSwatches, GetAllGarageDoorSwatches, GetAllGutterSwatches, GetAllRoofSwatches, GetAllWindowSwatches } from '../../../../../api/material/catBrandStyleSwatch/CatBrandStyleSwatchApi'
import { CatBrandStyleSwatchModel } from '../../../../../Model/material/CatBrandStyleSwatchModel'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'
import { addDoorSwatches, addGarageSwatches, addGutterSwatches, addRoofSwatches, addWindowSwatches } from '../../../../../slice/swatches/SwatchSlices'
import axios from 'axios'


type GutterProps={
    resetswatchGutter   : () => void
}
const GutterSwatch = ({resetswatchGutter}:GutterProps) => {
     const dispatch= useDispatch()
     const isApi= useRef(true)
    useEffect(()=>{
        if(isApi.current){
            getAllGutterSwatches()
            isApi.current=false
        }
    },[])

    const getAllGutterSwatches=async()=>{
        try{    
            const response=await GetAllGutterSwatches()
           // console.log("response gutter",response)
            if(response && 
                    response.status===200 &&
                response.data &&
                response.data.materials
                ){
                    dispatch(addGutterSwatches(response.data.materials))
                    resetswatchGutter()
            }
        }catch(err){
            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))
            }
            resetswatchGutter()
        }
    }

  return (
    <>
    </>
  )
}

export default GutterSwatch