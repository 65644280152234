import React, { useState } from 'react'
import LeftSection from '../../../leftSection/LeftSection'
import SwatchBrandTable from '../../../brandPage/brand/SwatchBrandTable'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'
import EditBrandForm from '../../../brandPage/brand/EditBrandForm'
import { MaterialBrandModel } from '../../../../Model/material/MaterialBrandModel'

import { useDispatch } from 'react-redux'
import { startLoading, stopLoading } from '../../../../slice/loading/LoadingSlice'
import UpdateBrandApi from './api/UpdateBrandApi'
import { useNavigate } from 'react-router-dom'
import Header from '../../../header/Header'
import Sidebar from '../../../dasboardpages/components/Sidebar'

const AdminBrandEdit = () => {
   const dispatch= useDispatch()
  const [ isEditApi , setIsEditApi]= useState<boolean>(false)
   const[editBrand, setEditBrand]= useState<MaterialBrandModel|null>()
     const navigate= useNavigate()
     const handleUpdateBrand=(data:MaterialBrandModel)=>{
     dispatch(startLoading())
     
      setEditBrand(data)
      setIsEditApi(true)

     }

      const handleResetBrand=(mess:string)=>{
         if(mess==="success"){
          setIsEditApi(false)
          setEditBrand(null)
          dispatch(stopLoading())
          alert("Brand updated sucessfully")
         } else if (mess=="fail"){
          setIsEditApi(false)
          setEditBrand(null)
          dispatch(stopLoading())
          alert("Error in updating Brand")
          
         }
              navigate("/dashboard/swatch-brands")
        }

        const [isActive, setIsActive] = useState(false); // State to track whether the div is active
        const [firstHeader, setFirstHeader] = useState<string>('Material Library'); 
        const [secondHeader, setSecondHeader] =useState<string>('Segments') 
       
        const [breadCum1, setbreadCum1] =useState<string>('Home') 
        const [breadCum2, setbreadCum2] =useState<string>('Library') 
        const [breadCum3, setbreadCum3] =useState<string>('Segments') 
       
        
        
        const toggleActiveClass = () => {
          setIsActive(!isActive); // Toggle the isActive state
        };
        
  return (
    <>
      {/* <Header/> */}

      <div className="mt-5 pt-4">
<Header/>
    <div  className={`wrapper  ${isActive ? 'active' : ''}`}>
    <Sidebar toggleActiveClass={toggleActiveClass} />
   {/* content header */}
   
   <EditBrandForm
    updatData={handleUpdateBrand}
    />



    {isEditApi && editBrand &&
       <UpdateBrandApi
       editBrand={editBrand}
       resetUpdate={handleResetBrand}
       
       />
    }
    
     {/* table */}
    
     {/* <SegmentTableData/> */}
     </div>
     </div>

{/* <div className='row'>


  <LeftSection
   
  />
<div className="col-9 brand-right-section">

   
    <EditBrandForm
    updatData={handleUpdateBrand}
    />



    {isEditApi && editBrand &&
       <UpdateBrandApi
       editBrand={editBrand}
       resetUpdate={handleResetBrand}
       
       />
    }
  </div> */}



  {/* getAll materials */}
  <GetAllMaterialsHome/>

    </>
  
  )
}

export default AdminBrandEdit