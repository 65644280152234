import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { EachOption, OptionJDetails } from '../../../../../Model/genAiAttributes/GenAiAttributes';
import OptionForm from './OptionForm';
import { time } from 'console';
import UpdateAttriTODB from './UpdateAttriTODB';
import { start } from 'repl';
import { getLoading, startLoading, stopLoading } from '../../../../../slice/loading/LoadingSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../loading/Loading';


type Props={
    isOpen:boolean
    onClose:()=>void
    editAttribute:OptionJDetails
}
const EditGenAiAttribute = ({isOpen, onClose,editAttribute}:Props) => {
  const [optionDetails, setOptionDetails] = useState<OptionJDetails>({
    key:'',
    title: '',
    status: false,
    is_featured: false,
    type: '',
    priority: '',
    options: [],
    option:[]
  });

    const [isSave ,setIsSave]= useState<boolean>(false)
    const [isLoading ,setIsLoading]= useState<boolean>(false)
     const dispatch= useDispatch()
    const getLoadings= useSelector(getLoading)
    useEffect(()=>{
      if(getLoadings){
        setIsLoading(true)
      }else{
        setIsLoading(false)
      }
    },[getLoadings])

     // update loader

  useEffect(()=>{
    if(editAttribute &&
       editAttribute.key &&
         editAttribute.title && 
         editAttribute.type &&
         editAttribute.options &&
         editAttribute.options.length>0 &&
         editAttribute.option &&
         editAttribute.option.length>0


        ){
            setOptionDetails({
                key:editAttribute.key,
                title: editAttribute.title,
                status: editAttribute.status || false,
                is_featured: editAttribute.is_featured || false,
                type: editAttribute.type,
                priority: editAttribute.priority || '',
                options: editAttribute.options|| [],
                option: editAttribute.option|| [],
                
              });
    }
  },[editAttribute])
 

  console.log("optionDetails---",optionDetails)
  console.log("editAttribute---",editAttribute)
  const handleClose = () => {
    onClose()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setOptionDetails(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };


  const handleSubmit=()=>{
    dispatch(startLoading())
    setIsSave(true)
  }

  const handleUpdateOption = (data: EachOption) => {
    setOptionDetails(prev => ({
      ...prev,
      option: (prev.option || []).map(option => 
        option.title === data.title 
          ? {
              title: data.title,
              image: data.image,
              slug: data.slug,
              isActive: data.isActive ? true : false
            }
          : option
      )
    }));
  };

   const handleokSubmit=()=>{
    dispatch(stopLoading())
    setIsSave(false)
    onClose()
   }
  return (
    <>
     

      <Modal
        show={isOpen}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title> <h5 className='fs-5 m-0'>Edit AI Attribute</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body  >

        <div className='row g-2 mb-4'>
        <div className='col-6'>
          <Form.Group  controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control 
              type="text" 
              name="title" 
              value={optionDetails.title} 
              onChange={handleChange} 
            />
          </Form.Group>

          <Form.Group  controlId="formStatus">
            <Form.Check 
              type="checkbox" 
              label="Status" 
              name="status" 
              checked={optionDetails.status} 
              onChange={handleChange} 
            />
          </Form.Group>

          <Form.Group controlId="formIsFeatured">
            <Form.Check 
              type="checkbox" 
              label="Is Featured" 
              name="is_featured" 
              checked={optionDetails.is_featured} 
              onChange={handleChange} 
            />
          </Form.Group>
          </div>

          <Form.Group className='col-6' controlId="formType">
            <Form.Label>Type</Form.Label>
            <Form.Control 
              type="text" 
              name="type" 
              value={optionDetails.type} 
              onChange={handleChange} 
            />
          </Form.Group>

          <Form.Group className='col-6' controlId="formPriority">
            <Form.Label>Priority</Form.Label>
            <Form.Control 
              type="text" 
              name="priority" 
              value={optionDetails.priority} 
              onChange={handleChange} 
            />
          </Form.Group>

          <Form.Group className='col-6' controlId="formOptions">
            <Form.Label>Options</Form.Label>
            
             <Form.Control as="textarea"
              type="text" 
              name="options" 
              value={optionDetails.options} 
              onChange={handleChange} 
             rows={3}
             placeholder="Comma separated values" 
              />
          </Form.Group>
          </div>
         

            {/* loading  */}
           { isLoading &&
           <Loading/>}

           {/* more options  */}
         
          {optionDetails && 
          optionDetails.option &&
          optionDetails.type &&
           optionDetails.option.length > 0 &&
           optionDetails.option.map(item => {
             if (item && optionDetails.type && optionDetails.key) {
                
               return <OptionForm 
               key={optionDetails.key}
               title={item.title??""}
                item={item}
                attritype ={optionDetails.type}
                updateOptionData={handleUpdateOption}
                />; 
             }
             return null; 
           })
          }
        </Modal.Body>
        <Modal.Footer>
          <button className='btn border' onClick={handleClose}>
            Close
          </button>
          <button className='btn btn-primary' onClick={handleSubmit}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      {isSave &&
      <UpdateAttriTODB
      optionDetails={optionDetails}
        resetUpdateAttri={handleokSubmit}
      
      />
      }
    </>
  );
};

export default EditGenAiAttribute;