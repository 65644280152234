import React, { useEffect, useRef, useState } from "react";
import CreateProjectFrom from "../../createproject/CreateProjectFrom";
// import EditProjectFrom from "../EditProjectFrom"
import CreateProjectModel from "../CreateProjectModel";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import { Card, Input, InputRef, Table, TableColumnsType } from 'antd';
import Search from "antd/es/transfer/search";
import LeftSection from "../../leftSection/LeftSection";
import EditCreateProject from "../EditCreateProject";
import EditCreateproject from "./SegEditCreateproject";
import SegEditCreateproject from "./SegEditCreateproject";
import { SegmentModel } from "../../../Model/segment/SegmentModel";
import { useSelector } from "react-redux";
import { getAllSegment } from "../../../slice/segment/SegmentSlice";
import Loading from "../../loading/Loading";
import { Image } from "antd";
import SearchAdd from "../../dasboardpages/components/pageContent/SearchAdd";
import Sidebar from "../../dasboardpages/components/Sidebar";
import PageContentHeader from "../../dasboardpages/components/pageContent/PageContentHeader";
import SegmentTableData from "./SegmentTableData";
import Header from "../../header/Header";
interface TableData {
  name: string;
 
  labels:string;
  media: string;
  color:string;
  colorcode: string;
 
  state: string;
}

const SegmentTable = () => {
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
        {}
      );
      const searchInput = useRef<InputRef>(null);
      const [selectAll, setSelectAll] = useState(false);
      const navigate = useNavigate();
       const getAllSegments= useSelector(getAllSegment)
       const[allSegment, setAllSegments]= useState<SegmentModel[]>([])
      const handleCheckboxChange = (id: string) => {
        setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
      };

      // getAll Segments from rrdux
      useEffect(()=>{
        if(getAllSegments &&
          getAllSegments.length>0
        ){
          setAllSegments(getAllSegments)
        }
      },[getAllSegments])
      const handleSelectAllChange = () => {
        const newCheckedItems: { [key: string]: boolean } = {};
       
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
      };
    
      const [isEditProjectFrom, setIsProEditForm] = useState(false);
    

      const handleEditSegment = () => {
        console.log('handleEditSegment')
        navigate("/segments/edit");
      };

    
    
  const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>('Material Library'); 
  const [secondHeader, setSecondHeader] =useState<string>('Segments') 
 
  const [breadCum1, setbreadCum1] =useState<string>('Home') 
  const [breadCum2, setbreadCum2] =useState<string>('Library') 
  const [breadCum3, setbreadCum3] =useState<string>('Segments') 
 
  
  
  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };
  
  return (
<div className="mt-5 pt-4">
<Header/>
    <div  className={`wrapper  ${isActive ? 'active' : ''}`}>
    <Sidebar toggleActiveClass={toggleActiveClass} />
   {/* content header */}
   
   
    <PageContentHeader
    firstHeader={firstHeader}
    secondHeader={secondHeader}
    breadCum1={breadCum1}
    breadCum2={breadCum2}
    breadCum3={breadCum3}
    
     />
   

   <SearchAdd/>

     {/* table */}
    
     <SegmentTableData/>
     </div>
     </div>
  /* < div className="row">  */

  //     <LeftSection/>

  //   <div className="col-9 brand-right-section">
  //   <div className="row mt-3">
  //     <div className="col-9 brand-title">
  //       <h1 className="job-title">Segments</h1>
  //       <p className="m-0">0 entries Found</p>
  //     </div>

  //     <div className="col-3 breadcrumb-link">
  //       <Breadcrumb
  //         items={[
  //           // {
  //           //   title: <a href="">Segment</a>,
  //           // },
  //           {
  //             title: "Segments",
  //           },
  //         ]}
  //       />
  //     </div>
  //   </div>

  //   <div className="row table-input">
  //     <div className="col-6 search-bar">
  //       <Search
  //         placeholder="Search swatch"
  //         //  onSearch={handleSearch}
  //         //  enterButton
  //         // style={{ marginBottom: '20px' }}
  //       />
  //     </div>

  //     <div className="col-6">
  //     <SegEditCreateproject />
  //     </div>
  //   </div>
   

  //   <Card className="mt-4">
  //   {allSegment &&
  //           allSegment.length == 0 ? (
  //           <Loading />
  //         ) :
  //           (
  //             <Table
  //               columns={columns}
  //               dataSource={allSegment?.map((item) => ({ ...item, key: item.id }))}
  //             // onChange={onChange}
  //             // showSorterTooltip={{ target: 'sorter-icon' }}
  //             />)}
  //   </Card>
  // </div>
  // </div>
  
  )
}

export default SegmentTable
