// AppRoutes.tsx
import React, { ReactNode, useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import HomePage from '../page/homePage/HomePage';

import NotFound from '../page/notfound/NotFound';
import Layout from '../layout/Layout';
import Profile from '../page/profilePage/Profile';
import BrandRoutes from './brandRoutes/BrandRoutes';
import ProjectRoutes from './projectRoutes/ProjectRoutes';
import LoginPage from '../page/login/LoginPage';


import AuthRoutes from './authRoutes/AuthRoutes';
import CustomerRoutes from './customerRoute/CustomerRoute';
import AdminRoutes from './adminRoutes/AdminRoutes';
import CreateProjectHomes from '../module/createproject/CreateProjectHomes';

import SegmentsRoutes from './segmentRoutes/SegmentsRoutes';
import UsersRoutes from './userroutes/UsersRoutes';
import ShapeRoutes from './shapeRoutes/ShapeRoutes';
import CanvasRoutes from './canvasRoute/CanvasRoutes';
import Generate_Image from '../page/generate_Image/Generate_Image';
import Edit_Image from '../page/generate_Image_edit/Edit_Image';
import store from "../store/Store"
import { RootState } from '../store/Store';


import { ChakraProvider } from '@chakra-ui/react';

import MediaRoutes from './mediaRoutes/MediaRoutes';
import DemoRoutes from './demoTable/DemoTable';
import Canvasview from '../page/canvas/canvasview';
import DasboardRoute from './dasboardRoute/DasboardRoute';
import SamModelPage from '../page/samModel/SamModelPage';
import { userModel } from '../Model/user/UserModel';
import { getUserLogin } from '../slice/user/UserSlice';
import { stopLoading } from '../slice/loading/LoadingSlice';
import ShareProPage from '../page/shareproject/ShareProPage';

// interface Props {
//   children?: ReactNode;
// }
// const RedirectToHome: React.FC = ({ children }) => {
//   const navigate = useNavigate();
//   const user = useSelector((state: RootState) => state.user.user); // Ensure this is correct based on your Redux state

//   useEffect(() => {
//     if (user && user.length==0) {
//       navigate('/'); // Redirect to home if user is null
//     }
//   }, [user, navigate]);

//   return <>{children}</>;
// };


const AppRoutes: React.FC = () => {
 
 
  return (
    <Provider store={store}>
    {/* <ChakraProvider> */}
      <Router>
        <Routes> 
          <Route path="/" element={<Layout />}>
            <Route index  element={<HomePage />} />
            <Route path ="auth/*" element={<AuthRoutes/>}/>
            <Route path="media/*" element={<MediaRoutes />} />
            <Route path="*" element={<NotFound />} />
            <Route path="profile" element={<Profile />} />
            {/* <Route
              path="profile"
              element={
                <RedirectToHome>
                  <Profile />
                </RedirectToHome>
              }
            /> */}
            <Route path="project/*" element={<CanvasRoutes />} />
            
            <Route path="brand/*" element={<BrandRoutes/>}/>
         
            <Route path="dashboard/*" element={<AdminRoutes/>}/>
         
            <Route path="projects/*" element={<ProjectRoutes/>}/>
                
            <Route path="customer/*" element={<CustomerRoutes/>}/>
            <Route path="designer/*" element={<CustomerRoutes/>}/>


            <Route path="createproject" element={<CreateProjectHomes/>}/>



            <Route path="segments/*" element={<SegmentsRoutes/>}/>
            <Route path="user/*" element={<UsersRoutes/>}/>
            <Route path="shape/*" element={<ShapeRoutes/>}/>
            <Route path="dashboard-page/*" element={<DasboardRoute/>}/>
            


            <Route path="generate-image" element={<Generate_Image/>}/>
            <Route path="generate-image/edit" element={<Edit_Image/>}/>


            {/* <Route path="canvasview" element={<Canvasview     
                
            />}/> */}



            {/* <Route path="job/*" element={<JobRoutes/>}/> */}

            {/* demo table routes */}
            <Route path="demo-table/*" element={<DemoRoutes/>}/>
            <Route path="sam-model" element={<SamModelPage/>}/>
            <Route path="share" element={<ShareProPage/>}/>


            {/* html canvas */}
           


          </Route>

        </Routes>
      </Router>
      {/* </ChakraProvider> */}
    </Provider>
  );
};

export default AppRoutes;

