import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getGenAiAttributes } from '../../../../../slice/genAi/GenAiAttributesSlice'
import GetAlgeniAttributes from '../../getAttributes/GetAlgeniAttributes'

const ReLoadGeAitrribute = () => {
    const location= useLocation()
    // console.log("location",location)
    const getGenAiAttributess= useSelector(getGenAiAttributes)
   const[isGenAttri, setIsGenAttri]= useState<boolean>(false)
    useEffect(()=>{
        if(location && 
          location.pathname &&
          location.pathname.includes("/project/view/") &&
            getGenAiAttributess.length==0
            
        ){
          setIsGenAttri(true)
        }else if( getGenAiAttributess.length>0){
          setIsGenAttri(false)
        }
    },[getGenAiAttributess,location])
  return (
    <>
        
       { isGenAttri &&
       <GetAlgeniAttributes/>}
    </>
  )
}

export default ReLoadGeAitrribute