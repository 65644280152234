import React from 'react'
import DasboardPages from '../../module/dasboardpages/DasboardPages'
import Header from '../../module/header/Header'

const DasboardPage = () => {
  return (
    <>
    <Header/>
      <DasboardPages/>
    </>
  )
}

export default DasboardPage
