import React from 'react'
import AuthRefresh from './AuthRefresh'
import RefreshCanvas from './RefreshCanvas'
import ProjectPageRefresh from './ProjectPageRefresh'
import CommentRefesh from './commentRefresh/CommentRefesh'

const RefreshHome = () => {

  //console.log("refreshed worked")
  return (
    <div>
        
        <AuthRefresh/>
        <RefreshCanvas/>
          {/*  refresh comment */}
     <CommentRefesh />
        <ProjectPageRefresh/>
    </div>
  )
}

export default RefreshHome