import React, { useState } from 'react'
import Header from '../../../header/Header'
import Sidebar from '../../../dasboardpages/components/Sidebar';
import PageContentHeader from '../../../dasboardpages/components/pageContent/PageContentHeader';
import SearchAdd from '../../../dasboardpages/components/pageContent/SearchAdd';
import GenAiAtrributeTable from './GenAiAtrributeTable';

const GenAiAttriHome = () => {
    const [isActive, setIsActive] = useState(false); // State to track whether the div is active
    const [firstHeader, setFirstHeader] = useState<string>('Gen-Ai'); 
    const [secondHeader, setSecondHeader] =useState<string>('Gen-Ai Attributes') 
   
    const [breadCum1, setbreadCum1] =useState<string>('Home') 
    const [breadCum2, setbreadCum2] =useState<string>('Gen-Ai') 
    const [breadCum3, setbreadCum3] =useState<string>('Gen-Ai Attributes') 
    const toggleActiveClass = () => {
        setIsActive(!isActive); // Toggle the isActive state
      };
  return (
    <>
    <Header/>
    <div  className={`wrapper ${isActive ? 'active' : ''}`}>
             <Sidebar toggleActiveClass={toggleActiveClass} />
            {/* content header */}
            
            
             <PageContentHeader
             firstHeader={firstHeader}
             secondHeader={secondHeader}
             breadCum1={breadCum1}
             breadCum2={breadCum2}
             breadCum3={breadCum3}
             
              />
            
    
            <SearchAdd/>
    
    <GenAiAtrributeTable/>
              {/* table */}
              {/* <SwatchBrandTable/> */}
    
    
    
    
             
        </div>
    
    
    </>
  )
}

export default GenAiAttriHome