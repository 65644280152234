import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'


 interface ProjectDetailsValue{
  segName: string;
  value: number;
  colorCode:string
 }
const SegmentDetails = () => {
  const getMasterArrays= useSelector(getMasterArray)
  const [segmentValue, setSegmentValue] = useState<ProjectDetailsValue[]>([])
  useEffect(()=>{
    if(getMasterArrays && getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length>0
    ){
       let segValue:ProjectDetailsValue[]=[]
       
      getMasterArrays.allSeg.flatMap((item) => {
        const groups = item?.segmentation;
        let totalVal=0
      if (groups && groups.length > 0) {
        //  groups.map((grp, index) => {
        //   const key = Object.keys(grp)[0];
         
        //   const value = grp[key].length;
        //   totalVal=totalVal+value

        // })

        const data:ProjectDetailsValue={
          segName:item.name??"",
          value:item?.segmentation?.length??0,
          colorCode:item.color_code??""
         }
         segValue.push(data)
      }
     
      })
     
      if(segValue.length>0){
        setSegmentValue(segValue)
      }
    }
  },[getMasterArrays])

   //console.log("segmentValue",segmentValue)

  return (
    <>
     
  {segmentValue &&
    
    segmentValue.length > 0 &&
    segmentValue.map((item,index) => {
      return (
        <div className="seg-progress-details rounded-pill" key={`${item.segName}-${index}`}>
          <span className="seg-color-code"
          style={{ backgroundColor: `${item.colorCode}` }}
          ></span>
          <p>
             {item.segName} <span className="total-seg">{item.value}</span>
          </p>
        </div>
      );
     
    })}


{/* return (
            <div className="seg-progress-details" key={`${key}-${index}`}>
              <span className="seg-color-code"
              style={{ backgroundColor: colorCode }}
              ></span>
              <p className="fw-semibold">
                <span className="total-seg">{value}</span> {segName}
              </p>
            </div>
          ); */}
            {/* <div className="seg-progress-details">
              <span className="seg-color-code"></span>
              <p className="fw-semibold">
                <span className="total-seg">7</span>Window
              </p>
            </div>
            <div className="seg-progress-details">
              <span className="seg-color-code"></span>
              <p className="fw-semibold">
                <span className="total-seg">5</span>Wall
              </p>
            </div>

            <div className="seg-progress-details">
              <span className="seg-color-code"></span>
              <p className="fw-semibold">
                <span className="total-seg">6</span>Roof
              </p>
            </div> */}
    </>
  )
}

export default SegmentDetails