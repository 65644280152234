import React, { useState } from "react";
import CreateProjectFrom from "../../createproject/CreateProjectFrom";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Checkbox,
  Card,
} from "@chakra-ui/react";

// import EditProjectFrom from "../EditProjectFrom"
import CreateProjectModel from "../CreateProjectModel";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import Search from "antd/es/transfer/search";
import LeftSection from "../../leftSection/LeftSection";
import EditShapeCreate from "./EditShapeCreate";
import PageContentHeader from "../../dasboardpages/components/pageContent/PageContentHeader";
import SearchAdd from "../../dasboardpages/components/pageContent/SearchAdd";
import Sidebar from "../../dasboardpages/components/Sidebar";
import ShaptableData from "./ShaptableData";
import Header from "../../header/Header";

interface TableData {
  name: string;
  id: number;
  placeholder: string;
  description: string; 
  state: string;
}

const ShapeTable = () => {
    const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
        {}
      );
      const [selectAll, setSelectAll] = useState(false);
      const navigate = useNavigate();
      const tableData: TableData[] = [
        {
          id: 3,
          name: "Square",
        
          placeholder: "-",
          description: "-",
          state: "Published",
        },
        {
          id: 4,
          name: "Square",
        
          placeholder: "-",
          description: "-",
          state: "Published",
        },
        {
          id: 5,
          name: "Rectangle",
            placeholder: "-",
            description: "-",
          state: "Published",
        },
      ];
    
      const handleCheckboxChange = (id: number) => {
        setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
      };
    
      const handleSelectAllChange = () => {
        const newCheckedItems: { [key: number]: boolean } = {};
        tableData.forEach((row) => {
          newCheckedItems[row.id] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
      };
    
      // const [isEditProjectFrom, setIsProEditForm] = useState(false);
    
      // const handleProEditFrom = () => {
      //   navigate("/shape/edit");
      // };
      const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>('Material Library'); 
  const [secondHeader, setSecondHeader] =useState<string>('Shapes') 
 
  const [breadCum1, setbreadCum1] =useState<string>('Home') 
  const [breadCum2, setbreadCum2] =useState<string>('Library') 
  const [breadCum3, setbreadCum3] =useState<string>('Shapes') 
 
  
  
  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };

  return (
    <div className="mt-5 pt-4">
    <Header/>
    <div  className={`wrapper ${isActive ? 'active' : ''}`}>
    <Sidebar toggleActiveClass={toggleActiveClass} />
   {/* content header */}
   
    <PageContentHeader
    firstHeader={firstHeader}
    secondHeader={secondHeader}
    breadCum1={breadCum1}
    breadCum2={breadCum2}
    breadCum3={breadCum3}
    
     />
   

   <SearchAdd/>

     {/* table */}
     
     <ShaptableData/>
</div>
</div>


  //   < div className="row"> 

  //     <LeftSection/>

  //   <div className="col-9 brand-right-section">
  //   <div className="row mt-3">
  //     <div className="col-9 brand-title">
  //       <h1 className="job-title">Shape</h1>
  //       <p className="m-0">0 entries Found</p>
  //     </div>

  //     <div className="col-3 breadcrumb-link">
  //       <Breadcrumb
  //         items={[
  //           {
  //             title: <a href="">Segment</a>,
  //           },
  //           {
  //             title: "Segments",
  //           },
  //         ]}
  //       />
  //     </div>
  //   </div>

  //   <div className="row table-input">
  //     <div className="col-6 search-bar">
  //       <Search
  //         placeholder="Search swatch"
  //         //  onSearch={handleSearch}
  //         //  enterButton
  //         // style={{ marginBottom: '20px' }}
  //       />
  //     </div>

  //     <div className="col-6">
  //     < EditShapeCreate/>
  //     </div>
  //   </div>
   
  //   <Card className="mt-4">
  //     <TableContainer className="seg-table-container">
  //       <Table variant="simple" className="table-container">
  //         <Thead className="table-head">
  //           <Tr>
  //             <Th>
  //               <Checkbox
  //                 isChecked={selectAll}
  //                 onChange={handleSelectAllChange}
  //               />
  //             </Th>
  //             <Th>ID</Th>
  //             <Th>NAME</Th>
   
  //             <Th>PLACEHOLDER</Th>
  //             <Th>DESCRIPTION</Th>
  //             <Th>STATE</Th>
  //             <Th>ACTION</Th>
  //           </Tr>
  //         </Thead>
  //         <Tbody className="table-body">
  //           {tableData.map((row) => (
  //             <Tr key={row.id}>
  //               <Td>
  //                 <Checkbox
  //                   isChecked={checkedItems[row.id] || false}
  //                   onChange={() => handleCheckboxChange(row.id)}
  //                 />
  //               </Td>
  //               <Td>{row.id}</Td>
  //               <Td>{row.name}</Td>
              
  //               <Td>{row.placeholder}</Td>
  //               <Td>{row.description}</Td>
         
  //               <Td>
  //                 <Button className="btn-media">{row.state}</Button>
  //               </Td>
  //               <Td>
             
  //                 <div className="d-flex action">
  //                       <div
  //                         className="action-icon-table"
  //                         onClick={handleProEditFrom}>
  //                         <i className="bi bi-pencil-square mx-0"></i>
  //                       </div>
  //                       <div className="action-icon-table">
  //                         <i className="bi bi-copy mx-0"></i>
  //                       </div>
  //                       <div className="action-icon-table">
  //                         <i className="bi bi-box-arrow-up-right mx-0"></i>
  //                       </div>
  //                       <div className="action-icon-table trash-icon-media">
  //                         <i className="bi bi-trash3 mx-0"></i>
  //                       </div>
  //                     </div>
  //               </Td>
  //             </Tr>
  //           ))}
  //         </Tbody>
  //       </Table>
  //     </TableContainer>
  //   </Card>
  // </div>
  // </div>
  )
}

export default ShapeTable
