import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Projects from '../../page/project/Projects';
import ProjectIds from '../../page/project/ProjectIds';
import AdminJobTable from '../../module/admin/jobAdmin/AdminJobTable';


const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Projects />} />
      <Route path=":id" element={<ProjectIds />} />
     {/* // <Route path="/jobs" element={< AdminJobTable/>} /> */}
    </Routes>
  );
};

export default ProjectRoutes;
