import React, { useState } from 'react'
import LeftSection from '../../../leftSection/LeftSection'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'
import EditStyleForm from '../../../brandPage/style/EditStyleForm'
import { MaterialStyleModel } from '../../../../Model/material/MaterialStyleModel'
import { startLoading, stopLoading } from '../../../../slice/loading/LoadingSlice'
import { useDispatch } from 'react-redux'
import UpdateStyleApi from './api/UpdateStyleApi'
import { useNavigate } from 'react-router-dom'
import Header from '../../../header/Header'
import Sidebar from '../../../dasboardpages/components/Sidebar'


const AdminStyleEdit = () => {
  const navigate = useNavigate()
  const [isEditApi, setIsStyleEditApi] = useState<boolean>(false)
  const [editStyle, setEditStyle] = useState<MaterialStyleModel | null>()

  const dispatch = useDispatch()
  const handleUpdateBrand = (data: MaterialStyleModel) => {
    dispatch(startLoading())

    setEditStyle(data)
    setIsStyleEditApi(true)

  }

  const handleResetBrand = (mess: string) => {
    if (mess === "success") {
      setIsStyleEditApi(false)
      setEditStyle(null)
      dispatch(stopLoading())
      alert("Brand updated sucessfully")
    } else if (mess == "fail") {
      setIsStyleEditApi(false)
      setEditStyle(null)
      dispatch(stopLoading())
      alert("Error in updating Brand")

    }
    navigate("/dashboard/swatch-styles")
  }

  const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>('Material Library');
  const [secondHeader, setSecondHeader] = useState<string>('Segments')

  const [breadCum1, setbreadCum1] = useState<string>('Home')
  const [breadCum2, setbreadCum2] = useState<string>('Library')
  const [breadCum3, setbreadCum3] = useState<string>('Segments')



  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };

  return (
    <>

      <div className="mt-5 pt-4">
        <Header />
        <div className={`wrapper  ${isActive ? 'active' : ''}`}>
          <Sidebar toggleActiveClass={toggleActiveClass} />
          {/* content header */}
          <EditStyleForm
            StyleupdatData={handleUpdateBrand}
          />

          {isEditApi && editStyle &&
            <UpdateStyleApi
              editBrand={editStyle}
              resetUpdate={handleResetBrand}

            />
          }
          {/* table */}

          {/* <SegmentTableData/> */}
        </div>
      </div>

      {/* <Header/> */}
      {/* 
       <div className='row'>


<LeftSection
 
/> */}
      {/* <div className="col-9 brand-right-section">

<EditStyleForm 
        StyleupdatData={handleUpdateBrand}
/>



{isEditApi && editStyle &&
       <UpdateStyleApi
       editBrand={editStyle}
       resetUpdate={handleResetBrand}
       
       />
    }

</div> */}




      {/* getAll materials */}
      <GetAllMaterialsHome />
      {/* </div> */}
    </>
  )
}

export default AdminStyleEdit