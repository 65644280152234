import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addGeneratedPrompt, getGenAiAttributes, getGenAiImage, getGeneratedPrompt, getPromptValues, startGenAiImage, updateProjectPrompTbasedImage, updateStartUploadGenAi } from '../../../slice/genAi/GenAiAttributesSlice';

import GenAiSegments from './tabs/GenAiSegments';
import GenAiGallery from './tabs/GenAiGallery';
import GetPrompt from './getPrompt/GetPrompt';
import PromptToAiImage from './promptToAiImage/PromptToAIImage';
import GetGenAiImage from '../genAi/GetGenAiImage';
import { getMasterArray } from '../../../slice/canvas/masterArray/MasterArraySlice';
import "./AiExteriors.scss"
import { PromptModel } from '../../../Model/genAiAttributes/GenAiAttributes';
import { switchToOutline } from '../../../slice/tabControl/TabControlSlice';
import ReLoadGeAitrribute from './tabs/reloadGenAiAttribute/ReLoadGeAitrribute';
import StartWebSocket from '../../webSocket/StartWebSocket';
import UploadGenAiIHome from './updateGenAiPromptImage/UploadGenAiIHome';
import GetGenAiImageFromDb from './getAttributes/GetGenAiImageFromDb';
import GenAiFeatures from './tabs/genAiFeature/GenAiFeatures';

export interface GenAiAttribute {
  type: 'feature' | 'segment'; // Define types for attributes
  // Add other properties if needed
}

export interface GenAiAttributesModel {
  [key: string]: GenAiAttribute; // Allow indexing with string keys
}

const s3BucketPath = process.env.REACT_APP_S3Bucket;

const AiExteriors = () => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [promptData, setPromptData] = useState<string | null>(null);
  const [allGenAttrFeature, setAllGenAttrFeature] = useState<GenAiAttributesModel | null>(null);
  const [allGenAttrSegments, setAllGenAttrSegments] = useState<GenAiAttributesModel | null>(null);
  const [selectedDropdownValues, setSelectedDropdownValues] = useState<PromptModel | null>(null);
  const [imagePath, setImagePath] = useState<string | null>(null);
  const [isGen, setIsGen] = useState<boolean>(false);
  const [isGenImage, setIsGenImage] = useState<boolean>(false);
  const [tabSelector, setTabSelector] = useState<string | null>(null);
 const[job_id, setJob_id]= useState<number|null>(null)
 const[projectId, setProjectId]= useState<number|null>(null)

  const dispatch = useDispatch();
  const getGenAiAttributess = useSelector(getGenAiAttributes);
  const getGenAiImages = useSelector(getGenAiImage);
  const getPromptValuess = useSelector(getPromptValues);
  const getMasterArrays = useSelector(getMasterArray);

  useEffect(() => {
    if (getMasterArrays?.image && 
      getMasterArrays?.project_id && 
      getMasterArrays.jobId) {
      const image = `${s3BucketPath}/projects/${getMasterArrays.project_id}/${getMasterArrays.image}`;
      setImagePath(image);
      setProjectId(getMasterArrays?.project_id)
      setJob_id(getMasterArrays.jobId)
    }
  }, [getMasterArrays]);

  useEffect(() => {
    if(getGenAiImages){
      setIsGenImage(getGenAiImages);
    }
    else{
      setIsGenImage(false)
    }
   
  }, [getGenAiImages]);

  useEffect(() => {
    if (getGenAiAttributess && getGenAiAttributess.length > 0) {
      const allAtt = getGenAiAttributess[0] as GenAiAttributesModel; // Explicitly cast to GenAiAttributesModel
      // Set features and segments from attributes
      const featureAttributes: GenAiAttributesModel = {};
      const segmentAttributes: GenAiAttributesModel = {};

      Object.keys(allAtt).forEach((key) => {
        const attribute = allAtt[key]; // Access attribute directly
        if (attribute.type === 'feature') {
          featureAttributes[key] = attribute; // Correctly typed attribute
        } else if (attribute.type === 'segment') {
          segmentAttributes[key] = attribute; // Correctly typed attribute
        }
      });

      setAllGenAttrFeature(featureAttributes);
      setAllGenAttrSegments(segmentAttributes);
    }
  }, [getGenAiAttributess]);

  useEffect(()=>{
    if(getPromptValuess){
      setSelectedDropdownValues(getPromptValuess)
     console.log("getPromptValuess",getPromptValuess)
    }
    
  },[getPromptValuess])


  const handleProfile = () => setTabSelector('profile');
  const handleHome = () => setTabSelector('home');

  const handleGenAiImage = () => {
    setIsGen(true)

    dispatch(startGenAiImage());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPromptData(event.target.value);
    dispatch(addGeneratedPrompt(event.target.value))
  };

  const handleResetGenAi = () => {
    setIsGen(false);
  };
    const handleTabSelect = (key: string | null) => {
    setTabSelector(key); // Set the selected tab
    if (key === 'gallery') {
       // console.log("gallery tab selected"); // Log when the gallery tab is selected
        dispatch(switchToOutline("genAiImage")); // Dispatch the action
    }
};

// generated prompt
const handlePromptGenerated=(data:string)=>{
  setPromptData(data)
  dispatch(addGeneratedPrompt(data))

}
 
const getGeneratedPrompts= useSelector(getGeneratedPrompt)
// set generated prompt
useEffect(()=>{
 if(getGeneratedPrompts==""){
  setPromptData(null)
 }
  else if(getGeneratedPrompts!==""){
    setPromptData(getGeneratedPrompts)
  }
},[getGeneratedPrompts])

// get All image from folder


  return (
    <>
      <div className='ai-exteriors-sec'>
        <div className='aiexter-custo-sec py-2'>
          <Tabs activeKey={tabSelector || 'home'} onSelect={handleTabSelect}  fill>
            <Tab className='home-tab' 
            eventKey='home' 
            title={<span className='tab'>Features</span>} onClick={handleHome}>
              {allGenAttrFeature && 
              <GenAiFeatures
               allGenAttrFeature={allGenAttrFeature} />}
            </Tab>
            <Tab className='profile-tab' 
            eventKey='profile'
             title={<span className='tab'>Segments</span>} onClick={handleProfile}>
              {allGenAttrSegments &&
               <GenAiSegments 
               allGenAttrSegments={allGenAttrSegments} />}
            </Tab>
            <Tab  className='gallery-tab'
             eventKey='gallery'
              title={<span className='tab'>Gallery</span>}
              >
              <GenAiGallery />
            </Tab>
          </Tabs>
        </div>

        {tabSelector !== 'gallery' && (
          <div className='gen-layout px-4'>
            <div className='pt-3'>
              <h6 className='fw-medium'>
               AI Prompt
               <span
        data-bs-toggle="popover"
        data-bs-trigger="hover focus"
        data-bs-content="Information about house angles."
      >
        <i className="bi bi-question-circle ps-1"></i>
      </span>
      
              </h6>
              <textarea
                className='form-control from-textarea'
                id='exampleFormControlTextarea1'
                rows={3}
                value={promptData || ''}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className='d-flex gap-1 mt-2'>
              <span className='switch-checkbox'>Private Gallery</span>
              <div className='form-check form-switch ms-2'>
                <input className='form-check-input' type='checkbox' role='switch' id='flexSwitchCheckDefault' />
              </div>
              <span className='switch-checkbox'>Public Gallery</span>
            </div>
          
            <button className='btn btn-primary rounded-pill create-btn mt-2 w-100' 
            onClick={handleGenAiImage}>
              Generate with Ai
               {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> <span>1</span></span> */}
            </button>
          </div>
        )}


       
      </div>

      {selectedDropdownValues &&
       Object.keys(selectedDropdownValues).length > 0 && (
        <GetPrompt 
        selectedDropdownValues={selectedDropdownValues}
         resetPrompt={handlePromptGenerated} />
      )}

      {imagePath && promptData && isGen && (
        <PromptToAiImage promptData={promptData} imagePath={imagePath} resetImagePath={handleResetGenAi} />
      )}

      {isGenImage && <GetGenAiImage 
      resetGenAi={handleResetGenAi}
      />}

      <ReLoadGeAitrribute/>
      <StartWebSocket/>

      <UploadGenAiIHome/>

      {/* <GetGenAiImageFromDb
      resetGenAiImages={handleResetAiImage}
      /> */}


    </>
  );
};

export default AiExteriors;
