import React from 'react'
import { useDispatch } from 'react-redux'
import { NameModel } from '../../../../../../Model/material/MaterialModel'
import { addSearchCatId } from '../../../../../../slice/materialSlice/SearchMaterial'


type Props={
  allSegCategory:NameModel[],
  startSearchBrand:()=>void
}
const CategorySearch = ({allSegCategory,startSearchBrand}:Props) => {

  // console.log("allSegCategory",allSegCategory)
    const dispatch= useDispatch()

    const handleCategory=(catId:number)=>{
      //  dispatch(updateIsCategoryModal(true))
     
      dispatch(addSearchCatId(catId))
       startSearchBrand()
    }
  return (
    <>
      {allSegCategory && allSegCategory.length>0 &&
      allSegCategory.map(item=>{
        return(
          <button type='button' 
          className='nav-link text-dark'
          key={item.id}
      onClick={()=>handleCategory(item.id??0)}
      >
       <span><i className="bi bi-bricks"></i></span> {item.title}
      </button>
        )
      })
      }
    </>
  )
}

export default CategorySearch