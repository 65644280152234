import React, { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux';
import { getUserJobBase64 } from '../../../slice/userJobSlice/UserJobSlice';
import { changeSegmentTab, getSwitchCanvas, getTabControl } from '../../../slice/tabControl/TabControlSlice';
import "../../../page/canvas/canvasview.scss"
import { getSegregateSegment } from '../../../slice/segegratedSlice/SegregatedSlice';

const SelectedSwatchCanvas = () => {
    const canvasRef = useRef<fabric.Canvas | null>(null);
    const canvasElementRefs = useRef<HTMLCanvasElement | null>(null);
    const rightSectionRef = useRef<HTMLDivElement | null>(null);
  
    const getUserJobBase64s = useSelector(getUserJobBase64);
    const [scaleX, setScalex] = useState<number | null | undefined>();
    const [scaleY, setScaley] = useState<number | null | undefined>();
    const getSegregateSegments = useSelector(getSegregateSegment);
   // const [annotationPoint, setAnnotationPoint] = useState<PointAnnotation[]>([]);
    const isCanvas = useRef(true);
  
    const dispatch = useDispatch();
    const getTabControls = useSelector(getTabControl);
    const getSwitchCanvass = useSelector(getSwitchCanvas);
  
    // Clean up canvas on unmount
    useEffect(() => {
      return () => {
        if (canvasRef.current) {
          canvasRef.current.dispose();
          canvasRef.current = null;
        }
      };
    }, []);
  
    // Handle canvas disposal on switch
    useEffect(() => {
      if (getSwitchCanvass === "segment" && canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;  // Clear the reference after disposal
        dispatch(changeSegmentTab('segment'));
      }
      else  if (getSwitchCanvass === "outline" && canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;  // Clear the reference after disposal
        dispatch(changeSegmentTab('outline'));
      }else if (getSwitchCanvass === "dimension" && canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;  // Clear the reference after disposal
        dispatch(changeSegmentTab('dimension'));
      }
      else if (getSwitchCanvass === "genAiImage" && canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;  // Clear the reference after disposal
        dispatch(changeSegmentTab('genAiImage'));
      }
    }, [getSwitchCanvass, dispatch]);
  
    // Initialize the canvas when the tab is set to "outline"
    useEffect(() => {
      if (getTabControls === "compare" && 
        !canvasRef.current && 
        canvasElementRefs.current) {
        isCanvas.current = true;
        generateCanvas();
      }
    }, [getTabControls, canvasRef]);
  
    const generateCanvas = () => {
      const rightSection = rightSectionRef.current;
      const canvas = new fabric.Canvas(canvasElementRefs.current, {
        width: rightSection == null ? window.innerWidth : window.innerWidth - rightSection.offsetWidth,
        height: window.innerHeight,
        backgroundColor: '#f0f0f0',
      });
      const imgGroup = new fabric.Group([], { 
        selectable: false, 
        name: 'imageGroup',
        visible: true ,
        hasBorders: false,
          hasControls: false,
      });
  
      canvas.add(imgGroup);
      canvas.requestRenderAll();
      canvasRef.current = canvas;
    }
  
    // Load the background image based on the job base64
    useEffect(() => {
      if (getUserJobBase64s && canvasRef.current && getTabControls === "compare") {
        const image = getUserJobBase64s;
        const encodedImage = "data:image/png;base64," + image;
        // const encodedImage = image;
        const decodedImage = atob(encodedImage.split(",")[1]);
        const uint8Array = new Uint8Array(decodedImage.length);
  
        for (let i = 0; i < decodedImage.length; i++) {
          uint8Array[i] = decodedImage.charCodeAt(i);
        }
  
        const blob = new Blob([uint8Array], { type: "image/png" });
        const imageUrl = URL.createObjectURL(blob);
  
        fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
          const canvas = canvasRef.current;
          if (canvas && canvas.width && canvas.height && img.width && img.height) {
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
  
            img.scaleToWidth(canvasWidth);
            img.scaleToHeight(canvasHeight);
  
            canvas.setBackgroundImage(img, canvas.requestRenderAll.bind(canvas), {
              scaleX: canvasWidth / img.width,
              scaleY: canvasHeight / img.height,
              originX: 'left',
              originY: 'top',
            });
  
            setScalex(canvasWidth / img.width);
            setScaley(canvasHeight / img.height);
            canvas.requestRenderAll();
          }
        });
      }
    }, [getUserJobBase64s, getTabControls]);

    useEffect(()=>{
      if(getSegregateSegments &&
        getSegregateSegments.length>0 &&
        canvasRef.current
      ){
        const groupSeg = canvasRef.current?.getObjects();
        const matchingGroup = groupSeg.find(grp => grp.name ==="imageGroup") as fabric.Group | undefined;
                
        getSegregateSegments.map(item=>{
          
          Object.keys(item).forEach(key=>{
            const swatchName= item[key].details?.label
            const swatchImageUrl=item[key].swatch.swatch_seg_image
            if(swatchImageUrl &&matchingGroup &&swatchName ){
              fabric.Image.fromURL(swatchImageUrl, (img) => { 
                const canvas = canvasRef.current;
                
                if (canvas && canvas.width && canvas.height && img.width && img.height) {
                  const scaleX = canvas.width / img.width;
                  const scaleY = canvas.height / img.height;
              
                  const oImg = img.set({ scaleX: scaleX, scaleY: scaleY });
                  oImg.set({
                    name: swatchName,
                    visible: true,
                  });
              
                  // Assuming you have a group, if not, you can directly add it to the canvas
                  matchingGroup.addWithUpdate(oImg);
                  canvas.requestRenderAll();
                }
              }
              , 
              {
                crossOrigin: 'anonymous', // Only needed if you load from URL, not required for Base64
              }
              );
            }

          })
        })
    
      }
    },[getSegregateSegments,canvasRef])
  
  return (
    <div>
         <canvas ref={canvasElementRefs}  className='original'/>
    </div>
  )
}

export default SelectedSwatchCanvas