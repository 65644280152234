import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MaterialCategoryModel } from "../../Model/material/MaterialCategoryModel";
import { NameModel } from "../../Model/material/MaterialModel";


interface MaterialcategoryStates {
    allCategoryName:NameModel[],
    materialCategory:MaterialCategoryModel[]
      editCategorydata:MaterialCategoryModel,
      isDownloadcategory:boolean,
      categoryResponse:string
    
}

const initialState: MaterialcategoryStates = {
    allCategoryName:[],
    materialCategory:[],
    editCategorydata:{},
    isDownloadcategory:false,
    categoryResponse:""

};

const MaterialCategorySlice = createSlice({
    name: "materialCategory",
    initialState,
    reducers: {
        resetMaterialCategory:(state)=>{
            return initialState
        }
        ,

       addMaterialCategory:(state,action)=>{
        state.materialCategory= action.payload
       },

       addEditcategory:(state,action)=>{
         state.editCategorydata= action.payload
       },
       removeEditCategory:(state)=>{
        state.editCategorydata={}
       },
       updateCategory:(state,action)=>{
        const {data}= action.payload;
        const index= state.materialCategory.findIndex(item=>item.id===data.id);
        if(index!=-1){
            state.materialCategory[index].description= data.description
            state.materialCategory[index].slug= data.slug
            state.materialCategory[index].sort_order= data.sort_order
            state.materialCategory[index].status= data.status
            state.materialCategory[index].title= data.title
        }
       }, 
       deletecategory:(state,action)=>{
              const {id}= action.payload
              const catIndex= state.materialCategory.findIndex(item=>item.id===id)
              if(catIndex!=-1){
                state.materialCategory.splice(catIndex, 1);
              }
       },
        addReduxCategory:(state,action)=>{
            const {data}=action.payload;
            state.materialCategory.push(data)
            
        },
        addIsDownloadCategory:(state,action)=>{
            state.isDownloadcategory=action.payload
        },
        updateResponseCategory:(state,action)=>{
            state.categoryResponse=action.payload
        },
        addCategoryName:(state,action)=>{
            state.allCategoryName= action.payload
        }
        
    }
}
)

export const {addMaterialCategory,updateCategory,
    addEditcategory,removeEditCategory,deletecategory,
    addReduxCategory,
    addIsDownloadCategory,updateResponseCategory,
    resetMaterialCategory,
    addCategoryName

}=MaterialCategorySlice.actions;
    
export const getMaterialCategorys=  (state:{materialCategory:MaterialcategoryStates})=>state.materialCategory.materialCategory;
export const getEditCategorys=  (state:{materialCategory:MaterialcategoryStates})=>state.materialCategory.editCategorydata;
export const getIsDownloadCategory=  (state:{materialCategory:MaterialcategoryStates})=>state.materialCategory.isDownloadcategory;
export const getResponseCategory=  (state:{materialCategory:MaterialcategoryStates})=>state.materialCategory.categoryResponse

export const getCategoryName=  (state:{materialCategory:MaterialcategoryStates})=>state.materialCategory.allCategoryName
export default MaterialCategorySlice.reducer