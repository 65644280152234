import React from 'react'
import AdminBrandEdit from '../../../module/admin/AdminMaterial/brand/AdminBrandEdit'

const AdminBrandEditPage = () => {
  return (
    <div >
        <AdminBrandEdit/>
    </div>
  )
}

export default AdminBrandEditPage