import axios from "axios";
import { ProjectModel, UpdateProjectModel, UploadImageModel } from "../../Model/project/ProjectModel";
import { useDispatch } from "react-redux";
import { addUploadMessage } from "../../slice/modelData/ModelDataSlice";

 const serverApi =process.env.REACT_APP_SERVER_URL

export function GetallAdminProject(page:number, limit:number) {
     const data={
        page:page,
        limit:limit
     }

    return axios.post(`${serverApi}/admin/project/get-all`,
        data
    )
        .then(response => {
            
            return response
        }).catch(error => {
            throw error;
        });


}