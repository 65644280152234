import React, { useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux';

import { GetJobsAll } from '../../../../api/jobs/JobApi';
import { addAllJobData, addJobDocument, getAllJobData } from '../../../../slice/Admin/JobSlice/JobSlice';
import { GetallAdminProject } from '../../../../api/projects/AdminProjectApi';
import { addAllProjectData, addProjectDocument } from '../../../../slice/Admin/projectSlice/ProjectSlice';

type Props={
  page:number,
  limit:number,
  resetAllProjects:()=>void
}
const GetAllProjects = ({page,limit,resetAllProjects}:Props) => {
   const dispatch= useDispatch();
 
   const isApi= useRef(true)
   useEffect(()=>{
    if(page &&
      limit &&
      isApi.current
    ){
      isApi.current= false
        getProjects(page, limit)
    }
   },[page])

    const getProjects=async(page:number, limit:number)=>{

        try{
            
      const response = await GetallAdminProject(page, limit);
      console.log("get all projects", response)
      if(response && response.status===200){
          if(response.data.projects.length>0){
            dispatch(addAllProjectData({
                project:response.data.projects,
              response:"get job data"
             }))
          }else{
            dispatch(addAllProjectData({
              job:response.data.projects,
              response:"No jobs"
             }))
          }
          dispatch(addProjectDocument({
            totalDocuments:response.data.totalProjects,
            totalPages:response.data.totalPages ,
             currentPage:response.data.currentPage,
             pageSize:response.data.pageSize
          }))
          isApi.current= true
          resetAllProjects()
      }
        }catch(err){
            alert("Error in getting job ")
          isApi.current= true
          resetAllProjects()
        }
    }
  return (
    <>
        
    </>
  )
}

export default GetAllProjects