import React, { useEffect, useRef, useState } from "react";
import "./Swatch.css";
import {
  MaterialModel,
  SwatchesModel,
} from "../../../Model/material/MaterialModel";
import {
  Breadcrumb,
  Card,
  Input,
  InputRef,
  Table,
  TableColumnsType,
} from "antd";
import Loading from "../../loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  addIsDownloadSwatch,
  addSearchSwatch,
  getAllSwatches,
  getIsSearch,
  getMaterial,
  getSearchSwatchData,
} from "../../../slice/materialSlice/MaterialSlice";
import CreateNewEntrySwatch from "./CreateNewEntrySwatch";
import { Image } from "antd";
import Search from "antd/es/transfer/search";
import { title } from "process";
import { addIsDownloadStyle } from "../../../slice/materialSlice/MaterialStyleSlice";
import DataTable from "react-data-table-component";
import SearchSwatches from "./searchSwatchs/SearchSwatches";
import { getLoading } from "../../../slice/loading/LoadingSlice";

const Swatch = () => {
  const [allSwatches, setAllSwatches] = useState<MaterialModel[]>([]);
  

  const getAllSwatche = useSelector(getAllSwatches);
  const getMaterials = useSelector(getMaterial);
  const [currentPages, setCurrentPages] = useState<number | null>();
  const dispatch = useDispatch();

  const getIsSearchs= useSelector(getIsSearch)
  const getSearchSwatchDatas= useSelector(getSearchSwatchData)
  const getLoadings= useSelector(getLoading)

  const [isloading, setIsLoading]= useState<boolean>(false)
  useEffect(() => {
    if (getLoadings) {
        setIsLoading(true);
    }else{
      setIsLoading(false);
    }
  }, [getMaterials]);

//  id searched thevalue then update teh table

    useEffect(()=>{
      if(getIsSearchs &&
         getSearchSwatchDatas && 
         getSearchSwatchDatas.length>0){
          setAllSwatches(getSearchSwatchDatas);
        
      }else if(!getIsSearchs &&
        getMaterials && getMaterials.length>0){
        setAllSwatches(getMaterials);
      }
    },[getIsSearchs,getSearchSwatchDatas,getMaterials])

  const handleChangePage = () => {
    if (currentPages != null) {
      const page = currentPages + 1;
      const pageSize = 200;
      dispatch(
        addSearchSwatch({
          page: page,
          pageSize: pageSize,
        })
      );
      setCurrentPages(page);
      dispatch(addIsDownloadSwatch(true));
    }
  };
  const handleChangePrevPage = () => {
    if (currentPages != null) {
      const page = currentPages - 1;
      const pageSize = 200;
      setCurrentPages(page);
      dispatch(
        addSearchSwatch({
          page: page,
          pageSize: pageSize,
        })
      );
      dispatch(addIsDownloadSwatch(true));
    }
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPages(page);
  };

 
  const columns = [
    {
      name: "Id",
      selector: (row: MaterialModel) => row.id ?? 0, // Selector returns a primitive value
      sortable: true,
    },
    {
      name: "Image",
      cell: (row: MaterialModel) => {
        const path =
          "https://dzinlyv2.s3.us-east-2.amazonaws.com/liv/materials";
        const imagePath = row.photo ?? "";
        return <img width={50} src={`${path}/${imagePath}`} alt="Material" />;
      },
    },

    {
      name: "Title",
      selector: (row: MaterialModel) => row.title ?? "",
      sortable: true,
    },
    {
      name: "Style",
      selector: (row: MaterialModel) => row.material_style?.title ?? "",
      sortable: true,
    },

    {
      name: "Brand",
      selector: (row: MaterialModel) => row.material_brand?.title ?? "",
      sortable: true,
    },

    {
      name: "Category",
      selector: (row: MaterialModel) => row.material_category?.title ?? "",
      sortable: true,
    },

    {
      name: "Status",
      selector: (row: MaterialModel) => row.status ?? "",
      sortable: true,
    },

    {
      name: "Action",
      cell: (row: MaterialModel) => (
        <>
          <div className="d-flex action">
            <div
              className="action-icon-table"
              // onClick={() => handleEditCategory(row)}
            >
              <i className="bi bi-pencil-square mx-0"></i>
            </div>
            <div className="action-icon-table">
              <i className="bi bi-copy mx-0"></i>
            </div>
            <div className="action-icon-table">
              <i className="bi bi-box-arrow-up-right mx-0"></i>
            </div>
            <div
              className="action-icon-table trash-icon-media"
              // onClick={() => handleDelete(row)}
            >
              <i className="bi bi-trash3 mx-0"></i>
            </div>
          </div>
        </>
      ),
    },
  ];

 


  return (
    <>
      {/* <CreateNewEntrySwatch /> */}

      <div className="card mx-3">
        <div className="card-header bg-white p-2 d-flex justify-content-between align-items-center">
          
          <SearchSwatches/>



          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal">
            Create new entry
          </button>
        </div>

         {isloading &&
          <Loading />
         }
        <div className="card-body p-0">
          <div className="p-0">
            {allSwatches && allSwatches.length == 0 ? (
              <Loading />
            ) : (
             

              <DataTable
                columns={columns}
                data={allSwatches?.map((item) => ({ ...item, key: item.id }))}
                pagination
                fixedHeader
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Swatch;
