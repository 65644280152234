import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UpdateIndexModel } from "../../../Model/masterArray/UpdateIndexModel";


interface UpdateValueStates {
    isUpdateValue:boolean
    isDownload:boolean
    isAddSegment:boolean,
    targetAddSegment:{
        groupName:string,
        subGroupName:string
    },
    isUpdateGroup:boolean,
    isUpdateSubGroup:boolean,
    isUpdateChild:boolean,
    isUpdateAction:boolean
   
}

const initialState: UpdateValueStates = {
    isUpdateValue:false,
    isDownload:false,
    isAddSegment:false,
    targetAddSegment:{
        groupName:"",
        subGroupName:""
    },
    isUpdateGroup:false,
    isUpdateSubGroup:false,
    isUpdateChild:false,
    isUpdateAction:false
 
};

const UpdateValueSlice = createSlice({
    name: "updateValue",
    initialState,
    reducers: {
        resetUpdateValue:(state)=>{
            return initialState
        },
       startUpdateValue:(state)=>{
        state.isUpdateValue= true
       },
       stopUpdateValue:(state)=>{
        state.isUpdateValue= false
       }, 
       startDownlaod:(state)=>{
        state.isDownload=true
       },
       stopDownlaod:(state)=>{
        state.isDownload=false
       }, 
       startAddSegment:(state)=>{
        state.isAddSegment=true
       },
       stopAddSegment:(state)=>{
        state.isAddSegment=false
       }, 
       addTragetAddSegment:(state,action)=>{
        const{groupName, subGroupName}= action.payload
        state.targetAddSegment.groupName=groupName
        state.targetAddSegment.subGroupName=subGroupName
       },
       updateTargetAddSegment:(state,action)=>{
        const{subGroupName}=action.payload
        state.targetAddSegment.subGroupName=subGroupName
       },
       resetTragetAddSegment:(state)=>{
      
        state.targetAddSegment.groupName=""
        state.targetAddSegment.subGroupName=""
       },
       addUpdateGroup:(state,action)=>{
        state.isUpdateGroup= action.payload
       },
       addUpdateSubGroup:(state,action)=>{
        state.isUpdateSubGroup= action.payload
       },
       addUpdateChild:(state,action)=>{
        state.isUpdateChild= action.payload
       },
       addUpdateAction:(state,action)=>{
        state.isUpdateChild= action.payload
       },
       
       
      
      
    }
}
)

export const {startUpdateValue, stopUpdateValue,
    startDownlaod,stopDownlaod,
    startAddSegment,stopAddSegment,
    resetUpdateValue,
    resetTragetAddSegment,
    addTragetAddSegment,
    updateTargetAddSegment,
    addUpdateGroup,
    addUpdateSubGroup,
    addUpdateChild,
    addUpdateAction
}=UpdateValueSlice.actions;
export const getUpdateValueStatus=  (state:{updateValue:UpdateValueStates})=>state.updateValue.isUpdateValue
export const getDownloadStatus=  (state:{updateValue:UpdateValueStates})=>state.updateValue.isDownload
export const getAddSegmentStatus=  (state:{updateValue:UpdateValueStates})=>state.updateValue.isAddSegment
export const getTargetAddSegment=  (state:{updateValue:UpdateValueStates})=>state.updateValue.targetAddSegment

export const getIsUpdateGroup=  (state:{updateValue:UpdateValueStates})=>state.updateValue.isUpdateGroup
export const getIsUpdateSubGroup=  (state:{updateValue:UpdateValueStates})=>state.updateValue.isUpdateSubGroup
export const getIsUpdateChild=  (state:{updateValue:UpdateValueStates})=>state.updateValue.isUpdateChild
export const getIsUpdateAction=  (state:{updateValue:UpdateValueStates})=>state.updateValue.isUpdateAction
 export default UpdateValueSlice.reducer