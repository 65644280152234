import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobModel } from "../../../Model/Job/JobModel";
import { BrandDocModel } from "../../../Model/material/MaterialBrandModel";
import { ProjectModel } from "../../../Model/project/ProjectModel";


interface ProjectStates {
    isProjectSearch:boolean
    projectResponse:string,
    projects:ProjectModel[],
    projectDocumnet:BrandDocModel
   
    
}

const initialState:ProjectStates = {
    projects:[],
    projectResponse:"",
   isProjectSearch:false,
   projectDocumnet :{}
};
const ProjectSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        resetProjectSlice:(state)=>{
            return initialState
        }

        ,
        addProjectSearch:(state,action)=>{
            state.isProjectSearch= action.payload
        },
        addAllProjectData:(state,action)=>{
            const {project, response}=action.payload
            state.projects=project
            state.projectResponse=response
        },
        addProjectDocument:(state,action)=>{
            const {totalDocuments,totalPages , currentPage,pageSize}=action.payload
            state.projectDocumnet.totalDocuments=totalDocuments
            state.projectDocumnet.totalPages=totalPages
            state.projectDocumnet.currentPage=currentPage
            state.projectDocumnet.pageSize=pageSize
        }
        , 
        
        resetProjects:(state)=>{
            state.projects=[]
        },
        
        
    }
}
)

export const {
    resetProjectSlice,
    addProjectSearch,
    addAllProjectData,
    addProjectDocument,
    resetProjects
}=ProjectSlice.actions;
export const getAllProjectData=  (state:{projects:ProjectStates})=>state.projects.projects
export const getIsSearchProject=  (state:{projects:ProjectStates})=>state.projects.isProjectSearch
export const getProjectResponse=  (state:{projects:ProjectStates})=>state.projects.projectResponse
export default ProjectSlice.reducer