import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMasterArray, updateSegment } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import { closeAddSegmentModal, getAllSegment } from '../../../../slice/segment/SegmentSlice'
import { Modal } from 'react-bootstrap'
import { ReactSVG } from 'react-svg'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { SegmentModel } from '../../../../Model/segment/SegmentModel'
import { changeSegmentTab } from '../../../../slice/tabControl/TabControlSlice'
import "./AddSegmentModal.scss";
type AddSegmentModalProps={
  isSegmentModal:boolean
  handleClose:()=>void
}
const AddSegmentModal = ({isSegmentModal,handleClose}:AddSegmentModalProps) => {

    const getAllSegments= useSelector(getAllSegment)
    console.log("getAllSegments----segment",getAllSegments)
     const [selectedSegment, setSelectedSegment]= useState<SegmentModel|null>(null)
      const dispatch= useDispatch()
      const handleAddSegment=(eachSeg:SegmentModel)=>{
        if( eachSeg&&!eachSeg.isDisabled){
        setSelectedSegment(eachSeg)
      }else{
        alert("Segment is already added")
        // dispatch(addMessage({
        //     show:true,
        //   message:"Segment is already added"
         
        // }))
      }
    }

    const handleSave=()=>{
       console.log("selectedSegment",selectedSegment)
       if(selectedSegment && selectedSegment.name){
        dispatch(updateSegment({segment:selectedSegment}))
        dispatch(closeAddSegmentModal())  
        dispatch(changeSegmentTab("newSegment"))
        dispatch(addMessage({
          show:true,
          message:"Segment is added",
           toastType:"success"
        }))
       } else{
        dispatch(addMessage({
          show:true,
          message:"Please select a segment",
           toastType:"Error"
        }))
       //alert("Please select a segment")
        return
       }
     
    }
  return (
    <>
    <Modal
        show={isSegmentModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className="mb-0">Add Segment</h5>

            
          </div>
        </Modal.Header>

        <Modal.Body className="mb-2">
         
           <>
           {/* <h5 className='mb-0'>Please select the segment to create</h5> */}
           <span className="mb-3 fw-regular fs-6"><h5>{selectedSegment?.name}</h5></span>
           <div className='row'>
           {getAllSegments &&
             getAllSegments.length>0 &&
             getAllSegments.map((item,index)=>{
                const path = 'https://dzinlystrapi.s3.us-east-2.amazonaws.com'
              return(
                // <div >
                <>
    
                  <div 
                    className={`col-2 add-seg-model  rounded-1 m-2 pt-2 pb-1 ${item.isDisabled ? 'disabled' : ''}`}  
                    key={index}
                    onClick={() => !item.isDisabled && handleAddSegment(item)}
                    style={{ 
                      border: `1px solid ${item.color_code}`,
                      cursor: item.isDisabled ? 'not-allowed' : 'pointer',
                      opacity: item.isDisabled ? 0.6 : 1
                    }}
                  >
                    <span className='seg-icons'>
                      <ReactSVG
                        src={`${item.icon}`}
                        beforeInjection={(svg) => {
                          svg.setAttribute('style', `width: 100%; height: 100%; border-radius: 4px; ${item.isDisabled ? 'opacity: 0.6;' : ''}`);
                        }}
                        onError={(error) => {
                          console.error('Error loading SVG:', error);
                        }}
                      />
                    </span>
                    <h6 
                      className={`pt-2 fw-medium segments-item-title ${item.isDisabled ? 'disabled' : ''}`}
                      style={{ color: item.isDisabled ? '#999' : '#000' }}
                    >
                      {item.name}
                    </h6>

                    <div className='select-seg-checkbox d-none'>
                      <i className="bi bi-check-circle-fill fs-4"></i>
                    </div>

                    
                  </div>
                  </>
                // </div>
              )
             })
           }
           </div>
           <div className="actions-btns d-flex align-items-center justify-content-between mt-4 mb-3">
            <button
              className="btn btn-tranparent border-primary text-primary"
             
              onClick={handleClose}
              >


              Cancel
            </button>
            <button
              className="btn btn-primary"
             
              onClick={handleSave}
              >
              Add New Segment
            </button>
          </div>
           </>

          



        </Modal.Body>
       
      </Modal>
    </>
  )
}

export default AddSegmentModal