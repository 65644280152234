import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";
import { getUserJobBase64 } from "../../../slice/userJobSlice/UserJobSlice";
import { getSegregateSegment } from "../../../slice/segegratedSlice/SegregatedSlice";
import {
  changeSegmentTab,
  getTabControl,
  switchToOutline,
} from "../../../slice/tabControl/TabControlSlice";
import { getAllSegment } from "../../../slice/segment/SegmentSlice";
import { getSwitchCanvas } from "../../../slice/tabControl/TabControlSlice";
import { Segment } from "../../../Model/Job/JobModel";
import { JobSegmentModel } from "../../../Model/Job/JobModel";
import { PointModel } from "../../../Model/masterArray/MasterArrayModel";
import { addCanavasControl } from "../../../slice/canvas/masterArray/CanvasControlSlice";
import { addComment } from "../../../slice/projectCommet/ProjectCommentSlice";
import CommentHome from "../../ImageView/RightSection/comment/CommentHome";
import Tools from "../../ImageView/RightSection/tools/Tools";
import { UpdateToolActive } from "../../../slice/toolActive/ToolActiveSlice";

interface PointAnnotation {
  x: number;
  y: number;
}

interface CustomGroupOptions extends fabric.IGroupOptions {
  groupName?: string;
  subGroupName?: string;
  childGroupName?: string; // Example for an object
}
const CommentsCanvas = () => {
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const canvasElementRefs = useRef<HTMLCanvasElement | null>(null);
  const rightSectionRef = useRef<HTMLDivElement | null>(null);

  const getUserJobBase64s = useSelector(getUserJobBase64);
  const [scaleX, setScalex] = useState<number | null | undefined>();
  const [scaleY, setScaley] = useState<number | null | undefined>();
  const getSegregateSegments = useSelector(getSegregateSegment);
  const [annotationPoint, setAnnotationPoint] = useState<PointAnnotation[]>([]);
  const isCanvas = useRef(true);

  const dispatch = useDispatch();
  const getTabControls = useSelector(getTabControl);
  const getSwitchCanvass = useSelector(getSwitchCanvas);

  const getAllSegments = useSelector(getAllSegment);

  // Clean up canvas on unmount
  useEffect(() => {
    return () => {
      if (canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;
      }
    };
  }, []);
  // Handle canvas disposal on switch
  useEffect(() => {
    if (getSwitchCanvass === "segment" && 
      canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null; // Clear the reference after disposal
      dispatch(changeSegmentTab("segment"));
    } else if (getSwitchCanvass === "dimension" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null; // Clear the reference after disposal
      dispatch(changeSegmentTab("dimension"));
    } else if (getSwitchCanvass === "compare" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null; // Clear the reference after disposal
      dispatch(changeSegmentTab("compare"));
    } else if (getSwitchCanvass === "genAiImage" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null; // Clear the reference after disposal
      dispatch(changeSegmentTab("genAiImage"));
    }
  }, [getSwitchCanvass, dispatch]);

  // Initialize the canvas when the tab is set to "outline"
  useEffect(() => {
    if (
      getTabControls === "comments" &&
      !canvasRef.current &&
      canvasElementRefs.current
    ) {
      isCanvas.current = true;
      generateCanvas();
    }
  }, [getTabControls, canvasRef]);

  const generateCanvas = () => {
    const rightSection = rightSectionRef.current;
    const canvas = new fabric.Canvas(canvasElementRefs.current, {
      width:1023,
      height:592,
      // width:
      //   rightSection == null
      //     ? window.innerWidth
      //     : window.innerWidth - rightSection.offsetWidth,
      // height: window.innerHeight,
      backgroundColor: "#f0f0f0",
    });
    // (canvas as any).retinaScaling = 2;
    canvas.requestRenderAll();
    canvasRef.current = canvas;
    canvas.on("mouse:over", (event) => {
      handleMouseOver(event);
    });
    canvas.on("mouse:out", (event) => {
      handleMouseOut(event);
    });

    // canvas.on('mouse:down',
    //   (event) => {
    //     handleMouseDown(event);
    //  });
    //canvas.on('mouse:up', handleMouseUp);

    return () => {
      canvas.off("mouse:over", handleMouseOver);
      // canvas.off('mouse:out', handleMouseOut);
      // canvas.off('mouse:up', handleMouseUp);
    };
  };
  // Load the background image based on the job base64
  useEffect(() => {
    if (
      getUserJobBase64s &&
      canvasRef.current &&
      getTabControls === "comments"
    ) {
      const image = getUserJobBase64s;
      const encodedImage = "data:image/png;base64," + image;
      // const encodedImage = image;
      const decodedImage = atob(encodedImage.split(",")[1]);
      const uint8Array = new Uint8Array(decodedImage.length);

      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], { type: "image/png" });
      const imageUrl = URL.createObjectURL(blob);

      fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
        const canvas = canvasRef.current;
        if (
          canvas &&
          canvas.width &&
          canvas.height &&
          img.width &&
          img.height
        ) {
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;

          img.scaleToWidth(canvasWidth);
          img.scaleToHeight(canvasHeight);

          canvas.setBackgroundImage(img, canvas.requestRenderAll.bind(canvas), {
            scaleX: canvasWidth / img.width,
            scaleY: canvasHeight / img.height,
            originX: "left",
            originY: "top",
          });

          setScalex(canvasWidth / img.width);
          setScaley(canvasHeight / img.height);
          canvas.requestRenderAll();
        }
      });
    }
  }, [getUserJobBase64s, getTabControls]);
  // Handle Segregate Segments and draw polygons
  useEffect(() => {
    if (
      canvasRef.current &&
      getSegregateSegments &&
      getSegregateSegments.length > 0 &&
      getAllSegments &&
      getAllSegments.length > 0
    ) {
      getSegregateSegments.forEach((segmentModel: JobSegmentModel) => {
        Object.keys(segmentModel).forEach((key) => {
          const jobDetail = segmentModel[key] as Segment;
          const annotation = jobDetail?.details?.annotation;
          const cordinate = jobDetail?.details?.bb_annotation_int;
          const segName = key;
          const groupName = jobDetail?.details?.seg_type ?? "";
          const subGroupName = jobDetail?.details?.group ?? "";
          const childName = jobDetail?.details?.seg_short ?? "";
          const segColor = getAllSegments.find(
            (item) => item.name === groupName
          );
          const color = segColor?.color_code;
          if (annotation && cordinate && segName && color) {
            collectPoints(
              annotation,
              segName,
              cordinate,
              groupName,
              subGroupName,
              childName,
              color
            );
          }
        });
      });
    }
  }, [canvasRef.current, getSegregateSegments, scaleX, scaleY, getAllSegments]);

  const collectPoints = (
    annotation: number[],
    segName: string,
    coordinates: number[],
    groupName: string,
    subGroupName: string,
    childName: string,
    color: string
  ) => {
    if (annotation && scaleX && scaleY) {
      const points: PointModel[] = [];
      let i;
      for (i = 0; i < annotation.length; i += 2) {
        const x = annotation[i] * scaleX;
        const y = annotation[i + 1] * scaleY;
        points.push({ x, y });
      }
      if (points && points.length > 0) {
        makePolygon(
          points,
          coordinates,
          segName,
          groupName,
          subGroupName,
          childName,
          color
        );
      }
    }
  };

  const makePolygon = (
    points: PointModel[],
    coordinates: number[],
    polyName: string,
    groupName: string,
    subGroupName: string,
    childName: string,
    color: string
  ) => {
    if (
      points &&
      points.length > 0 &&
      coordinates &&
      polyName &&
      scaleX &&
      scaleY &&
      canvasRef.current
    ) {
      const text = new fabric.Text(polyName, {
        left: coordinates[0] * scaleX,
        top: coordinates[1] * scaleY,
        fontFamily: "Arial",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        fontSize: 18,
        fill: color,
        selectable: true,
        visible: false,
      });

      const polygon = new fabric.Polygon(points, {
        left: Math.round(coordinates[0] * scaleX), // Rounding to prevent subpixel rendering
        top: Math.round(coordinates[1] * scaleY),
        fill: "transparent",
        originX: "left",
        originY: "top",
        hasRotatingPoint: false,
        hasBorders: false,
        hasControls: false,
        stroke: color,

        strokeWidth: 2,
        opacity: 0.1,
        lockMovementX: true,
        lockMovementY: true,
      });

      const options: CustomGroupOptions = {
        selectable: false,
        lockMovementX: true,
        lockMovementY: true,
        hasRotatingPoint: false,
        hasBorders: false,
        hasControls: false,
        groupName: groupName,
        subGroupName: subGroupName,
        childGroupName: childName,
        subTargetCheck: true,
        name: polyName,
      };

      const group = new fabric.Group([polygon, text], options);
      group.on("mousedown", (event) => {
        
        handleGroupClick(event);
      });
      canvasRef.current.add(group);
      canvasRef.current.requestRenderAll();
    }
  };

  // return to main canavs
  const handlehoverLayer = () => {
    dispatch(UpdateToolActive(0))
    dispatch(switchToOutline("segment"))
    dispatch(changeSegmentTab('segment'))
   
  };

  const handleMouseOut = (event: fabric.IEvent) => {
    //  console.log("mouse out", event.target?.name)
    if (event.target) {
      // hideAnnotation(event)
      hidehoverEffectFromLeftSection();
    }
  };

  const showAnnotation = (name: string) => {
    console.log("Annotation name",name)
    const targetname = name;
    const allObjects = canvasRef.current?.getObjects();
    if (allObjects && allObjects.length > 0) {
      const currentObject = allObjects.find((item) => item.name == targetname);
      // console.log("currentObject",currentObject)
      const currentObjGroup = currentObject as fabric.Group;
      const showObject = currentObjGroup
        ?.getObjects()
        .filter((item) => item.name != "pattern");

      if (showObject) {
        showObject?.forEach((obj) => {
          if (!obj.visible) {
            obj.set({
              visible: true,
            });
          }
          if (obj.opacity === 0.1) {
            obj.set({
              opacity: 10,
              visible: true,
            });
          }

          canvasRef.current?.requestRenderAll();
        });
      } else {
        // console.log(" no currentObject",currentObject)
        //hideAnnotation(targetname)
      }
    }
  };

  // on mouse hover
  const handleMouseOver = (event: fabric.IEvent) => {
    //  console.log("event",event)
    //  console.log("canvasRef.current",canvasRef.current)
    if (event.target) {
      const targetnames = event.target?.name;
      if (targetnames) {
        showAnnotation(targetnames);
      }
    }
  };
  // hide hover effect
  const hidehoverEffectFromLeftSection = () => {
    const allObjects = canvasRef.current?.getObjects();
    if (allObjects && allObjects.length > 0) {
      allObjects.forEach((item: fabric.Object) => {
        const groupObject = item as fabric.Group;
        if (groupObject.name != "imageGroup") {
          const selectgroupObject = groupObject.getObjects();
          if (selectgroupObject) {
            selectgroupObject.forEach((obj) => {
              const name = obj.name + "pattern";

              if (obj.visible && obj.name != name) {
                obj.set({
                  visible: false,
                });
              }

              if (obj.opacity === 10 && obj.name != "patternPolygon") {
                obj.set({
                  opacity: 0.1,
                  visible: false,
                });
              }

              canvasRef.current?.requestRenderAll();
            });
          }
        }
      });
    }
  };

  const handleGroupClick = (data: fabric.IEvent) => {
   console.log("data", data);
    const pointer = data.pointer;
    console.log("mouse position", pointer);
    const canvas= canvasRef.current
    console.log("canvas", canvas);
    
   // 
    const currentObject = data.target as CustomGroupOptions;
    if (currentObject && pointer &&canvas) {
     
      const groupName = currentObject.groupName;
      const subGroupName = currentObject.subGroupName;
      const segShortName = currentObject.childGroupName;
      const segName = currentObject.name;
      const width = canvas.width;  // Canvas width in pixels
      const height = canvas.height;
      if(width && height){
        console.log("width", width);
        console.log("height", height);
        const xPos = Math.min(Math.max( pointer.x, 0), width - 725)
        const yPos = Math.min(Math.max(pointer.y, 0), height - 250);
       
        if (
          groupName &&
          subGroupName &&
          segShortName &&
          segName &&
          scaleY &&
          scaleX
        ) {
          const data = {
            groupName: groupName,
            subGroupName: subGroupName,
            segShortName: segShortName,
            segName: segName,
            isMainComment: true,
            isUserIcon: false,
            isEditComment: false,
            // x: pointer.x,
            // y: pointer.y,
            x: xPos,
            y:yPos,
          };
  
          dispatch(addCanavasControl(data));
  
          dispatch(addComment(data));
          showAnnotation(segName)
        }
      }
     
    }
  };
  return (
    <>
      <div className="position-relative  overflow-hidden">
        <div className="re-anno-section" onClick={handlehoverLayer}>
          <button className=" d-flex  align-items-center btn custom-back-button rounded-pill border-0 fs-6">
            <span className="fs-4">
              <i className="bi bi-arrow-left-short pe-1"></i>
            </span>
            Comments
          </button>
        </div>
        <canvas ref={canvasElementRefs} className="dzi-van" />
        <Tools/>
      </div>
      <CommentHome />
     
    </>
  );
};

export default CommentsCanvas;
