
import axios from "axios";
import { MaterialCategoryModel } from "../../../Model/material/MaterialCategoryModel";

// const serverApi = import.meta.env.SERVER_URL
const serverApi =process.env.REACT_APP_SERVER_URL

const databaserServer="http://localhost:5000"

// getAll maetrial catorgyies
export function GetAllMaterialsCategories() {
    
    return axios.get(`${serverApi}/material-library/categories`)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}

// update 
export function UpdateCategories(data:MaterialCategoryModel){
    return axios.post(`${serverApi}/material-library/categories/update`,data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}


// add
export function AddCategories(data:MaterialCategoryModel){
    return axios.post(`${serverApi}/material-library/categories/add`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// delete
export function DeleteCategories(id:number){
    const data={
        id:id
    }
    return axios.post(`${serverApi}/material-library/categories/delete`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}
// get categories name
export function GetCategoriesName(){
    
    return axios.get(`${serverApi}/material/categories`)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}
