import React from 'react'
import { Routes, Route } from 'react-router-dom';
import DasboardPage from '../../page/dasboardpage/DasboardPage';

import AdminBrandTablePage from '../../page/admin/adminBrand/AdminBrandTablePage';
import AdminCategoryTablePage from '../../page/admin/adminCategory/AdminCategoryTablePage';
import AdminStyleTablePage from '../../page/admin/adminstyle/AdminStyleTablePage';
import AdminSwatchesTablePage from '../../page/admin/adminSwatches/AdminSwatchesTablePage';
import AdminJobTable from '../../module/admin/jobAdmin/AdminJobTable';
import ShapeTable from '../../module/brandPage/shapes/ShapeTable';

import SegmentTable from '../../module/brandPage/Segment/SegmentTable';
import AdminProjectsTable from '../../module/admin/projectAdmin/AdminProjectsTable';
import AdminUser from '../../module/admin/AdminUser/AdminUser';
import UserTablePage from '../../page/user/UserTablePage';
import AdminGenAiAttributePage from '../../page/admin/adminGenAi/genAiAttribute/AdminGenAiAttributePage';

const DasboardRoute = () => {
  return (
   
      <Routes>
        <Route path="/" element={<DasboardPage />}/>
        <Route path="/swatch-brand" element={<AdminBrandTablePage/>} />
        <Route path="/swatch-categories" element={<AdminCategoryTablePage />} />
        <Route path="/swatch-styles" element={<AdminStyleTablePage />} />
        <Route path="/swatches" element={<AdminSwatchesTablePage />} />
        <Route path="/jobs" element={< AdminJobTable/>} />
        <Route path="/projects" element={< AdminProjectsTable/>} />
        <Route path="/users" element={< UserTablePage/>} />
        <Route path="/shape" element={< ShapeTable/>} />
        <Route path="/sagment" element={< SegmentTable/>} />


        <Route path="/gen-ai" element={< AdminGenAiAttributePage/>} />
      </Routes>
    
  )
}

export default DasboardRoute
