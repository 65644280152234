import { useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGenAiAttributes } from '../../../../slice/genAi/GenAiAttributesSlice'
import { EachOption, GenAiAttributesModel, OptionJDetails } from '../../../../Model/genAiAttributes/GenAiAttributes'
import DataTable from 'react-data-table-component'
import Loading from '../../../loading/Loading'
import { MaterialBrandModel } from '../../../../Model/material/MaterialBrandModel'
import EditGenAiAttribute from './editGenAiAttribute/EditGenAiAttribute'

const GenAiAtrributeTable = () => {
    const [isOpen, setIsOpen]= useState<boolean>(false)
    const dispatch= useDispatch()
    const getGenAiAttributess= useSelector(getGenAiAttributes)
    const [editAttribute, setEditAttribute]= useState<OptionJDetails|null>(null)
    const [allgenAttributes, setAllGenAttributes]= useState<OptionJDetails[]>([])

    // get All atributes from redix
    useEffect(()=> {
        if (getGenAiAttributess && getGenAiAttributess.length > 0) {
            const allAtt = getGenAiAttributess[0] as GenAiAttributesModel;
            let newFormate:OptionJDetails[]=[];
            (Object.keys(allAtt) as Array<keyof GenAiAttributesModel>).map((item) => {
                const value = allAtt[item] as OptionJDetails
              //   console.log("item",item)
                   const keyName= item as string
                if(value &&
                     value.title &&
                      value.type &&
                      value.options &&
                     value.options.length>0 && 
                     keyName 
                    ){
                    let optArray:EachOption[]=[]
                    const segOption=value.options
                      if(segOption && 
                        segOption.length>0){
                            segOption.map(opt=>{
                               // console.log("opt",opt)
                                optArray.push({
                                title:opt,
                                image:"",
                                slug:"",
                                isActive:false
                            })
                            })
                      }
                      if(optArray && optArray.length>0){
                        newFormate.push({
                              key:keyName,
                            title:value.title,
                            status:value.status?true:false,
                            is_featured:value.is_featured?true:false,
                            type:value.type,
                            priority:value.priority,
                            options:value.options,
                            option:optArray||[]
    
                        })
                      }
                    
                }
              

            })
            if(newFormate && newFormate.length>0){
                console.log(newFormate)
                setAllGenAttributes(newFormate)
            }
        }
    }, [getGenAiAttributess])
    

    // handle open edit modal
    const handleEdit=(data:OptionJDetails)=>{
        console.log("data====>",data)
        console.log("allgenAttributes====>",allgenAttributes)
        setEditAttribute(data)
        setIsOpen(true)
    }

    // close edit modal
    const handleCloseEdit=()=>{
        setIsOpen(false)
        setEditAttribute(null)
    }
    
    const columns = [
       
        {
          name: "Title",
          selector: (row: OptionJDetails) => row.title??"",
          width: '150px',
        
        },
        {
          name: "Type",
          selector: (row: OptionJDetails) => row.type??"",
          width: '100px',
         
        },
        {
          name: "Options",
          cell: (row: OptionJDetails) => (
            <ul>
              {row.options?.map((option, index) => (
                <li key={index}>{option}</li>
              )) || <li>No options available</li>}
            </ul>
          ),
        },
        {
          name: "Is featured",
          selector: (row: OptionJDetails) => row.is_featured?"true":"false",
        },
       
        {
          name: "Status",
          selector: (row: OptionJDetails) => row.status?"true":"false",
        },
        {
          name: "Priority",
          selector: (row: OptionJDetails) => row.priority??"",
        },
       
        {
          name: "Action",
          cell: (row: OptionJDetails) => (
            <>
      <div className="d-flex action">
                      <div className="action-icon-table" 
                      onClick={() => handleEdit(row)}
                      >
                        <i className="bi bi-pencil-square mx-0"></i>
                      </div>
                      <div className="action-icon-table">
                        <i className="bi bi-copy mx-0"></i>
                      </div>
                      <div className="action-icon-table">
                        <i className="bi bi-box-arrow-up-right mx-0"></i>
                      </div>
                      <div className="action-icon-table trash-icon-media"
                      // onClick={() => handleDelete(row)}
                       >
                        <i className="bi bi-trash3 mx-0"></i>
                      </div>
                    </div>
                    </>)
          }, 
      ];
  return (
    <>
      <div className='card mx-3'>
        <div className='card-header bg-white p-2 d-flex justify-content-between align-items-center'>
          <input
            type="search"
            className="form-control w-auto"
            placeholder="Search"
          />
          <button type='button' className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#exampleModal">Create new entry</button>
        </div>

  <div className='card-body p-0'>

  
      

       
          
{
    allgenAttributes &&
    allgenAttributes.length>0 &&
    <DataTable 
            columns={columns} 
            data={allgenAttributes?.map((item) => ({ ...item, key: item.key}))}

            pagination
            fixedHeader
          />
          }

          

   
      




     
      </div>
      </div>

      {/* open to edit gen Ai attribute */}
         {editAttribute &&
         <EditGenAiAttribute
         isOpen={isOpen}
         onClose={handleCloseEdit}
         editAttribute={editAttribute}
         />}
    </>

  )
}

export default GenAiAtrributeTable