import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobModel } from "../../../Model/Job/JobModel";
import { BrandDocModel } from "../../../Model/material/MaterialBrandModel";
import { userModel } from "../../../Model/user/UserModel";


interface UserStates {
    isUserSearch:boolean,
    userResponse:string,
    allUsers:userModel[],
  
   
    
}

const initialState:UserStates = {
    allUsers:[],
    userResponse:"",
    isUserSearch:false,
   
};
const AllUserSlice = createSlice({
    name: "allUSers",
    initialState,
    reducers: {
        resetAllUserSlice:(state)=>{
            return initialState
        }

        ,
        addIsUserSearch:(state,action)=>{
            state.isUserSearch= action.payload
        },
        addAllUserData:(state,action)=>{
            const {user, response}=action.payload
            
            state.allUsers= user
            state.userResponse= response
        },
     
        
         resetAllUsers:(state)=>{
            state.allUsers=[]
        },
        

    }
}
)

export const {
    resetAllUserSlice,
    addIsUserSearch,
    addAllUserData,
    resetAllUsers,
   
}=AllUserSlice.actions;
export const getAllUserData=  (state:{allUSers:UserStates})=>state.allUSers.allUsers
export const getIsSearchUser=  (state:{allUSers:UserStates})=>state.allUSers.isUserSearch
export const getUserResponse=  (state:{allUSers:UserStates})=>state.allUSers.userResponse
export default AllUserSlice.reducer