import React, { useEffect, useState } from 'react'
import GetAllMaterialsHome from '../../getAllMaterials/GetAllMaterialsHome'
import LeftSection from '../../leftSection/LeftSection'
import Projects from '../../brandPage/projects/Projects'
import Header from '../../header/Header'
import Sidebar from '../../dasboardpages/components/Sidebar'
import PageContentHeader from '../../dasboardpages/components/pageContent/PageContentHeader'
import SearchAdd from '../../dasboardpages/components/pageContent/SearchAdd'
import { getAllProjectData } from '../../../slice/Admin/projectSlice/ProjectSlice'
import { useSelector } from 'react-redux'
import GetAllProjects from '../../brandPage/projects/callApi/GetAllProjects'

const AdminProjectsTable = () => {
  const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>('Material Library'); 
  const [secondHeader, setSecondHeader] =useState<string>('Projects') 
 
  const [breadCum1, setbreadCum1] =useState<string>('Home') 
  const [breadCum2, setbreadCum2] =useState<string>('Project') 
  const [breadCum3, setbreadCum3] =useState<string>('Projects') 
  const [page, setPage] =useState<number>(1) 
  const [limit, setLimit] =useState<number>(50)  
  const[isProject, setIsProject]= useState<boolean>(false)
  
  const getAllProjectDatas= useSelector(getAllProjectData )
  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };


  // get all projects initailly
  useEffect(()=>{
    if(getAllProjectDatas &&
       getAllProjectDatas.length==0){
             setIsProject(true)
    } else{
      setIsProject(false)
    }
  },[getAllProjectDatas])

  const handleResetProject=()=>{
    setIsProject(false)
  }
  return (
    <>
    <div className="mt-5 pt-4">
      <Header/>
    <div  className={`wrapper ${isActive ? 'active' : ''}`}>
         <Sidebar toggleActiveClass={toggleActiveClass} />
        {/* content header */}
        
         <PageContentHeader
         firstHeader={firstHeader}
         secondHeader={secondHeader}
         breadCum1={breadCum1}
         breadCum2={breadCum2}
         breadCum3={breadCum3}
         
          />
        

        <SearchAdd/>

          {/* table */}
         
          <Projects/>



         
    </div>
      
    </div>

    { isProject &&
    <GetAllProjects
    page={page}
    limit={limit}
    resetAllProjects={handleResetProject}
    />}
    </>

  )
}

export default AdminProjectsTable