import React, { useEffect, useRef } from 'react'
import { UploadImagePresignedS3Bucket } from '../../../../api/projects/ProjectApi';
import { useDispatch, useSelector } from 'react-redux';
import { useSelect } from '@react-three/drei';
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice';
import { getUserLogin } from '../../../../slice/user/UserSlice';
import { addScreenShotImageUrl } from '../../../../slice/imageDepthSlice/ImageDepthSlice';
import axios from 'axios';
import { addMessage } from '../../../../slice/messageToast/ToastSlice';

type Props={
    screenShotImage:File
    resetScreenUpload:()=>void
}

const UploadScreenShortToS3 = ({screenShotImage,resetScreenUpload}:Props) => {
      const dispatch= useDispatch()
      const getMasterArrays= useSelector(getMasterArray)
      const getUserLogins= useSelector(getUserLogin)

      const isApi= useRef(true)
    useEffect(()=>{
        if(getMasterArrays &&
            getMasterArrays.project_id &&
             screenShotImage &&
             getUserLogins[0].role
            ){
                const imagename= `screenShot/${screenShotImage.name}`
                const role= getUserLogins[0].role
                upLoadPreSignedImage(getMasterArrays.project_id,screenShotImage,imagename,role)     
             }
    },[getMasterArrays,screenShotImage,getUserLogins])


    const upLoadPreSignedImage = async (id: number, file: File, imagename: string, role:string) => {
        try {
          const formData = new FormData();
          formData.append('image', file);
          formData.append('id', id.toString());
          formData.append('imagename', imagename);
          const response = await UploadImagePresignedS3Bucket(file, role, id.toString(),imagename);
          console.log("response presignws upload image", response);
          
          if (response.status === 200 && response.data) {
            const { presignedUrl } = response.data;
            //const imageBuffer = await file.arrayBuffer();
            const uploadImage = await uploadImageUsingPresignedUrl(presignedUrl,file );
              console.log("uploadImage",uploadImage)
            if (uploadImage) {
                dispatch(addScreenShotImageUrl(uploadImage))
                 resetScreenUpload()

            }
          } else {
            throw new Error('Upload failed');
          }
        } catch (err) {
          if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }
          //console.error("Error in upload Image:", err);
         // resetUploadImage("fail");
         resetScreenUpload()
        } finally {
          isApi.current = true;
        }
      };
  
  
   
      
      const uploadImageUsingPresignedUrl=async(presignedUrl: string, file:File)=> {
        try {
          const response = await fetch(presignedUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': file.type, // Adjust based on your image type
            },
            body: file,
           
          });
         
          console.log("upload pres signed image Api", response)
          if (!response.ok) {
            throw new Error(`Failed to upload image. Status: ${response.status}`);
          }
         
          return presignedUrl.split('?')[0]; // Return the URL of the uploaded image without query parameters
        } catch (err) {
          console.error("Error uploading image", err);
          throw err;
        }
      }
  return (
    <></>
  )
}

export default UploadScreenShortToS3