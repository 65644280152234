import React, { useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addIsDownloadCategory, addMaterialCategory, getIsDownloadCategory, getMaterialCategorys, updateResponseCategory } from '../../slice/materialSlice/MaterialCategorySlice'
import { GetAllMaterialsCategories } from '../../api/material/category/CategoryApi'
import { addMessage } from '../../slice/messageToast/ToastSlice'
import axios from 'axios'

const GetAllMaterialCaterogy = () => {
    

  const dispatch= useDispatch()
  const getMaterialCategory= useSelector(getMaterialCategorys)
  const getIsDownloadCategorys= useSelector(getIsDownloadCategory)
   const isApi=useRef(true)
   useEffect(()=>{
     if(getMaterialCategory && 
      getMaterialCategory.length===0 &&
      getIsDownloadCategorys &&
      isApi.current
    ){
      isApi.current= false
      getAllMaterials_category()
      dispatch(addIsDownloadCategory(false))
     }
      
   },[getMaterialCategory,getIsDownloadCategorys])
    const getAllMaterials_category= async()=>{
        try{
     
             const response= await GetAllMaterialsCategories()
                 if(response && response.status===200){
                  
                  dispatch(addMaterialCategory(response.data))
                  dispatch(updateResponseCategory("sucessfully get all category"))
                  dispatch(addIsDownloadCategory(false))
                  isApi.current= true
                 }
        }catch(err){
          if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }
           isApi.current= true
         
           dispatch(updateResponseCategory("Error on  getting category"))
           dispatch(addIsDownloadCategory(false))
        }

    }
  return (
    <div></div>
  )
}

export default GetAllMaterialCaterogy