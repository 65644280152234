import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BrandDocModel, MaterialBrandModel, SearchPageModel, SwatchBrandModel } from "../../Model/material/MaterialBrandModel";
import { NameModel } from "../../Model/material/MaterialModel";


interface MaterialBrandStates {
    allBrandName:NameModel[],
    searchBrandName:NameModel[],
    materialBrand:MaterialBrandModel[],
    isDownloadBrand:boolean,
    searchBrand:SearchPageModel,
    brandResponse:string,
    brandDoc:BrandDocModel,
   swatchBrand:SwatchBrandModel[],
   editBrand:SwatchBrandModel,
    

}

const initialState: MaterialBrandStates = {
    allBrandName:[],
    materialBrand:[],
    searchBrandName:[],
    searchBrand:{},
    brandDoc:{},
    swatchBrand:[],
    editBrand:{},
    isDownloadBrand:false,
    brandResponse:"",

};

const MaterialBrandSlice = createSlice({
    name: "materialBrand",
    initialState,
    reducers: {
        resetBrand:(state)=>{
            return initialState
        },
        addBrandName:(state,action)=>{
           state.allBrandName=action.payload
        },
       addMaterialBrand:(state,action)=>{
        state.materialBrand= action.payload
       },
       addBrandDoc:(state,action)=>{
        const {totalDocuments,totalPages,currentPage,pageSize}=action.payload;
        state.brandDoc.currentPage=currentPage
        state.brandDoc.pageSize=pageSize
        state.brandDoc.totalDocuments=totalDocuments
        state.brandDoc.totalPages=totalPages
       },
       addSwatchBrand:(state,action)=>{
        state.swatchBrand= action.payload;
       }, 
       addEditBrand:(state,action)=>{
        state.editBrand= action.payload;

       },
       updateEditBrand:(state,action)=>{
        const {data}= action.payload;
        const brandIndex=state.materialBrand.findIndex(item=>item.id===data.id)
         if(brandIndex!=-1){
            state.materialBrand[brandIndex].description=data.description
            state.materialBrand[brandIndex].material_category_id=data.material_category_id
            state.materialBrand[brandIndex].title=data.title
            state.materialBrand[brandIndex].slug=data.slug
            state.materialBrand[brandIndex].sort_order=data.sort_order
            state.materialBrand[brandIndex].status=data.status
        }
       },
       addNewBrand:(state,action)=>{
        const {data}=action.payload
          state.materialBrand.push(data)
       },
        resetEditBrand:(state)=>{
         state.editBrand={}
       }, 
       deleteBrand:(state,action)=>{
        const {id}= action.payload;
        const brandIndex= state.swatchBrand.findIndex(item=>item.id===id)
            if(brandIndex !=-1){
                state.swatchBrand.slice(brandIndex,1)
            }
        },
        addIsDownloadBrand:(state,action)=>{
            state.isDownloadBrand= action.payload
        },
        addBrandResponse:(state,action)=>{
        state.brandResponse=action.payload
        },
        addSearchBrand:(state,action)=>{
            const { page,pageSize}=action.payload
            state.searchBrand.page=page
            state.searchBrand.pageSize=pageSize
        },
        addSearchBrandName:(state,action)=>{
            state.searchBrandName=action.payload
        },
        resetSearchBrandName:(state)=>{
            state.searchBrandName=[]
        }
        

       }

}
)

export const {
    addBrandName,
    addMaterialBrand,
    addSwatchBrand,
    addEditBrand,resetEditBrand,updateEditBrand,
    deleteBrand,addNewBrand,
    resetBrand,
    addIsDownloadBrand,
    addBrandResponse,
    addSearchBrand,
    addBrandDoc,
    addSearchBrandName,
    resetSearchBrandName

}=MaterialBrandSlice.actions;
export const getMaterialBrand=  (state:{materialBrand:MaterialBrandStates})=>state.materialBrand.materialBrand;
export const getSwatchBrand=  (state:{materialBrand:MaterialBrandStates})=>state.materialBrand.swatchBrand;

export const getEditBrand=  (state:{materialBrand:MaterialBrandStates})=>state.materialBrand.editBrand;
export const getIsDownloadBrand=  (state:{materialBrand:MaterialBrandStates})=>state.materialBrand.isDownloadBrand;
export const getBrandResponse=  (state:{materialBrand:MaterialBrandStates})=>state.materialBrand.brandResponse;

export const getBrandDoc=  (state:{materialBrand:MaterialBrandStates})=>state.materialBrand.brandDoc;
export const getSearchBrand=  (state:{materialBrand:MaterialBrandStates})=>state.materialBrand.searchBrand;

export const getAllBrandName=  (state:{materialBrand:MaterialBrandStates})=>state.materialBrand.allBrandName;
export const getSearchBrandName=  (state:{materialBrand:MaterialBrandStates})=>state.materialBrand.searchBrandName;

export default MaterialBrandSlice.reducer
