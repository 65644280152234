import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ProjectImage } from '../../../../Model/mediaModel/MediaModel'
import { getGenAiImage } from '../../../../slice/userJobSlice/UserJobSlice'
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import { getProjectGenAiImage } from '../../../../slice/genAi/GenAiAttributesSlice'
import { GalleryModel, GenAiImageModel } from '../../../../Model/genAiAttributes/GenAiImages'


const s3BucketUrl=process.env.REACT_APP_S3Bucket
const urlpath = process.env.REACT_APP_Project_Image
const GenAiGallery = () => {
    const getGenAiImages= useSelector(getGenAiImage)
    const [genImage, setGenImage]= useState<ProjectImage[]>([])
    const [genAiGallery, setGenAiGallery]= useState<GenAiImageModel|null>(null)
    const getMasterArrays = useSelector(getMasterArray)
    const getProjectGenAiImages= useSelector(getProjectGenAiImage)
   
  


    // insert gen Ai gallery image
    useEffect(()=>{
        if(getProjectGenAiImages && 
            getProjectGenAiImages.gallery &&
            getProjectGenAiImages.gallery.length>0 &&
            getProjectGenAiImages.project_id
        ){
            setGenAiGallery(getProjectGenAiImages)
        }
    },[getProjectGenAiImages])

  
  return (
    <div  className='gen-ai-image  px-4 pt-3'>
    {
        genAiGallery && 
        genAiGallery.project_id &&
        genAiGallery.gallery &&
        
        genAiGallery.gallery.length>0?(
            genAiGallery.gallery.map(item=>{
                    let path:string=""
                   if(item.image_name=="Project Image"){
 path=`${urlpath}/${genAiGallery.project_id}/${item.image_path}`
                   }else{
                     path=`${urlpath}/${genAiGallery.project_id}/styleGen/${item.image_path}`
                   }
                    
                    let names:string=""
                    if(item.image_name){
                         names= item?.image_name.charAt(0).toUpperCase() + item?.image_name.slice(1).toLowerCase();
                    }

                   
                   
                    return(
                        <>
                        <div>
                       {
                        <h6>{names }</h6>}
                         <img   src={path} alt="no image"  key={item.image_name}/>
                         <h6><span>Prompt:-</span>{item.prompt}</h6>

                         
                         </div>
                        </>
                       
                    )
                })
            
        ):
        (<h6> No genai image</h6>)
    }
    
</div>
  )
}

export default GenAiGallery