import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { creditUpdate } from '../../../../api/genAi/GenAiAPi'
import axios from 'axios'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { updateCredits } from '../../../../slice/user/UserSlice'

type Props={
    email:string
    credit:number
    resetUpdateCredit:()=>void
}
const UpdateCredit = ({email, credit,resetUpdateCredit}:Props) => {

    const dispatch= useDispatch()
    const isApi= useRef(true)
    useEffect(()=>{
        if(email &&
            credit &&
           isApi.current
        ){
            isApi.current= false
            updateUserCredit(email, credit)
        }
    },[email,credit])

    const updateUserCredit=async(email:string, credit:number)=>{

         try{

    const response= await creditUpdate(email, credit)
         if(response && response.status==200){
            dispatch(updateCredits({
                email:email,
                credit:credit

            }))
            isApi.current= true
            resetUpdateCredit()
         }
         }catch(err){
             
            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))

                   isApi.current= true
                   resetUpdateCredit()
            }
    }

    }
  return (
    <></>
  )
}

export default UpdateCredit