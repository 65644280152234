import React, { useEffect, useRef } from 'react'
import { GetUserComments } from '../../../../api/projectComments/ProjectCommentsApi'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { addProjectComment } from '../../../../slice/projectCommet/ProjectCommentSlice'
import { is } from '@react-three/fiber/dist/declarations/src/core/utils'
import axios from 'axios'


type Props={
    jobId:number,
    resetGetComment:()=>void
}
const GetComments = ({jobId,resetGetComment}:Props) => {
    const dispatch= useDispatch()
    const isApi= useRef(true)
    useEffect(()=>{
        if(jobId && isApi.current){
            isApi.current= false
            getAllProjectCommnet(jobId)
        }
    },[jobId])

    const getAllProjectCommnet=async(jobId:number)=>{
        try{


            const response = await GetUserComments(jobId);
            if(response && response.status==200){
                console.log("get all user comments", response)
                 dispatch(addProjectComment({
                    addMessage:response.data
                 }))
           dispatch(addMessage({
            isShow:true,
         mess:"Get all user Comments",
         toastType:"success"
           }))

           isApi.current= true
           resetGetComment()
            }
        } catch(err){

            if(axios.isAxiosError(err)){
            
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))
            }
                
       isApi.current= true
       resetGetComment()
        }  

    }
  return (
    <>
        
    </>
  )
}

export default GetComments