import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userModel } from "../../Model/user/UserModel";

interface UserStates {
    user: userModel[];
    
}

const initialState: UserStates = {
    user: [],
};

const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser: (state, action: PayloadAction<userModel>) => {
            state.user.push(action.payload);
        },
        resetUser: (state) => {
            state.user = [];
        },
        updateUserprojects:(state, action)=>{
             const {user}=action.payload;
             const userIndex= state.user.findIndex(item=>item.email===user.email);;
             if(userIndex!=-1){
                state.user[userIndex].projects=user.projects
             }
            
        },
        updateCredits:(state,action)=>{
               const {email, credit}= action.payload
               const findUser= state.user.findIndex(item=>item.email==email)
               if(findUser!=-1){
                state.user[findUser].credit=credit
               }
        }
    },
});

export const { addUser,
     resetUser,
     updateUserprojects,
     updateCredits } = UserSlice.actions;
export const getUserLogin = (state: { user: UserStates }) => state.user.user;
export default UserSlice.reducer;
