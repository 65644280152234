import React, { useState } from 'react'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'
import LeftSection from '../../../leftSection/LeftSection'
import EditCategory from '../../../brandPage/category/EditCategory'
import { MaterialCategoryModel } from '../../../../Model/material/MaterialCategoryModel'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { startLoading, stopLoading } from '../../../../slice/loading/LoadingSlice'
import UpdateCategoryApi from './api/UpdateCategoryApi'
import Header from '../../../header/Header'
import Sidebar from '../../../dasboardpages/components/Sidebar'
import PageContentHeader from '../../../dasboardpages/components/pageContent/PageContentHeader'
import SearchAdd from '../../../dasboardpages/components/pageContent/SearchAdd'

const AdminCategoryEdit = () => {
     const navigate= useNavigate()
     const dispatch= useDispatch()
  const [ isEditApi, setIsEditApi]= useState<boolean>(false)
  const [editCategory, setEditCategory]= useState<MaterialCategoryModel|null>()
   const handleUpdate=(data:MaterialCategoryModel)=>{
    dispatch(startLoading())
    setEditCategory(data)
    setIsEditApi(true)
   }


   const handleResetUpdate=(mess:string)=>{
      if(mess==="success"){
        setIsEditApi(false)
        setEditCategory(null)
        dispatch(stopLoading())
        navigate("/dashboard/swatch-categories")
        alert("category updated successfully")
        
      }else if( mess==="fail"){
        setIsEditApi(false)
        setEditCategory(null)
        dispatch(stopLoading())
        navigate("/dashboard/swatch-categories")
        alert("Error in updating category")
      }
   }
   const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>('Material Library'); 
  const [secondHeader, setSecondHeader] =useState<string>('Segments') 
 
  const [breadCum1, setbreadCum1] =useState<string>('Home') 
  const [breadCum2, setbreadCum2] =useState<string>('Library') 
  const [breadCum3, setbreadCum3] =useState<string>('Segments') 
 
  
  
  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };
  return (
   <>
   {/* <Header/> */}
  

    <div className="mt-5 pt-4">
<Header/>
    <div  className={`wrapper  ${isActive ? 'active' : ''}`}>
    <Sidebar toggleActiveClass={toggleActiveClass} />
   {/* content header */}
   
   <EditCategory
 updateCategory={handleUpdate}

 />


 {/* update category Api */}
       { isEditApi &&editCategory &&
       <UpdateCategoryApi
       resetUpdate={handleResetUpdate}
       editCategory={editCategory}
       />}
    
     {/* table */}
    
     {/* <SegmentTableData/> */}
     </div>
     </div>

     {/* <div className='row'>
<LeftSection/>
<div className="col-9 brand-right-section">

 <EditCategory
 updateCategory={handleUpdate}

 />


 {/* update category Api */}
       {/* { isEditApi &&editCategory &&
       <UpdateCategoryApi
       resetUpdate={handleResetUpdate}
       editCategory={editCategory}
       />}
</div>



{/* getAll materials */}
{/* </><GetAllMaterialsHome/> */}
{/* </div> */}
   </>
  )
}

export default AdminCategoryEdit