import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSearchBrandName } from '../../../../../../slice/materialSlice/MaterialBrandSlice'
import { addSearchBrandId } from '../../../../../../slice/materialSlice/SearchMaterial'

const BrandSearch = () => {
    const dispatch= useDispatch()
    const getSearchBrandNames= useSelector(getSearchBrandName)

  
    const handleSelectBrand=(id:number)=>{
       // dispatch(updateIsBrandModal(true))
       if(id){
        dispatch(addSearchBrandId({
          id:id
         }))
       }
      
    }

  return (
    <>
    
    <ul className="list-group list-group-flush">
                     { getSearchBrandNames &&
                     getSearchBrandNames.length>0 &&
                     getSearchBrandNames.map(item=>{
                      return (
                        <li className="list-group-item px-0"
                        key={item.id}
                        >
                        <input className="form-check-input me-1" type="checkbox" id="firstCheckbox" 
                         onChange={()=>handleSelectBrand(item.id??0)}
                        />
                        <label className="form-check-label" htmlFor="firstCheckbox">{item.title}</label>
                      </li>
                      )
                     })
                     }
                      {/* <li className="list-group-item px-0">
                        <input className="form-check-input me-1" type="checkbox" id="secondCheckbox" />
                        <label className="form-check-label" htmlFor="secondCheckbox">Second checkbox</label>
                      </li>
                      <li className="list-group-item px-0">
                        <input className="form-check-input me-1" type="checkbox" id="thirdCheckbox" />
                        <label className="form-check-label" htmlFor="thirdCheckbox">Third checkbox</label>
                      </li> */}
                    </ul>



    </>
  )
}

export default BrandSearch