import { Tooltip } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeSegmentTab, switchToOutline } from '../../../../../slice/tabControl/TabControlSlice'
import { JobSegmentationModel } from '../../../../../Model/masterArray/MasterArrayModel'
// import { addInformation, addSelectedSegment, getSelectedSegment } from '../../../../../slice/userJobSlice/UserJobSlice'
import { useNavigate } from 'react-router-dom'
import { useSelect } from '@react-three/drei'
import { addReAnnotationSegment, deleteSegment, getMasterArray, startSegHoverEffect, stopSegHoverEffect, updateIndexing } from '../../../../../slice/canvas/masterArray/MasterArraySlice'

import DeleteSegmentApi from '../../deleteSegment/DeleteSegmentApi'
import Information from './Information'
import "./Action.scss"
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'
import { getDeleteStatus, openCloseDeleteSegment, stopDeletingSegment } from '../../../../../slice/canvas/masterArray/CanvasControlSlice'

import Offcanvas from 'react-bootstrap/Offcanvas';
import AnnotationUpdateApi from '../../../../canavas/masterDataAnotationApi/AnnotationUpdateApi'
import { startLoading, stopLoading } from '../../../../../slice/loading/LoadingSlice'
import { SegmentDetails } from '../../../../../Model/Job/SamModel'
import { updateAnnoatationInSegegtated } from '../../../../../slice/segegratedSlice/SegregatedSlice'
import { OverlayTrigger } from 'react-bootstrap'
type Props={
    allSegDetail:JobSegmentationModel
    groupName:string
}
const Action = ({allSegDetail,groupName}:Props) => {
  const[isViewSwatchActive, setIsViewSwatchActive]= useState(false)
  const[isEditActive, setIsEditActive]= useState(false)
  const [eachSegmentData, setEachSegmentData]= useState<JobSegmentationModel|null>(null)
  const[ deleteName, setDeleteName]= useState<string|null>(null)
    const[grpName, setGrpName]= useState<string|null>(null)
  // const getSelectedSegments= useSelector(getSelectedSegment)
  const [jobId, setJobId]= useState<number|null>(null)
  const [isInfo, setIsInfo]= useState<boolean>(false)
  const [subGroupName, setSubGroupName]= useState<string|null>(null)
  const [childName, setChildName]= useState<string|null>(null)
  const [childShortName, setChildShorName]= useState<string|null>(null)
  const [isDeleteApi, setIsDeleteApi]= useState<boolean>(false)
  const [annotation, setAnnotation]= useState<number[]>([])
 const [isReAnnotationApi, setIsReAnnotationApi]= useState<boolean>(false)
  // const [groupNames, setGroupName]= useState<string|null>(null)
  // const [childGroupName, setChildGroupName]= useState<string|null>(null)

    const dispatch= useDispatch()

    // selected segement
      useEffect(()=>{
       
        if(allSegDetail && 
          allSegDetail.details && 
          allSegDetail.details.group &&
          allSegDetail.details.seg_type &&
          allSegDetail.details.seg_short &&
          allSegDetail.details.seg_name &&
          allSegDetail.details.annotation 

        ){
          setGrpName( allSegDetail.details.seg_type)
          setSubGroupName(allSegDetail.details.group)
          setChildName(allSegDetail.details.seg_name)
          setChildShorName(allSegDetail.details.seg_short)
          setEachSegmentData(allSegDetail)
          setAnnotation( allSegDetail.details.annotation )
        }else{
          setEachSegmentData(null)
        }
      },[allSegDetail,groupName])


     


    const navigate= useNavigate()
     const getMasterArrays= useSelector(getMasterArray)
     useEffect(()=>{
        if(getMasterArrays && 
          getMasterArrays.jobId){
          
            setJobId(getMasterArrays.jobId)
        }else{
            setJobId(null)
        }
     },[getMasterArrays])

     const handleEditSegment=()=>{
      
       dispatch(switchToOutline("editAnnotation"))
       dispatch(changeSegmentTab('editAnnotation'))
      //  dispatch(addSelectedSegment(allSegDetail))
       // navigate(`/project/edit/${jobId}`)
     }


     // handle Reannationa

     const handleReAnnotation=()=>{
  
      // console.log("reAnnotationLayer")
      const data=  {
        groupName:subGroupName ,  
        segName:allSegDetail.segName,   
        name: allSegDetail.name
       
       }
       
         dispatch(addReAnnotationSegment(data ))
         dispatch(switchToOutline("reAnnotation"))
         dispatch(changeSegmentTab('reAnnotation'))

        //  navigate(`/project/re-annotation/${jobId}`)
     }

     const handleHoverSegment=()=>{
       
      dispatch(startSegHoverEffect({
        seg:[eachSegmentData]}))
   }
   const handleOffHoverSegment=()=>{
     
     dispatch(stopSegHoverEffect())
   }

   const handleDeleteSegment=()=>{
        
       setDeleteName(eachSegmentData?.segName??"")
       dispatch(openCloseDeleteSegment(true))
   }

const getDeleteStatuss= useSelector(getDeleteStatus)
useEffect(()=>{
  if(getDeleteStatuss){
    setIsDeleteApi(true)
  }else{
    setIsDeleteApi(false)
  }
},[getDeleteStatuss])

// reset Delete Api
const handleResetDelete=async(data:boolean)=>{
    dispatch(stopDeletingSegment())
     if(data){

      dispatch(addMessage({
        isShow:true,
        mess:"Segment delete sucessFully",
         toastType:"success"
       }))
      // alert("Segment delete sucessFully")
      setDeleteName(null)
      
      // delete from master Array

      dispatch(deleteSegment({
        groupName:grpName,
        subGroupName:subGroupName,
        childName:childName,
        childShortName:childShortName
      }))

      // // // delete from canavas
      // await dispatch(deleteSegmentFromCanvas({
      //   childName:childName,
      //   childShortName:childShortName
      // }))

      // await dispatch(updateIndexing({
      //   groupName:grpName,
      // }))
     } else{
      dispatch(addMessage({
        isShow:true,
        mess:"some thing went wrong",
         toastType:"Error"
       }))
       //alert("some thing went wrong")
     }
}

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);


 const handleSegmentInfo =()=>{
  setShow(true)
  // const data={
  //   isInfo:true,
  //   segment:eachSegmentData
  // }
  //  dispatch(addInformation(data))
 }
 

// after anotation click api
const handleRegenerate=()=>{
    if(eachSegmentData &&
      eachSegmentData.details &&
       eachSegmentData.details?.annotation &&
       eachSegmentData.details.label
       
      ){
        setIsReAnnotationApi(true)
    }else{
      setIsReAnnotationApi(false)
      alert("please select the segment")
    }
}

const handleFailResetAnnotation=()=>{
  setAnnotation([])
setIsReAnnotationApi(false)
  dispatch(stopLoading())

}

const handleResetAnotation=(data:SegmentDetails)=>{
  setAnnotation([])
  setIsReAnnotationApi(false)
  dispatch(stopLoading())

  const masterValue={
    segName:childName,
    childgroupName:subGroupName,
    seg_short:childShortName,
    groupName:grpName,
    masterData:data
  }
  
  dispatch(startLoading())
  // update in segretaed  Array
  dispatch(updateAnnoatationInSegegtated(masterValue))
     
}

  return (
<>

<ul className='seg-actions m-0' role="tablist" 
    onMouseEnter={handleHoverSegment}
    onMouseLeave={handleOffHoverSegment}
    >
      
          <li className="nav-item" role="presentation"
          //  onClick={handleSegmentInfo}
           onClick={handleRegenerate}
          >
              <button className="nav-link"  aria-selected="false" >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-r-circle mx-auto" viewBox="0 0 16 16">
                <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.5 4.002h3.11c1.71 0 2.741.973 2.741 2.46 0 1.138-.667 1.94-1.495 2.24L11.5 12H9.98L8.52 8.924H6.836V12H5.5zm1.335 1.09v2.777h1.549c.995 0 1.573-.463 1.573-1.36 0-.913-.596-1.417-1.537-1.417z"/>
              </svg>
              </button>
          </li>
          <li className="nav-item" role="presentation"
          //  onClick={handleSegmentInfo}
           onClick={handleSegmentInfo}
          >
              <button className="nav-link"  aria-selected="false" >
              <i className="bi bi-info-square"></i>
              </button>
          </li>
          {/* active-icon */}
          <li className={`nav-item `} role="presentation" onClick={handleEditSegment}
          >
              <button className="nav-link"  aria-selected="false"
             
              >
                  <i className="bi bi-pencil-square"></i>
              </button>
          </li>
          <li className="nav-item" role="presentation"
            onClick={handleReAnnotation}
          >
              <button className="nav-link" aria-selected="false">
                  <i className="bi bi-bounding-box-circles"></i>
              </button>
          </li>
          <li className="nav-item" role="presentation" onClick={handleDeleteSegment}>
              <button className="nav-link" aria-selected="false">
                  <i className="bi bi-trash3"></i>
              </button>
          </li>
    </ul>


    <Offcanvas show={show} onHide={handleClose}  placement="bottom" className=" bottom_side">
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title> 
          Segment Name
          <div className='d-flex gap-1 mt-2'>
                        <span className='switch-checkbox'>Private Gallery</span>
                        <div className="form-check form-switch ms-2">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        </div>
                        <span className='switch-checkbox'>Public Gallery</span>
                    </div>

          </Offcanvas.Title> */}
            <h4 className='model-title'>
          Segment Name: {eachSegmentData?.name || 'N/A'} - {eachSegmentData?.segName || 'N/A'}
        </h4>
        {/* {imageUrl && (
          <div className="image-container mb-4">
            <h5>Image</h5>
            <span>{imageUrl}</span>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip className='custom-tooltip' id={`tooltip-imageUrl`}>{copied.imageUrl ? 'Copied!' : 'Copy'}</Tooltip>}>
                
              <i className="bi bi-copy text-primary" onClick={handleImageCopy}></i>
             
            </OverlayTrigger>

          </div>
        )} */}
        
        </Offcanvas.Header>
        <Offcanvas.Body>
        {grpName &&
        subGroupName &&
        childName&&
        <Information 
        grpName ={grpName}
        subGroupName ={subGroupName}
        childName={childName}
          isInfo={isInfo}
          handleClose={handleClose}
        />}
        </Offcanvas.Body>
        {/* <button className='btn btn-tranparent border-primary text-primary'    onClick={handleClose}>
          Close
        </button> */}
        </Offcanvas>
       



    

    {/*  delete Api  */}
       { deleteName != null &&
        isDeleteApi &&
       jobId &&
       <DeleteSegmentApi
        segName={deleteName}
        jobId={jobId}
        resetDelete={handleResetDelete}
        />}

      
      {/*  after reAnnoatation  hit Api*/}
      {isReAnnotationApi &&
            annotation &&
            childName &&
            <AnnotationUpdateApi
            segmentationInt={annotation}
            segName={childName}
            resetAnnotation={handleResetAnotation}
            resetFailAnnotation={handleFailResetAnnotation}
            />}
</>
    
   
 

  )
}

export default Action
