import React, { useEffect, useRef } from 'react'
import { OptionJDetails } from '../../../../../Model/genAiAttributes/GenAiAttributes'
import { useDispatch } from 'react-redux'
import { updateGenAiAttributeData } from '../../../../../api/genAi/GenAiAPi'
import { updateGenAiAttribute } from '../../../../../slice/genAi/GenAiAttributesSlice'
import axios from 'axios'
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'

type Props={

    optionDetails:OptionJDetails
    resetUpdateAttri:()=>void
}
const UpdateAttriTODB = ({optionDetails,resetUpdateAttri}:Props) => {

    const dispatch= useDispatch()
    const isApi= useRef(true)

    useEffect(()=>{
      if(optionDetails && 
        isApi.current){
          isApi.current= false
          updateAtttriDB(optionDetails)   
      }
    },[optionDetails])

    const updateAtttriDB=async(optionDetails:OptionJDetails )=>{
        

        try{
            const response= await updateGenAiAttributeData(optionDetails)
               // console.log("resposn==", response)
                if(response && response. status===200){
                   
                  dispatch(updateGenAiAttribute({
                    key:optionDetails.key,
                    updateData:optionDetails
                  }))
                  isApi.current= true
                  resetUpdateAttri()
                }
            

        }catch(err){

          if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }
          isApi.current= true
                  resetUpdateAttri()
        }
    }
  return (
    <></>
  )
}

export default UpdateAttriTODB