import React, { useEffect, useRef } from 'react'
import { UpdateCommentsModel } from '../../../../Model/comments/CommentsModel'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { message } from 'antd'
import { UpdateUserReply } from '../../../../api/projectComments/ProjectCommentsApi'
import { resetReply, updateAddReply } from '../../../../slice/projectCommet/ProjectCommentSlice'
import axios from 'axios'

type ReplyProps={
    updateReply:UpdateCommentsModel
    resetReply:()=>void
}

const UpdateReplyComments = ({updateReply,resetReply}:ReplyProps) => {
    const dispatch= useDispatch()
    const isApi= useRef(true)
    useEffect(()=>{
        if(updateReply && isApi.current){
            isApi.current= false
            addReply(updateReply)
        }
    },[updateReply])
    const addReply=async(reply:UpdateCommentsModel)=>{
    try{
        const response = await UpdateUserReply(reply)
         if(response && response.status===200){
           // console.log("replyy commnets", response.data)
            dispatch(updateAddReply(response.data))
            dispatch(addMessage({
                isShow:true,
                 mess:"updated comments",
                  toastType:"success"
              }))

              isApi.current= true
              resetReply()
         }

    }catch(err){
      if(axios.isAxiosError(err)){
            
        dispatch(addMessage({
            isShow:true,
            mess: err.response?.data.message,
            toastType:"Error"
           }))
    }

          isApi.current= true
          resetReply()
    }
    }
  return (
    <></>
  )
}

export default UpdateReplyComments