import React, { useEffect, useState } from 'react'
import { GenAiAttributesModel, OptionJDetails, PromptModel } from '../../../../../Model/genAiAttributes/GenAiAttributes'
import { useDispatch } from 'react-redux'
import GetPrompt from '../../getPrompt/GetPrompt'
import { addIsOpenFeatureModal, addPrompvalue, addShowAttributeonModal } from '../../../../../slice/genAi/GenAiAttributesSlice'


type Props={
    allGenAttrFeature:GenAiAttributesModel
   
}
const GenAiFeatures = ({allGenAttrFeature}:Props) => {

    const [selectedFeaturesValues, setSelectedFeaturesValues] = useState<PromptModel>({});
    const[promptData, setPromptData]= useState<string|null>(null)
    const [genType, setGenType]= useState<string>("feature")
      const dispatch= useDispatch()
    
    const handleDropDown=(val:string, itemKey:string)=>{
        console.log("val",val)
        console.log("itemKey",itemKey)
                const title=itemKey
                if(title && val){
                    dispatch(addPrompvalue({
                        promptKey:title,
                         promptvalue : val
                    }))
                 setSelectedFeaturesValues((prev)=>({
                     ...prev,
                     [title]:val
                    }))
                }
               
             }
 
   
    // console.log("selectedFeaturesValues===",selectedFeaturesValues)
    // console.log("allGenAttrFeature===",allGenAttrFeature)
 
      const handleOpenModal=(data:OptionJDetails)=>{
        console.log("OptionJDetails valie--", data)
          dispatch(addIsOpenFeatureModal(true))
          dispatch(addShowAttributeonModal(data))
      }
  return (
    <>
    <div className='px-4'>
        <div className='customize-sec pt-3'>
            {allGenAttrFeature &&
                (Object.keys(allGenAttrFeature) as Array<keyof GenAiAttributesModel>).map((item, index) => {
                    const value = allGenAttrFeature[item] as OptionJDetails;
                     const itemKey=item as string
                    return (
                        itemKey &&
                        value.status && value.is_featured && (
                            <div key={index} className='pb-3'>
                                <h6 className='fw-medium'>
                                    {value.title}
                                    <span 
                                        // data-bs-toggle="popover" 
                                        // data-bs-trigger="hover focus" 
                                        // data-bs-content="Information about house angles."
                                        data-bs-toggle="modal" 
                                        data-bs-target="#exampleModal"
                                        
                                    >
                                        <i className="bi bi-question-circle ps-1"
                                        onClick={()=>handleOpenModal(value)}
                                        ></i>
                                    </span>
                                </h6>
                                
                                <div className="btn-group">

                                    <select 
                                    className="form-select" 
                                    aria-label="Category Select" 
                                    //value={categorySearchId ? JSON.stringify(allCatName.find(item => item.id === categorySearchId)) : ""}
                                    onChange={(e) => handleDropDown(e.target.value,itemKey)}
                                    >
                                    <option value=""> select {value.title?.toLocaleLowerCase()}</option>
                                    { value.options&&value.options.length > 0 ? (
                                    value.options.map((opt, index) => (
                                        <option key={index} value={opt}>
                                            {opt || "Untitled"}
                                        </option>
                                    ))
                                    ) : (
                                    <option value="">No feature available</option>
                                    )}
                                    </select>
                                </div>
                                {/* <div className="dropdown w-100">
                                    <button 
                                        className="btn btn-primary dropdown-toggle drop-btn rounded-1"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        style={{ width: "100%", height: "38px", textAlign: "left" }}
                                    >
                                        {selectedFeaturesValues && Object.keys(selectedFeaturesValues).map((gen, idx) => (
                                            gen === item ? (
                                                <span key={idx} className="text-start">{selectedFeaturesValues[gen]}</span>
                                            ) : null
                                        ))}
                                        <span className="dropdown-toggle-icon"></span>
                                    </button>

                                    <ul className="dropdown-menu w-100">
                                        {value.options && value.options.length > 0 && value.options.map((val, idx) => (
                                            <li key={idx}>
                                                <a 
                                                    className="dropdown-item"
                                                    onClick={() => handleDropDown(val, item)}
                                                >
                                                    {val}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                            </div>
                        )
                    );
                })
            }
        </div>

        <div className='slider-intervention pt-3'>
            <h6 className='fw-medium'>
                AI Intervention
                <span 
                    data-bs-toggle="popover" 
                    data-bs-trigger="hover focus" 
                    data-bs-content="Information about house angles."
                >
                    <i className="bi bi-question-circle ps-1"></i>
                </span>
            </h6>
        </div>
    </div>


    {/* <div className="modal fade" id="exampleModal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        ...
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}

</>

  )
}

export default GenAiFeatures