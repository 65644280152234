import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'


import SearchSection from './SearchSection'
import { getGroupName } from '../../../../../slice/toolActive/ToolActiveSlice'
import { getCategoryName } from '../../../../../slice/materialSlice/MaterialCategorySlice'
import { getAllSegment } from '../../../../../slice/segment/SegmentSlice'
import { NameModel } from '../../../../../Model/material/MaterialModel'
import GetCategoryName from '../../../../admin/AdminMaterial/category/api/GetCategoryName'

const SearchSwatchHome = () => {

  const dispatch= useDispatch()


  return (
    <>
       

       <SearchSection/>

     {/* get all category name */}
     <GetCategoryName/>
     


    </>
  )
}

export default SearchSwatchHome