import React, { useEffect, useRef } from 'react'
import { GetStartcalculateImageDepth } from '../../api/imageDepth/ImageDepthApi'
import { useDispatch, useSelector } from 'react-redux'
import { addImageDepthUrl, addIsStartImageDepth, getImageDepthData } from '../../slice/imageDepthSlice/ImageDepthSlice'
const urlpath = process.env.REACT_APP_Project_Image
const CalculateImageDepth = () => {
 const dispatch= useDispatch()
  const getImageDepthDatas= useSelector(getImageDepthData)
  const isApi= useRef(true)
  useEffect(()=>{
    if(getImageDepthDatas &&
      getImageDepthDatas.current_image_url &&
      getImageDepthDatas.projectId&&
      isApi.current &&
      urlpath
    ){
      isApi.current= false
      const imagePath= urlpath+"/"+getImageDepthDatas.projectId +"/"+getImageDepthDatas.current_image_url
      calCulateDepthApi(imagePath,getImageDepthDatas.projectId)
    }
  },[getImageDepthDatas])

  const calCulateDepthApi= async(currentImage:string, projectId:number)=>{

    try{
      const response= await GetStartcalculateImageDepth(currentImage,projectId)
      console.log("image depth response", response)
      if(response && response.status==200){
          dispatch(addImageDepthUrl(response.data[0]))
      }
       isApi.current=true     
      dispatch(addIsStartImageDepth(false))

    }catch(err){
      console.log("error on calaculating depth")
      isApi.current=true
      dispatch(addIsStartImageDepth(false))
    }
  }
    
  return (
    <></>
  )
}

export default CalculateImageDepth