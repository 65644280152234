import React, { useEffect, useRef } from 'react'
import { UpdateSortedLayer } from '../../../api/jobs/JobApi'
import { useDispatch, useSelector } from 'react-redux'
import { getMasterArray, getSortedLayer } from '../../../slice/canvas/masterArray/MasterArraySlice'
import { addMessage } from '../../../slice/messageToast/ToastSlice'
import axios from 'axios'


type Props={
    resetLayer:()=>void
}
const UpdateSegmentLayer = ({resetLayer}:Props) => {
                const dispatch= useDispatch()
                const getMasterArrays= useSelector(getMasterArray)
                 const getSortedLayers= useSelector(getSortedLayer)
                 const isApi= useRef(true)
                 useEffect(()=>{
                    if(getMasterArrays &&
                        getMasterArrays.jobId &&
                        getSortedLayers &&
                          getSortedLayers.length>0  &&
                          isApi.current
                     ){
                        isApi.current= false
                            updateLayer( getMasterArrays.jobId,getSortedLayers) 
                        }
                 },[getMasterArrays,getSortedLayers])

                const updateLayer=async(jobId:number,sortedLayer:string[])=>{
       
        try{
                const response= await UpdateSortedLayer(jobId,sortedLayer)
                  console.log("update layer", response)
                    if(response && response.status==200){
                        dispatch(addMessage({
                          isShow:true,
                          mess:`${response.data}`,
                          toastType:"success"
                        }))
                        isApi.current=true
                        resetLayer()
                    }
        }catch(err){
          if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }
            isApi.current= true
            resetLayer()
        }
    }

  return (
    <></>
  )
}

export default UpdateSegmentLayer