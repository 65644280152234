import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addUserProject, getUserProject } from '../slice/user/UserProject'
import { getUserLogin } from '../slice/user/UserSlice'
import AuthRefresh from './AuthRefresh'
import { addSegment, getAllSegment } from '../slice/segment/SegmentSlice'
import { is } from '@react-three/fiber/dist/declarations/src/core/utils'
import GetAllProject from '../module/projectPage/showProject/GetAllProject'
import { useLocation } from 'react-router-dom'

const ProjectPageRefresh = () => {
    const dispatch= useDispatch()
    const [isProject, setIsProject]= useState<boolean>(false)
   const isUserProject= useRef(true)
   const getAllSegments= useSelector(getAllSegment)
    const getUserProjects= useSelector(getUserProject) 
    const getUserLogins= useSelector(getUserLogin)
    const isprojects= useRef(true)
    const isUserLogin= useRef(true)
    const isAllSegment= useRef(true)
    const location= useLocation()
    useEffect(()=>{
        if(getUserProjects && 
            getUserProjects.length===0 &&
            location.pathname.includes("/customer/project")
        ){
            isUserProject.current=true
        
           
        }else if (getUserProjects &&
             getUserProjects.length>0 && 
             isUserProject.current ){
                isUserProject.current=false
            //localStorage.setItem("getUserProjects",JSON.stringify(getUserProjects))
        }
    },[getUserProjects,location])



     useEffect(()=>{
        if(getAllSegments && getAllSegments.length===0){
            isAllSegment.current= true
            const allSegment= localStorage.getItem("getAllSegments")
            if(allSegment){
                const allSegementdata= JSON.parse(allSegment)
                dispatch(addSegment(allSegementdata))
            }
            
        }else if(getAllSegments && getAllSegments.length>0 && isAllSegment.current){
            isAllSegment.current= false
            localStorage.setItem("getAllSegments",JSON.stringify(getAllSegments))
        }
     },[getAllSegments])

  const handleReset=()=>{
    
  }
  
  return (
    <div>

        <AuthRefresh/>

       {/* get All project from Api */}
      { isUserProject.current &&
       <GetAllProject
       resetGetAllProject={handleReset}
       />}
    </div>
  )
}

export default ProjectPageRefresh