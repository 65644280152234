import React, { useEffect, useRef, useState } from 'react'

import { JobModel, JobSegmentModel, UpdateJobModel } from '../../Model/Job/JobModel'
import { addUserJob, UpdateUserJob } from '../../api/jobs/JobApi'
import { useDispatch, useSelector } from 'react-redux'
import { getUserJobData, updateJobId } from '../../slice/userJobSlice/UserJobSlice'
import { resetModalData } from '../../slice/modelData/ModelDataSlice'
import { stopLoading } from '../../slice/loading/LoadingSlice'
import { addNewSegment } from '../../slice/segegratedSlice/SegregatedSlice'
import axios from 'axios'
import { addMessage } from '../../slice/messageToast/ToastSlice'



type Props={
    resetJob:(data:boolean)=>void,
    updateJob:UpdateJobModel
}
const UpdateJobDB = ({ resetJob,updateJob}:Props) => {
 const isApi= useRef(true)
 const isApi2= useRef(true)
 const dispatch= useDispatch()
      const getUserJobDatas= useSelector(getUserJobData)
     const[addJob, setAddJob]= useState<UpdateJobModel|null>(null)
    const[ jobId, setJobId]= useState<number|null>(null)
        useEffect(()=>{
          
            if(updateJob &&
              updateJob.id &&
              isApi.current
            ){
                setJobId(updateJob.id)
              isApi.current= false
              setAddJob(updateJob)
            }
        },[updateJob,getUserJobDatas])

        useEffect(()=>{
          if(addJob && 
            jobId&&
            addJob.image!="" && 
            isApi2.current){
            isApi2.current= false
            setAddJob(null)
            setJobId(null)
            addJobDb(addJob,jobId)
          }
        },[addJob,jobId])

    const addJobDb=async(data:UpdateJobModel, jobId:number)=>{
        const jobData={
            jobId:jobId,
            jobArray:data
        }
  
       try{
        const response= await UpdateUserJob(jobData);
      
            
          if(response  && response.status==200){
            console.log("updated job", response)
            dispatch(addNewSegment(response.data?.segments))
            setAddJob(null)
            isApi.current=true
            isApi2.current= true
            dispatch(updateJobId({
              job:response.data
            }))
            dispatch(resetModalData())
              dispatch(stopLoading())
              resetJob(true)
          }
       }
       catch(err){
        dispatch(stopLoading())
        setAddJob(null)
            isApi.current=true
            isApi2.current=true
       
            if(axios.isAxiosError(err)){
            
              dispatch(addMessage({
                  isShow:true,
                  mess: err.response?.data.message,
                  toastType:"Error"
                 }))

                
          }
        resetJob(false)
       }
    }
  return (
    <div>
        
    </div>
  )
}

export default UpdateJobDB