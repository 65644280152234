import React, { useEffect, useState } from "react";

import JobsTable from "../../brandPage/jobs/table/JobTable";
import Header from "../../header/Header";
import Sidebar from "../../dasboardpages/components/Sidebar";
import PageContentHeader from "../../dasboardpages/components/pageContent/PageContentHeader";
import SearchAdd from "../../dasboardpages/components/pageContent/SearchAdd";
import { useSelector } from "react-redux";
import { getAllJobData } from "../../../slice/Admin/JobSlice/JobSlice";
import GetAllJobs from "../../brandPage/jobs/jobapi/GetAllJobs";

const AdminJobTable = () => {

  const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>('Material Library'); 
  const [secondHeader, setSecondHeader] =useState<string>('Job') 
 
  const [breadCum1, setbreadCum1] =useState<string>('Home') 
  const [breadCum2, setbreadCum2] =useState<string>('Library') 
  const [breadCum3, setbreadCum3] =useState<string>('Job') 
  const [page, setPage] =useState<number>(1) 
  const [limit, setLimit] =useState<number>(50)  
 
  const getAllJobDatas= useSelector(getAllJobData)
  const[isJob, setIsJob]= useState<boolean>(false)
  
  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };

// get all jobs initailly
  useEffect(()=>{
    if(getAllJobDatas &&
       getAllJobDatas.length==0){
             setIsJob(true)
    } else{
      setIsJob(false)
    }
  },[getAllJobDatas])


  const handleResetAllJobs=()=>{
    setIsJob(false)
  }

  return (
    <>
    <div className="mt-5 pt-4">
      <Header/>
    <div  className={`wrapper ${isActive ? 'active' : ''}`}>
         <Sidebar toggleActiveClass={toggleActiveClass} />
        {/* content header */}
        
         <PageContentHeader
         firstHeader={firstHeader}
         secondHeader={secondHeader}
         breadCum1={breadCum1}
         breadCum2={breadCum2}
         breadCum3={breadCum3}
         
          />
        

        <SearchAdd/>

          {/* table */}
         
        <JobsTable />



         
    </div>
      
    </div>

     {/*  get all jobs  */}
    { isJob &&
    <GetAllJobs
    page={page}
    limit={limit}
    resetAllJobs={handleResetAllJobs}
    />}
    </>
    
  );
};

export default AdminJobTable;
