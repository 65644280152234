import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addIsOpenFeatureModal, getIsOpenModal, getShowAttributeModal } from '../../../../../slice/genAi/GenAiAttributesSlice'
import "./GenAiFeatureModal.scss";
import { OptionJDetails } from '../../../../../Model/genAiAttributes/GenAiAttributes';

const GenAiFeatureModal = () => {

    const [openModal, setOpenModal]= useState<boolean>(false)
    const[attribute, setAttribute]= useState<OptionJDetails|null>(null)
    const getIsOpenModals= useSelector(getIsOpenModal)
    const [activeIndex, setActiveIndex] = useState<number | null>(null); // Track the active item index

    const getShowAttributeModals= useSelector(getShowAttributeModal)
     
    //open modal
    useEffect(()=>{
        if(getIsOpenModals){
            setOpenModal(openModal)
        }else{
            setOpenModal(false)
        }
    },[getIsOpenModals])

    // update the Attribute based on click
       useEffect(()=>{
        if(getShowAttributeModals){
          setAttribute(getShowAttributeModals)
        }
       },[getShowAttributeModals])


    const dispatch= useDispatch()
    const handleClose=()=>{
          dispatch(addIsOpenFeatureModal(false))
    }


  return (
    <>
       <div className={`modal fade ${openModal ? 'show' : ''}`} id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden={!openModal}>
  <div className="modal-dialog  modal-dialog-centered design-theme-model">
    <div className="modal-content">
      <div className="modal-header border-0">
        <h5 className="modal-title fs-5" id="exampleModalLabel">Design Theme</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
          onClick={handleClose}
        ></button>
      </div>
      <div className="modal-body ">
   <div className='row g-3'>

{ attribute && 
attribute.option &&
attribute.option.length>0 &&
attribute.option.map((item, index) => {
  const isActive = activeIndex === index;
  return (
    <div className={`col-3 box-item ${isActive ? 'active' : ''}`} onClick={() => setActiveIndex(index)}>
      {item.image === "" ? (
        <>
          <img src='https://betadzinly.s3.us-east-2.amazonaws.com/projects/841/image_841_original.jpg' alt="Default" />
          <p className='text-center'>Winodw</p>
        </>
      ) : (
        <>
          <img src={item.image} alt={item.title} />
          <p className='text-center'>{item.title}</p>
          <span className="select-cehckbox">
            <i className="bi bi-check-lg"></i> 
          </span>
        </>
      )}
    </div>
  );
})}

   </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
    </>
  )
}

export default GenAiFeatureModal