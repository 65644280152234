import axios from "axios";
import { error } from "console";
import { GeneratePromptImageModel, OptionJDetails, PromptModel } from "../../Model/genAiAttributes/GenAiAttributes";
import { GenAiImageModel } from "../../Model/genAiAttributes/GenAiImages";
import apiClient from "../interceptor/ApiCilent";

 const backEndUr=process.env.REACT_APP_BACKEND_URL
const serverApi =process.env.REACT_APP_SERVER_URL


// getAll gen Ai attributes
export function GetAllGenAiAttributes(){
  return axios.get(`${serverApi}/gen-ai-attributes`) 
  .then(response => {
         
    return response
}).catch(error => {
    throw error;
});
}

// generate prompt
 export function GeneratePromptImage(selectedDropdownValues:PromptModel){

  return axios.post(`${backEndUr}/api/prompt-gen`,selectedDropdownValues)
  .then(response=>{
    return response
  }).catch(error=>{
    throw error
  })
 }


 


// based on prompt generate images
export function GetGenAiStyle(imagePath:string,prompt:string){

    const data={
        url:imagePath ,
        prompt: [prompt]
      }
    return axios.post(`${backEndUr}/genai/gen-ai-images`,data)
    .then(response => {
         
        return response
    }).catch(error => {
        throw error;
    });
}


// get all GEn Ai image
// add job into job collection
export function GetGenAi(projectId:number){
  return axios.get(`${serverApi}/get-gen-ai-image`)
  .then(response => {
     
      return response
  }).catch(error => {
      throw error;
  });
}



// get gemAi Images from database

export function GetGenAiImages(jobId:number){
  const data={
    jobId:jobId
  }

  return axios.post(`${serverApi}/get-gen-ai-images`,data)
  .then(response=>{
    return response
  }).catch(error=>{
    throw error
  })
 }

// add gemAi Images from database

export function addGenAiImages(data:GenAiImageModel){

  return axios.post(`${serverApi}/add-gen-ai-images`,data)
  .then(response=>{
    return response
  }).catch(error=>{
    throw error
  })
 }
// get gemAi Images from database

export function updateGenAiImages(data:GenAiImageModel){
  

  return axios.post(`${serverApi}/genai/gen-ai-images`,data)
  .then(response=>{
    return response
  }).catch(error=>{
    throw error
  })
 }

 // update user credits
export function creditUpdate(email:string, credit:number){

  const data= {
    email:email,
    credit:credit
    }
  return apiClient.post(`/update-credit`,data)
  .then(response=>{
    return response
  }).catch(error=>{
    throw error
  })
 }


 // update user credits

  export function uploadGenAiAttributeImages(formData:FormData){


  return apiClient.post(`/upload-gen-ai-attribute-image`,formData)
  .then(response=>{
    return response
  }).catch(error=>{
    throw error
  })
 }

 // update attribute value 
  export function updateGenAiAttributeData(optionDetails:OptionJDetails ){

    const data={
      key:optionDetails.key,
      updateData:optionDetails
    }
  return apiClient.post(`/update-gen-ai-attribute`,data)
  .then(response=>{
    return response
  }).catch(error=>{
    throw error
  })
 }