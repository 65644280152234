import { useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { updateStartUploadScrenShot } from '../../../../slice/imageDepthSlice/ImageDepthSlice'

const CalculateDepthButton = () => {
    const dispatch= useDispatch()
    
    const handleCalculateDepth=()=>{
  dispatch(updateStartUploadScrenShot(true))
    }

    
  return (
    <>
    
    <div
    //  className={activeSubmenuIndex[0] === 11 ? 'active-icon' : ''}
               onClick={ handleCalculateDepth}
                >
                 <i className="bi bi-cpu"></i>
                </div>
    </>
  )
}

export default CalculateDepthButton

function updsateStartUploadScrenShot(arg0: boolean): any {
    throw new Error('Function not implemented.')
}
