import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getTargetAddSegment } from '../../../../../slice/canvas/updatevalue/UpdateValueSlice'
import { deleteSwatch } from '../../../../../slice/segegratedSlice/SegregatedSlice'

type Props={
  swatchId:number
}
const DeleteCurrentSwatch = ({swatchId}:Props) => {
   const [groupName, setGroupName]= useState<string|null>(null)
   const [groupSubName, setGroupSubName]= useState<string|null>(null)
   const getTargetAddSegments= useSelector(getTargetAddSegment)
    const dispatch= useDispatch()
       useEffect(()=>{
        if(getTargetAddSegments &&
           getTargetAddSegments.groupName &&
           getTargetAddSegments.subGroupName
          ){
            setGroupName(getTargetAddSegments.groupName)
            setGroupSubName( getTargetAddSegments.subGroupName)
          }
       },[getTargetAddSegments])

       // delete current swatch
  const handleDeleteCurrentPallet=()=>{
      console.log("swatchId",swatchId)
      console.log("groupName",groupName)
      console.log("groupSubName ",groupSubName )
      if(swatchId ){
     
             // delete swatch from segregated 
             dispatch(deleteSwatch({
              swatchId:swatchId,
               groupName:groupName, 
               groupSubName :groupSubName
             }))
      }

  }
  return (
    <>
    <Dropdown className="pallet-information-dropdown">
                      <Dropdown.Toggle
                        // variant="light"
                        className="bg-transparent border-0 p-0 header-user d-flex align-items-center gap-1 shadow-none"
                        id="dropdown-basic">
                        <span className="pallet-infomation-icon">
                          <i className="bi bi-three-dots-vertical"></i>
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="end">
                        <Dropdown.Item className="pallet-info-list" onClick={handleDeleteCurrentPallet}> 
                            <i className="bi bi-trash3"></i>
                              Delete
                              </Dropdown.Item>
                     
                      </Dropdown.Menu>
                    </Dropdown>
    </>
  )
}

export default DeleteCurrentSwatch