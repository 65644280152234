import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { GetStyleBrandBased } from '../../../../../api/material/style/styleApi'
import axios from 'axios'
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'
import { addSearchStylesNames } from '../../../../../slice/materialSlice/MaterialStyleSlice'



type Props={
    resetSearchStyle:()=>void
    brandId:number[]
}
const SearchStyleBrandBased = ({brandId,resetSearchStyle}:Props) => {

    const dispatch=useDispatch()
    const isApi= useRef(true)
 

    useEffect(()=>{
        if(brandId && isApi.current){
            isApi.current=false
           
            getStyleBasedOnBrand(brandId)
        }
       },[])
    
       const getStyleBasedOnBrand=async(brandId:number[])=>{
        try{
                const response= await GetStyleBrandBased(brandId  )
                console.log("response", response)
                if(response  && response.status==200){
    
                    dispatch(addSearchStylesNames(response.data))
                    isApi.current=true
                    resetSearchStyle()
                }
        }catch(err){
            if(axios.isAxiosError(err)){
                
                dispatch(addMessage({
                    isShow:true,
                    mess: err.response?.data.message,
                    toastType:"Error"
                   }))
            }
    
            
            isApi.current=true
            resetSearchStyle()
        }
       }
  return (
    <></>
  )
}

export default SearchStyleBrandBased