import React, { useEffect, useRef, useState } from 'react'
import UploadScreenShortToS3 from './UploadScreenShortToS3'
import { useDispatch, useSelector } from 'react-redux'
import { getScreenShortUrl, resetScreenShotImageUrl } from '../../../../slice/imageDepthSlice/ImageDepthSlice'
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import CallApiTogetDepthImage from './CallApiTogetDepthImage'
import DownLoadRefinedScreenShot from './DownLoadRefinedScreenShot'


const urlpath = process.env.REACT_APP_Project_Image
type Props={
    screnShotImage:File
    resetScreenShot:()=>void
}
const CalculateDepthHome = ({screnShotImage,resetScreenShot}:Props) => {
    const [projectImage, setProjectImage]= useState<string|null>(null)
    const [screenShotImage, setScreenShotImage]= useState<string|null>(null)
    const [projectId, setProjectId]= useState<number|null>(null)
    const [isCalDepth, setIsCalDepth]= useState<boolean>(false)
    const [isUpload, setIsUpload]= useState<boolean>(false)
    const getScreenShortUrls= useSelector(getScreenShortUrl)
    const getMasterArrays= useSelector(getMasterArray)
    const isScreenShot= useRef(true)
    const dispatch= useDispatch()
    useEffect(()=>{
        if(screnShotImage &&
            isScreenShot.current
        ){
            setIsUpload(true)
        }
    },[screnShotImage])

    const handleResetUpload=()=>{
       // 
        setIsUpload(false)
       

    }

    useEffect(()=>{
        if(getMasterArrays &&
             getMasterArrays.project_id &&
            getMasterArrays.image &&
            getScreenShortUrls

        ){
           
            setScreenShotImage(getScreenShortUrls)
            setProjectId(getMasterArrays.project_id)
         
            setIsCalDepth(true)
        }
    },[getMasterArrays,getScreenShortUrls])
   
    const hanldeResetApi=()=>{

        setIsCalDepth(false)
        setScreenShotImage(null)
        dispatch(resetScreenShotImageUrl())
        isScreenShot.current= true
        resetScreenShot()
    }

  return (
    <>
     {isUpload &&
      screnShotImage &&
        <UploadScreenShortToS3
        screenShotImage={screnShotImage}
        resetScreenUpload={handleResetUpload}
        />}
   
       {/* call Api get screen shot image with depth */}

     { isCalDepth &&
     screenShotImage &&
     projectId &&
      <CallApiTogetDepthImage
       screenShotImage={screenShotImage}
       projectId={projectId}
       resetCallDepth={hanldeResetApi}
       />}

       <DownLoadRefinedScreenShot/>
    </>
  )
}

export default CalculateDepthHome