import React, { useEffect, useState } from "react";
import CreateProjectFrom from "../../createproject/CreateProjectFrom";

import { useNavigate } from "react-router-dom";

import Loading from "../../loading/Loading";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { getAllProjectData } from "../../../slice/Admin/projectSlice/ProjectSlice";
import { ProjectModel } from "../../../Model/project/ProjectModel";

  const urlPath= process.env.REACT_APP_Project_Image

const Projects = () => {
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [selectAll, setSelectAll] = useState(false);
  const getAllProjectDatas= useSelector(getAllProjectData)
  const[allProjects, setAllProjects]= useState<ProjectModel[]>([])

  const navigate = useNavigate();

  // update All projects 
  useEffect(()=>{
     if(getAllProjectDatas && 
      getAllProjectDatas.length>0){
     setAllProjects(getAllProjectDatas)
     }
  },[getAllProjectDatas])

  const columns = [
   
  
    {
      name: "Project Id",
     selector: (row: ProjectModel) => row.id??0,
      sortable: true,
    },
    {
      name: "Project Image",
     cell:(row:ProjectModel)=>{
     
      const imagePath = row.compressImage ?? "";
      return <img width={50} src={`${urlPath}/${row.id}/${imagePath}`} alt="Material" />;
     },
      sortable: true,
    },
    {
      name: "Project Name",
     selector: (row: ProjectModel) => row.name??"",
      sortable: true,
    },
    {
      name: "Customer Id",
     selector: (row: ProjectModel) => row.customer_id??0,
      sortable: true,
    },
    {
      name: "Slug",
     selector: (row: ProjectModel) => row.slug??"",
      sortable: true,
    },
    {
      name: "Job",
     cell:(row:ProjectModel)=>{
  
      const jobs=row.jobs as  number[]
      return jobs[0]
     },
      sortable: true,
    },
    
    {
      name: "Action",
      cell: (row: ProjectModel) => (
        <>
  <div className="d-flex action">
                  <div className="action-icon-table" 
                  //onClick={() => handleEdit(row)}
                  >
                    <i className="bi bi-pencil-square mx-0"></i>
                  </div>
                  <div className="action-icon-table">
                    <i className="bi bi-copy mx-0"></i>
                  </div>
                  <div className="action-icon-table">
                    <i className="bi bi-box-arrow-up-right mx-0"></i>
                  </div>
                  <div className="action-icon-table trash-icon-media"
                   //onClick={() => handleDelete(row)}
                   >
                    <i className="bi bi-trash3 mx-0"></i>
                  </div>
                </div>
                </>)
    },
  ];

  const handleCheckboxChange = (id: number) => {
    setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSelectAllChange = () => {
    // const newCheckedItems: { [key: number]: boolean } = {};
    // tableData.forEach((row) => {
    //   newCheckedItems[row.id] = !selectAll;
    // });
    // setCheckedItems(newCheckedItems);
    // setSelectAll(!selectAll);
  };

  const [isEditProjectFrom, setIsProEditForm] = useState(false);

  const handleProEditFrom = () => {
    navigate("/dashboard/projects/edit");
  };

  return (
    <>
      <div className="card mx-3">
        <div className="card-header bg-white p-2 d-flex justify-content-between align-items-center">
          <input
            type="search"
            className="form-control w-auto"
            placeholder="Search"
            // onSearch={handleSearch}
          />
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal">
            Create new entry
          </button>
        </div>

        <div className="card-body p-0">
          {getAllProjectDatas && getAllProjectDatas.length === 0 ? (
            <Loading />
          ) : (
           
            <DataTable 
            columns={columns} 
            data={getAllProjectDatas?.map((item) => ({
                  ...item,
                 key: item.id,
               }))}
            pagination
            fixedHeader 
          />
          )}
        </div>
        
      </div>

    </>
  );
};

export default Projects;
