import React from 'react'
import AdminStyleTable from '../../../module/admin/AdminMaterial/style/AdminStyleTable'

const AdminStyleTablePage = () => {
  return (
    <div className="mt-5 pt-4">
        <AdminStyleTable/>
        

    </div>
  )
}

export default AdminStyleTablePage