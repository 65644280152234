import React, { useEffect, useState } from 'react'
import LeftSection from '../leftSection/LeftSection';
import GetAllMaterialsHome from '../getAllMaterials/GetAllMaterialsHome';
import GetScreenResolution from '../getUserScreenResolution/GetUserScreenResolution';
import Header from '../header/Header';
import GetBrandName from './AdminMaterial/brand/api/GetBrandName';
import GetCategoryName from './AdminMaterial/category/api/GetCategoryName';
import GetStyleNameApi from './AdminMaterial/style/api/GetStyleNameApi';

const AdminHome = () => {




  return (
    <>
 
    <GetBrandName/>
<GetCategoryName/>

<GetStyleNameApi/>

    </>
   
  )
}

export default AdminHome