import { useDisclosure } from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'
import { GetBrandscategoryIdBased } from '../../../../../api/material/brand/BrandApi'
import { addSearchBrandName } from '../../../../../slice/materialSlice/MaterialBrandSlice'


type Props={
    resetSearch:()=>void
    catId:number
}
const SearchBrand = ({resetSearch,catId}:Props) => {
   const dispatch=useDispatch()
   const isApi= useRef(true)


   useEffect(()=>{
    if(catId && isApi.current){
        isApi.current=false
       
        getBrandBasedOnCat(catId)
    }
   },[])

   const getBrandBasedOnCat=async(catId:number)=>{
    try{
            const response= await GetBrandscategoryIdBased(catId)
            if(response  && response.status==200){

                dispatch(addSearchBrandName(response.data))
                isApi.current=true
                resetSearch()
            }
    }catch(err){
        if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }

        
        isApi.current=true
        resetSearch()
    }
   }


  return (
    <></>
  )
}

export default SearchBrand