import React, { useEffect, useState } from 'react'
import LoginPage from '../../../page/login/LoginPage'
import LoginPag from './LoginPage'
import { useDispatch, useSelector } from 'react-redux'
import { getUserLogin } from '../../../slice/user/UserSlice'
import { useNavigate } from 'react-router-dom'
import { userModel } from '../../../Model/user/UserModel'
import Loading from '../../loading/Loading'
import { getLoading, stopLoading } from '../../../slice/loading/LoadingSlice'
import Header from '../../header/Header'
import MessageToast from '../../messageToast/MesageToast'
const LoginHome = () => {
   const dispatch= useDispatch()
  const navigator= useNavigate()
  const getUserLogins= useSelector(getUserLogin) as userModel[]
  // console.log("getUserLogins",getUserLogins)
  useEffect(()=>{
    if(getUserLogins && getUserLogins.length>0  ){
      
      if(getUserLogins[0].role){
        dispatch(stopLoading())
        navigator(`/${getUserLogins[0].role}/project`)
      }
    }
  },[getUserLogins])
     
  const getLoadings= useSelector(getLoading)
  const [isLoading, setisLoading]= useState<boolean>(false)
  useEffect(()=>{
    console.log("getLoadings",getLoadings)
    if(getLoadings){
      setisLoading(true)
    }
  },[getLoadings])
  return (
    <>
     <Header/>
    <div className='h-100' >
    <MessageToast/>
      <LoginPag/>
      {isLoading && <Loading/>}
      
    </div>
    </>
    
  )
}

export default LoginHome