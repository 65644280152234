import React from 'react'
import GenAiAttriHome from '../../../../module/admin/adminGenAi/genAiAttribute/GenAiAttriHome'

const AdminGenAiAttributePage = () => {
  return (
    <>
    <GenAiAttriHome/>
    </>
  )
}

export default AdminGenAiAttributePage