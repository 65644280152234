import React, { useState } from "react";
import "./ZoomCanvas.scss";
import { useTheme } from "../../../../context/ThemeContext";
import { updateFillPolygon } from "../../../../slice/canvas/masterArray/CanvasControlSlice";
import { useDispatch } from "react-redux";

const ZoomCanvas = () => {
  const dispatch= useDispatch()
  const eyeIcon = document.getElementById("eyeIcon") as HTMLElement;
  const { theme, toggleTheme } = useTheme();
  if (eyeIcon) {
    // Toggle between 'bi-eye' and 'bi-eye-slash'
    if (eyeIcon.classList.contains("bi-eye")) {
      eyeIcon.classList.remove("bi-eye");
      eyeIcon.classList.add("bi-eye-slash"); // Switch to eye-slash icon
    } else {
      eyeIcon.classList.remove("bi-eye-slash");
      eyeIcon.classList.add("bi-eye"); // Switch back to eye icon
    }
  }

  const [isCardVisible, setIsCardVisible] = useState(false);
  const [isSettingVisible, setIsSettingVisible] = useState(false);
  const [isFillVisible, setIsFillVisible] = useState(true);

  // Function to toggle the visibility of the display options
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };

  const toggleSettingVisibility = () => {
    setIsSettingVisible(!isSettingVisible);
  };

  const [scaleX, setScaleX] = useState(1);
  const [skewY, setSkewY] = useState(0);
  const [skewX, setSkewX] = useState(0);
  const [scaleY, setScaleY] = useState(1);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<number>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      setter(value);
    }
  };

   const handleFill=(value:boolean)=>{
    console.log("value fiill", value)
   setIsFillVisible(value)
    dispatch(updateFillPolygon(!value))
   }
   
  return (
    <div className="position-absolute canvas-mode-section">
      <div className="d-flex ">
        <div className=" bg-white   rounded-pill zoomContainer">
          <span className="me-3">
            <i className="bi bi-dash"></i>
          </span>
          <h6>20%</h6>
          <span className="mx-3">
            <i className="bi bi-plus"></i>
          </span>
          <h6 className="zoom-reset">Reset</h6>
        </div>

        <div
          className="bg-white rounded-circle brightness"
          onClick={toggleCardVisibility}>
          <i className="bi bi-brightness-high"></i>
        </div>

        <div
          className="bg-white rounded-circle brightness"
          onClick={toggleSettingVisibility}>
          <i className="bi bi-sliders"></i>
        </div>
      </div>

      {isCardVisible && (
        <div className="card display-mode-sec">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h5 className="card-title m-0">Display Options</h5>
              <span className="header-icon">
                <i className="bi bi-arrow-counterclockwise"></i>
              </span>
            </div>

            <div className="flex flex-col gap-1 range my-2">
              <label className="form-label">Contrast</label>
              <input
                type="range"
                className="form-range"
                min="0"
                max="3"
                step="0.5"
              />
            </div>

            <div className="flex flex-col gap-1 range mb-2">
              <label className="form-label">Brightness</label>
              <input
                type="range"
                className="form-range"
                min="0"
                max="3"
                step="0.5"
              />
            </div>

            <div className="flex flex-col gap-1 range mb-4">
              <label className="form-label">Shade</label>
              <input
                type="range"
                className="form-range"
                min="0"
                max="3"
                step="0.5"
              />
            </div>

            <div className="d-flex align-items-center justify-content-between my-3">
              <span>Always Show Labels</span>
              <label className="showLabels relative border rounded-pill">
                <input type="checkbox" className="peer sr-only" />
                <div className="optionContainer"></div>
                <div className="option off text-primary">
                  <i className="bi bi-eye"></i>
                </div>
                <div className="option on text-primary">
                  <i className="bi bi-eye-slash"></i>
                </div>
              </label>
            </div>
            <div className="d-flex align-items-center justify-content-between my-3">
              <span>Show Fill</span>
              <label className="showLabels relative border rounded-pill">
                <input 
                  type="checkbox" 
                  className="peer sr-only" 
                  checked={isFillVisible}
                  onChange={(e) => handleFill(e.target.checked)} 
                />
                <div className="optionContainer"></div>
                <div className= {`option ${isFillVisible?'off':'on'} text-primary`}>
                  <i className="bi bi-eye"></i>
                </div>
                <div className={`option ${isFillVisible?'on':'off'} text-primary`}>
                  <i className="bi bi-eye-slash"></i>
                </div>
              </label>
            </div>

            <div className="d-flex align-items-center justify-content-between ">
              <span>Display mode</span>
              <label className="showLabels relative border rounded-pill">
                <input type="checkbox" className="peer sr-only"  
                onChange={toggleTheme}
                />
                <div className="optionContainer"></div>
                <div className="option off text-primary">
                  <i className="bi bi-brightness-high"></i>
                </div>
                <div className="option on text-primary">
                  <i className="bi bi-moon"></i>
                </div>
              </label>
            </div>
          </div>
        </div>
      )}


{isSettingVisible && (
        <div className="card display-mode-sec setting-pop">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h5 className="card-title m-0">SVG Setting Options</h5>
              <span className="header-icon">
                <i className="bi bi-arrow-counterclockwise"></i>
              </span>
              <span className="header-icon">
                <i className="bi bi-play-fill"></i>
              </span>
            </div>

            <div className="flex flex-col gap-1 range my-4">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label">Scale X-axis</label>
                <input
                  type="number"
                  value={scaleX}
                  onChange={handleInputChange(setScaleX)}
                  className="form-control "
                  step="0.1"
                  min="0.5"
                  max="3"
                />
              </div>
              <input
                type="range"
                className="form-range"
                min="0.5"
                max="3"
                step="0.1"
                value={scaleX}
                onChange={handleInputChange(setScaleX)}
              />
            </div>

            <div className="flex flex-col gap-1 range mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label">Skew Y-axis</label>
                <input
                  type="number"
                  value={skewY}
                  onChange={handleInputChange(setSkewY)}
                  className="form-control "
                  step="0.1"
                  min="-1"
                  max="1"
                />
              </div>
              <input
                type="range"
                className="form-range"
                min="-1"
                max="1"
                step="0.1"
                value={skewY}
                onChange={handleInputChange(setSkewY)}
              />
            </div>

            <div className="flex flex-col gap-1 range mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label">Skew X-axis</label>
                <input
                  type="number"
                  value={skewX}
                  onChange={handleInputChange(setSkewX)}
                  className="form-control "
                  step="0.1"
                  min="-1"
                  max="1"
                />
              </div>
              <input
                type="range"
                className="form-range"
                min="-1"
                max="1"
                step="0.1"
                value={skewX}
                onChange={handleInputChange(setSkewX)}
              />
            </div>

            <div className="flex flex-col gap-1 range mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label">Scale Y-axis</label>
                <input
                  type="number"
                  value={scaleY}
                  onChange={handleInputChange(setScaleY)}
                  className="form-control "
                  step="0.1"
                  min="0.5"
                  max="3"
                />
              </div>
              <input
                type="range"
                className="form-range"
                min="0.5"
                max="3"
                step="0.1"
                value={scaleY}
                onChange={handleInputChange(setScaleY)}
              />
            </div>

            <div className="flex flex-col gap-1 range mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label">Translate X</label>
                <input
                  type="number"
                  value={translateX}
                  onChange={handleInputChange(setTranslateX)}
                  className="form-control "
                  step="10"
                  min="-300"
                  max="300"
                />
              </div>
              <input
                type="range"
                className="form-range"
                min="-300"
                max="300"
                step="10"
                value={translateX}
                onChange={handleInputChange(setTranslateX)}
              />
            </div>

            <div className="flex flex-col gap-1 range mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <label className="form-label">Translate Y</label>
                <input
                  type="number"
                  value={translateY}
                  onChange={handleInputChange(setTranslateY)}
                  className="form-control "
                  step="10"
                  min="-300"
                  max="300"
                />
              </div>
              <input
                type="range"
                className="form-range"
                min="-300"
                max="300"
                step="10"
                value={translateY}
                onChange={handleInputChange(setTranslateY)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ZoomCanvas;
