import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addBrandName, getAllBrandName } from '../../../../../slice/materialSlice/MaterialBrandSlice';
import { GetBrandNames } from '../../../../../api/material/brand/BrandApi';


const GetBrandName = () => {
  const dispatch= useDispatch()
   const isApi= useRef(true)
  const getAllBrandNames= useSelector(getAllBrandName)
   useEffect(()=>{
    if(getAllBrandNames &&
        getAllBrandNames.length==0 &&
        isApi.current
    ){
        isApi.current= false
        getBrandName()
    }else if(getAllBrandNames &&
        getAllBrandNames.length==0){
        isApi.current= true

    }
   },[getAllBrandNames])

    const getBrandName= async()=>{
        try{
            const response= await GetBrandNames();
            
            console.log("get all brand Name", response)
            if(response && response.status==200 ){
                  isApi.current= true
                  
                  dispatch(addBrandName(response.data))
            }

        }catch(err){
            isApi.current= true
        }
    }
  return (
    <></>
  )
}

export default GetBrandName