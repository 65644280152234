import React, { useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addMaterialStyle, getMaterialStyle, getIsDownloadStyle, getSearchStyle, addIsDownloadStyle, addStyleResponse, addSearchStyle, addStyleDoc } from '../../../slice/materialSlice/MaterialStyleSlice'
import { GetAllMaterialsStyle } from '../../../api/material/style/styleApi'
import { addMessage } from '../../../slice/messageToast/ToastSlice'
import axios from 'axios'

const GetAllMaetrial_brand_style = () => {

  const dispatch= useDispatch()
  const getMaterialStyles= useSelector(getMaterialStyle)
  const getIsDownloadStyles= useSelector(getIsDownloadStyle)
  const getSearchStyles= useSelector(getSearchStyle)
  //  useEffect(()=>{
  //    if(getMaterialStyles &&
  //      getMaterialStyles.length===0){
  //     getAllMaterials_Brand_style()
  //    }
      
  //  },[getMaterialStyles])

  const isApi= useRef(true)
   useEffect(()=>{
     if(getIsDownloadStyles &&
      getSearchStyles && 
      getSearchStyles.page &&
      getSearchStyles.pageSize &&
      isApi.current){
        isApi.current= false
        dispatch(addIsDownloadStyle(false))
      getAllMaterials_Brand_style(getSearchStyles.page,getSearchStyles.pageSize)
     }
      
   },[getIsDownloadStyles,getSearchStyles])

    const getAllMaterials_Brand_style= async(page:number, pageSize:number)=>{
        try{
     
             const response= await GetAllMaterialsStyle(page, pageSize)
                if(response && 
                  response.status===200 && 
                  response.data
                ){
                  dispatch(addMaterialStyle(response.data.data))
                  dispatch(addStyleResponse("successfully get style"))
                  const docvalue={
                    totalDocuments: response?.data.totalDocuments,
                      totalPages: response?.data.totalPages,
                      currentPage: response?.data.currentPage,
                      pageSize:response?.data.pageSize
                  }
                  dispatch(addStyleDoc(docvalue))
                  isApi.current= true
                }
        }catch(err){
          isApi.current=true
          if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }
          
          
        }

    }
  return (
    <div>

    </div>
  )
}

export default GetAllMaetrial_brand_style