import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface ToastStates {
    isShow:boolean,
    message: string | null;
    toastType?:string | null;
    apiMessage?:string | null;
     status?:string
   
    
}

const initialState: ToastStates = {
    isShow:false,
    message: null,
    apiMessage:null,
    toastType:"",
    status:""
};

const ToastSlice = createSlice({
    name: "toastSlice",
    initialState,
    reducers: {
        restToast:(state)=>{
            return initialState
        },
       addMessage:(state,action)=>{
        const{isShow, mess,toastType}=action.payload
        state.isShow=isShow
        state.message= mess
        state.toastType=toastType
       },
       resetMessage:(state)=>{
        state.isShow= false
        state.message=null
        state.toastType=""
       },
        addApiMessage:(state,action)=>{
        state.apiMessage=action.payload
       },
       resetApiMessage:(state)=>{
        state.apiMessage=null
       }

    }
}
)

export const {addMessage,resetMessage,
    addApiMessage,resetApiMessage
}=ToastSlice.actions;
export const getToast=  (state:{toastSlice:ToastStates})=>state.toastSlice
export const getApiMessage=  (state:{toastSlice:ToastStates})=>state.toastSlice.apiMessage  
export default ToastSlice.reducer