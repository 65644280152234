import React, { useEffect, useRef } from 'react'
import { SearchSwatchModel } from '../../../../Model/material/MaterialModel'
import { useDispatch } from 'react-redux'
import { SearchSwatches } from '../../../../api/material/swatch/SwatchApi'
import { addSearchSwatchData } from '../../../../slice/materialSlice/MaterialSlice'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import axios from 'axios'
import { stopLoading } from '../../../../slice/loading/LoadingSlice'


type Props={
    searchSwatch:SearchSwatchModel
    resetSearchSwatch:()=>void
}
const CallSearchApi = ({searchSwatch,resetSearchSwatch}:Props) => {

    const dispatch= useDispatch()
    const isApi= useRef(true)

    
    useEffect(()=>{
  if(searchSwatch && isApi.current){
    isApi.current= false
    getSearchSwatch(searchSwatch)
  }
    },[searchSwatch])

    const getSearchSwatch= async(searchSwatch:SearchSwatchModel)=>{
   try{
    const response= await SearchSwatches(searchSwatch)
    if(response && response. status==200){

        dispatch(addSearchSwatchData(response.data.data))
        isApi.current= true
        dispatch(stopLoading())
        resetSearchSwatch()
    }
   } catch(err){
       

        if(axios.isAxiosError(err)){
            
          dispatch(addMessage({
              isShow:true,
              mess: err.response?.data.message,
              toastType:"Error"
             }))
      }
        isApi.current= true
        resetSearchSwatch()
        
       
   }
           
    }
  return (
    <></>
  )
}

export default CallSearchApi