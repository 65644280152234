import React, { useEffect, useRef } from 'react'
import { GetGenAiImages } from '../../../../api/genAi/GenAiAPi'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectGenAiImage, updateGenAiGallery } from '../../../../slice/genAi/GenAiAttributesSlice'


 type Props={
    resetGenAiImages:()=>void
 }

const GetGenAiImageFromDb = ({resetGenAiImages}:Props) => {
      
    const dispatch= useDispatch()
    const isApi=useRef(true)
    const getProjectGenAiImages= useSelector(getProjectGenAiImage )
    useEffect(()=>{
        if(getProjectGenAiImages && 
            getProjectGenAiImages.job_id &&
            isApi.current){
                isApi.current= false
                getGeAiImageFromDb(getProjectGenAiImages.job_id )
            }

    },[getProjectGenAiImages])


    const getGeAiImageFromDb= async(jobId:number)=>{

        try{
            const response= await GetGenAiImages(jobId);
           // console.log("responbs get image genA", response)
            if(response && response.status==200){
               
              
                const gallery=response.data[0].gallery
                dispatch(updateGenAiGallery({
                    gallery:gallery
                }))
                isApi.current= true
                resetGenAiImages()
            }

        }catch(err){
            isApi.current= true
            resetGenAiImages()

        }
    }
  return (
    <></>
  )
}

export default GetGenAiImageFromDb