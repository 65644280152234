import React, { useState } from 'react'
import Sidebar from './components/Sidebar';
import PageContent from './components/PageContent';
import "./dasboard_layout.scss"
import Header from '../header/Header';
import AdminHome from '../admin/HomeAdmin';

const DasboardPages = () => {
    const [isActive, setIsActive] = useState(false); // State to track whether the div is active

    const toggleActiveClass = () => {
      setIsActive(!isActive); // Toggle the isActive state
    };
  return (
    <>
    <div className="mt-5 pt-4">
    <Header/>
    <div  className={`wrapper ${isActive ? 'active' : ''}`}>
         <Sidebar toggleActiveClass={toggleActiveClass} />
         <PageContent/>
         
    </div>
    </div>

    {/* get all category , brand , style name */}
    <AdminHome/>  
    </>
    
  )
}

export default DasboardPages
