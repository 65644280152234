import React from 'react'
import AdminBrandTable from '../../../module/admin/AdminMaterial/brand/AdminBrandTable'
import Header from '../../../module/header/Header'

const AdminBrandTablePage = () => {
  return (

    <div className="mt-5 pt-4">
   <AdminBrandTable/>
  
   </div>
   
  )
}

export default AdminBrandTablePage