
import React, { useEffect, useState, useRef } from 'react'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Checkbox, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react'
import Unitque from './Unitque'
import Action from './action/Action'
import "./EachSeg.scss";
import PalletInformation from './PalletInformation';
import { GroupMasterSegment, JobSegmentationModel, MasterArrayModel } from '../../../../Model/masterArray/MasterArrayModel';
import { useDispatch, useSelector } from 'react-redux';
import { addGroupOnSegmentation, getMasterArray, startSegHoverEffect, stopSegHoverEffect } from '../../../../slice/canvas/masterArray/MasterArraySlice';


import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import 'swiper/swiper-bundle.css';

import '../LeftSections.scss';
import SwatchImages from '../selectSwatch/SwatchImages';
import { SwatchesModel } from '../../../../Model/material/MaterialModel';
import { useSelect } from '@react-three/drei';
import { getAllSwatches } from '../../../../slice/materialSlice/MaterialSlice';
import { addAllSwatchImages, addSelectedSegment } from '../../../../slice/userJobSlice/UserJobSlice';
import GroupSegment from './GroupSegment';
import { addGroup, addSegment, updateChildAddSegment } from '../../../../slice/canvas/groupSlice.tsx/GroupSlice';
import { getCanvasControl, getIsSelectedCanvas } from '../../../../slice/canvas/masterArray/CanvasControlSlice';
import CurrentSelectedSwatch from '../currentSelectSwatch/CurrentSelectedSwatch';
import ReactDOMServer from 'react-dom/server';
import { changeSegmentTab } from '../../../../slice/tabControl/TabControlSlice';
import { addSubGroupName } from '../../../../slice/toolActive/ToolActiveSlice';
import { getTargetAddSegment, updateTargetAddSegment } from '../../../../slice/canvas/updatevalue/UpdateValueSlice';
// import SegPopover from './SegPopover';



type Props = {
  eachSegment: MasterArrayModel
  masterSegName: string   // groupName
}
const EachSeg = ({ eachSegment, masterSegName }: Props) => {
  const [seg, setSeg] = useState<GroupMasterSegment[]>([])
  const dispatch = useDispatch()

  const [masterSegNames, setMasterSegName] = useState<string | null>(null)
  const getAllSwatchess = useSelector(getAllSwatches)
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const swiperRef = useRef<SwiperClass | null>(null);
  const [eachSeg, setEachSeg] = useState<string | null>(null)
  const getMasterArrays = useSelector(getMasterArray);
  
  const getTargetAddSegments = useSelector(getTargetAddSegment);
  const[addTargertGroupName, setAddTargetGroupName]= useState<string|null>(null)
  const[addTargertSubGroupName, setAddTargetSubGroupName]= useState<string|null>(null)
  const getCanvasControls = useSelector(getCanvasControl)
  const getIsSelectedCanvass = useSelector(getIsSelectedCanvas)

  // update traget Add Segment groupNAme, subGroupNAme
  useEffect(()=>{
    if(getTargetAddSegments && 
      getTargetAddSegments.groupName &&
      getTargetAddSegments.subGroupName){
        setAddTargetGroupName(getTargetAddSegments.groupName)
        setAddTargetSubGroupName(getTargetAddSegments.subGroupName)
      }
  },[getTargetAddSegments])

  useEffect(() => {

    if (eachSegment && eachSegment.segmentation &&
      eachSegment.segmentation.length > 0 &&
      eachSegment.name) {

      setMasterSegName(eachSegment.name)
      const allSeg: GroupMasterSegment[] = []
      eachSegment.segmentation.map(item => {
        Object.keys(item).map(key => {
          allSeg.push({
            [key]: item[key],
            
          })
        })
      })
      if (allSeg.length > 0) {
        setSeg(allSeg)
      }
    }
  }, [eachSegment])

    

  // show first swatch
  const isApiFirst = useRef(true)
  useEffect(() => {
    if (seg &&
      seg.length > 0 &&
      isApiFirst.current) {
      isApiFirst.current = false
      const firstObject = seg[0];
      const firstKey = Object.keys(firstObject)[0];
      const firstValue = firstObject[firstKey];
      setEachSeg(firstKey)
    console.log("firstValue",firstValue)
      dispatch(addSelectedSegment(firstValue))

    }
   
  }, [seg])


  useEffect(()=>{
     if (getIsSelectedCanvass &&
      seg.length>0 &&
      
      getCanvasControls.subGroupName &&
      seg && seg.length > 0) {
      const selectedSubgroupIndex = seg.findIndex((item, index) =>
        Object.keys(item).map(grp => {
          if (grp === getCanvasControls.subGroupName) {
            return index
          }
        })
      )

      if (selectedSubgroupIndex != -1) {

        setEachSeg(getCanvasControls.subGroupName)
        const chidvalue = seg[selectedSubgroupIndex][getCanvasControls.subGroupName]
        console.log("childValue-----",chidvalue)
        //dispatch(addSelectedSegment(chidvalue))
      }
    }
  },[getIsSelectedCanvass,getCanvasControls,seg])

  
  // on Hover segment
  const handleHoverSegment = (data: JobSegmentationModel[]) => {

    if (seg) {

      dispatch(startSegHoverEffect({
        seg: data
      }))


    }

  }
  const handleOffHoverSegment = () => {

    dispatch(stopSegHoverEffect())
  }




  const handleTabClick = (index: number) => {
    setActiveTab(index);
    if (swiperRef.current) {
      swiperRef.current?.slideTo(index);
    }
  };

  const handleEachSegment = (eachKey: string, index: number, childValue: JobSegmentationModel[]) => {
      console.log("eachKey",eachKey)
    setEachSeg(eachKey)
    dispatch(addSelectedSegment(childValue))
    dispatch(addSubGroupName(eachKey))
    dispatch(updateTargetAddSegment({
      subGroupName:eachKey
    }))

    setActiveIndex(index)


  }

  // handle add segment
  const handleAddSegment = () => {

    dispatch(addSegment({
      isOpenModel: true,
      groupName: eachSegment.name
    }))
  }


  const [isOpen, setIsOpen] = useState(false); // State to manage visibility

  const handleAddSeg = () => {
    setIsOpen(prev => !prev); // Toggle the state
  };

  
    const handleAddGroup=()=>{
      console.log("eachSegment",eachSegment)
       const name=eachSegment.name
       const newGroupName=eachSegment.segmentation
       if(name && newGroupName){
            dispatch(updateChildAddSegment({
              groupName:addTargertGroupName,
              childGroupName:addTargertSubGroupName
            }))
           dispatch(changeSegmentTab("newSegment"))
     
    }
  }


  return (
    <>

      <div className='segment-tab-menu '>
        <div className='seg-tab-menu-list border-top'>
          <Swiper
            spaceBetween={0}
            slidesPerView={'auto'}
            freeMode={true}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {seg && seg.length > 0 && seg.map((grpSeg, index) => (
              Object.keys(grpSeg).map((item) => {
                const segment = item; // Get the segment data
                const childValue = grpSeg[item]
                return (
                  <SwiperSlide className='tab-btns'
                    style={{
                      width: 'auto',
                      backgroundColor: eachSeg === item ? 'lightblue' : 'white'  // Compare with the key `item`
                    }}
                    key={item}
                    onMouseEnter={() => handleHoverSegment(childValue)}
                    onMouseLeave={handleOffHoverSegment}
                    onClick={() => handleEachSegment(item, index, childValue)}
                  >
                    {segment}
                  </SwiperSlide>
                );
              })
            ))}
          </Swiper>

 <span className='add-seg-icons mx-2'
        onClick={handleAddGroup}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-houses" viewBox="0 0 16 16">
            <path d="M5.793 1a1 1 0 0 1 1.414 0l.647.646a.5.5 0 1 1-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 0 0 .5.5.5.5 0 0 1 0 1A1.5 1.5 0 0 1 1 12.5V7.207l-.146.147a.5.5 0 0 1-.708-.708zm3 1a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708zm.707.707L5 7.207V13.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7.207z" />
          </svg>
        </span>
       
          
        </div>

      </div>
    


      {seg &&
        seg.length > 0 ? (
        seg.map(childgrp => {
          return (
            Object.keys(childgrp).map(item => {

              if (eachSeg === item) {
                const childValue = childgrp[item]
                return (
                  <div className='m-0'>

                    <GroupSegment
                      allSegDetail={childValue}
                      groupName={eachSeg}
                    />



                  </div>
                )
              }

            })
          )
        })
      ) : (<>
        No segment
      </>)



      }



      <div className='segmentview-details'>
        

        <CurrentSelectedSwatch
          groupName={masterSegName}
        />



     

        {/* <div className="tab-content">
          <div className="tab-pane active" id={`${eachSeg}01`} role="tabpanel" aria-labelledby={`${eachSeg}01`}>
            <div className="tabs-navigationdd">
              <button
                className={activeTab === 0 ? 'active' : ''}
                onClick={() => handleTabClick(0)}
              >
                Brand
              </button>
              <button
                className={activeTab === 1 ? 'active' : ''}
                onClick={() => handleTabClick(1)}
              >
                Style
              </button>
              <button
                className={activeTab === 2 ? 'active' : ''}
                onClick={() => handleTabClick(2)}
              >
                Tab 3
              </button>
            </div>

            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              spaceBetween={50}
              slidesPerView={1}
              initialSlide={activeTab}
              onSlideChange={(swiper) => setActiveTab(swiper.activeIndex)}
            >
              <SwiperSlide>
                <div className='p-3 swichpallets-box'>
                  <div className='row g-3'>
                    {/* <SwatchImages
                   
                    /> */}
                  {/* </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>dsfdsf</SwiperSlide>
              <SwiperSlide>hhhhh</SwiperSlide>
            </Swiper>
          
          </div>
          
          <div className="tab-pane" id={`${eachSeg}02`} role="tabpanel" aria-labelledby={`${eachSeg}02`}>mmm</div>
          <div className="tab-pane" id={`${eachSeg}03`} role="tabpanel" aria-labelledby={`${eachSeg}03`}>hhh</div>
          <div className="tab-pane" id={`${eachSeg}04`} role="tabpanel" aria-labelledby={`${eachSeg}04`}>kkk</div>
          <div className="tab-pane" id={`${eachSeg}05`} role="tabpanel" aria-labelledby={`${eachSeg}05`}>kkk</div>
        </div> */} 
      </div>

{/* 
      <div className="eachSeg-header">
          <div className=' swichpallets-boxs'>

              <ul className='list-style-none d-flex gap-2 justify-content-between align-items-center'>
                <li className={activeTab === 0 ? 'active' : ''} >Brand</li>
                <li  className={activeTab === 1 ? 'active' : ''}>Style</li>
                <li className={activeTab === 2 ? 'active' : ''}>Tab 3</li>
              </ul>
          </div>
          

         
          <div className='p-3 swichpallets-box'>
                  <div className='row g-3'>
                    <SwatchImages
                   
                    />
                  </div>
          </div>
       

        </div> */}

      
    </>

  )
}

export default EachSeg


