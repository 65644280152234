import React, { useEffect, useRef, useState } from 'react';

import { MaterialStyleModel, SwatchStyleModel } from '../../../Model/material/MaterialStyleModel';
import { useDispatch, useSelector } from 'react-redux';
import { getMaterialStyle, getSwatchStyle } from '../../../slice/materialSlice/MaterialStyleSlice';
import Loading from '../../loading/Loading';
import GetSwatchBrand from '../../getAllMaterials/brand/GetSwatchBrand';
import { MaterialBrandModel, SwatchBrandModel } from '../../../Model/material/MaterialBrandModel';
import { addEditBrand, addIsDownloadBrand, addSearchBrand, getBrandDoc, getIsDownloadBrand, getMaterialBrand, getSearchBrand, getSwatchBrand } from '../../../slice/materialSlice/MaterialBrandSlice';
import { Breadcrumb, Card, Input, InputRef, Table, TableColumnsType } from 'antd';
import CreateProjectModel from '../CreateProjectModel';
import { useNavigate } from 'react-router-dom';
import Search from 'antd/es/transfer/search';
import { startLoading, stopLoading } from '../../../slice/loading/LoadingSlice';
import DelteBrandApi from '../../admin/AdminMaterial/brand/api/DeleteBrandApi';
import CreateBrandModel from './AddBrand';
import AddBrandApi from '../../admin/AdminMaterial/brand/api/AddBrandApi';
import DataTable from 'react-data-table-component';

const   SwatchBrandTable = () => {
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});
  const [selectAll, setSelectAll] = useState(false);
  const [allBrand, setAllBrand] = useState<MaterialBrandModel[]>([]);
  const navigate = useNavigate()
  const dispatch= useDispatch()
  const searchInput = useRef<InputRef>(null);
  const searchInputTitle = useRef(null);
  const getSwatchBrands = useSelector(getSwatchBrand)
  const getMaterialBrands = useSelector(getMaterialBrand)
  const getBrandDocs = useSelector(getBrandDoc)
  const getSearchBrands = useSelector(getSearchBrand)
  const[currentPages, setCurrentPages]= useState<number|null>()
  console.log("sewatch brand")
  useEffect(() => {
    if (getMaterialBrands && getMaterialBrands.length > 0) {
      setAllBrand(getMaterialBrands);
    } else if (getMaterialBrands && getMaterialBrands.length == 0) {

    }
  }, [getMaterialBrands]);

  // update the current page brand Data
  useEffect(()=>{
    if(getBrandDocs && 
      getBrandDocs.currentPage){
      setCurrentPages(getBrandDocs.currentPage)
    }
  },[getBrandDocs])

  console.log("currentPage",currentPages)

  const handleEdit = (record: MaterialBrandModel) => {
     dispatch(addEditBrand(record))
    navigate(`/dashboard/swatch-brand/${record.id}/edit`)
  }

     const [isDelete, setIsDelete]= useState<boolean>(false)
     const[ deleteId, setDeleteId]= useState<number|undefined>(0)
    const handleDelete=(record:MaterialBrandModel)=>{
      setDeleteId(record?.id)
      setIsDelete(true)
      dispatch(startLoading())
    }



 
// delete barnd

const columns = [
  {
    name: "Id",
     selector: (row: MaterialBrandModel) => row.id??0, // Selector returns a primitive value
    sortable: true,
  },
  {
    name: "Title",
     selector: (row: MaterialBrandModel) => row.title??"",
    sortable: true,
  },
  {
    name: "Category",
     selector: (row: MaterialBrandModel) => row.material_category?.title??"",
    sortable: true,
  },
  {
    name: "Description",
     selector: (row: MaterialBrandModel) => row.description??"",
  },
  {
    name: "Slug",
    selector: (row: MaterialBrandModel) => row.slug??"",
  },
  // {
  //   name: "Sort Order",
  //   selector: (row: MaterialBrandModel) => row.??"",
  // },
  {
    name: "Status",
    selector: (row: MaterialBrandModel) => row.status??"",
  },
  {
    name: "Action",
    cell: (row: MaterialBrandModel) => (
      <>
<div className="d-flex action">
                <div className="action-icon-table" 
                onClick={() => handleEdit(row)}
                >
                  <i className="bi bi-pencil-square mx-0"></i>
                </div>
                <div className="action-icon-table">
                  <i className="bi bi-copy mx-0"></i>
                </div>
                <div className="action-icon-table">
                  <i className="bi bi-box-arrow-up-right mx-0"></i>
                </div>
                <div className="action-icon-table trash-icon-media"
                 onClick={() => handleDelete(row)}
                 >
                  <i className="bi bi-trash3 mx-0"></i>
                </div>
              </div>
              </>)
    }, 
];

const handleResetDelete=(mess:string)=>{
       if(mess==="success"){
        setIsDelete(false)
        setDeleteId(0)
        dispatch(stopLoading())
        alert("Selected brand is deleted successfully")
       } else if (mess==="fail"){
        setIsDelete(false)
        setDeleteId(0)
        dispatch(stopLoading())
        alert("Error in  deleted brand")

       }

}

// add new Brand

const [ isAddApi, setIsAddApi]= useState<boolean>(false)
const [ newBrand, setNewBrand]= useState<MaterialBrandModel |null>()
const handleNewBrand=(data:MaterialBrandModel)=>{
   setIsAddApi(true)
   setNewBrand(data)
   dispatch(startLoading())
}

    const handleResetNewBrand=(mess:string)=>{
     if(mess==="success"){
      setIsAddApi(false)
      setNewBrand(null)
        dispatch(stopLoading())
        alert("Brand added successfully")
     }  else if (mess=="fail"){
      setIsAddApi(false)
      setNewBrand(null)
        dispatch(stopLoading())
        alert("Error in adding brand")
     }

    }

    const handleChangePage=()=>{
      if( currentPages!=null){
        const page=currentPages+1
        const pageSize=200
        dispatch(addSearchBrand({
          page:page,
          pageSize:pageSize
        }))
        setCurrentPages(page)
        dispatch(addIsDownloadBrand(true))
      }
      
    }
    const handleChangePrevPage=()=>{
      if( currentPages!=null){
        const page=currentPages-1
        const pageSize=200
        setCurrentPages(page)
        dispatch(addSearchBrand({
          page:page,
          pageSize:pageSize
        }))
        dispatch(addIsDownloadBrand(true))
      }
      
    }

    const handlePageChange=(page:number, pageSize:number)=>{
      setCurrentPages(page)
    }
  return (
    <>



<div className='card mx-3'>
        <div className='card-header bg-white p-2 d-flex justify-content-between align-items-center'>
          <input
            type="search"
            className="form-control w-auto"
            placeholder="Search"
          />
          <button type='button' className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#exampleModal">Create new entry</button>
        </div>

  <div className='card-body p-0'>

  
      

        {allBrand &&
          allBrand.length == 0 ? (
          <Loading />
        ) :
          (
<DataTable 
            columns={columns} 
            data={allBrand?.map((item) => ({ ...item, key: item.id }))}

            pagination
            fixedHeader
          />
)}
          

   
      


      {/*   delete brand )} */}
      {isDelete &&
       deleteId!=undefined &&
      <DelteBrandApi
      deleteId={deleteId}
      resetDelete={handleResetDelete}
      />
      }


      {/*  add new brand */}

      {isAddApi &&
      newBrand!=null &&
      <AddBrandApi
      resetAdd={handleResetNewBrand}
      addBrandData={newBrand}
      />
      }
      </div>
      </div>
    </>
  );
};

export default SwatchBrandTable;


