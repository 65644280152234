import React, { useEffect } from 'react'
import { GetAllGenAiAttributes } from '../../../../api/genAi/GenAiAPi'
import { useDispatch } from 'react-redux'
import { addGenAiAttributes } from '../../../../slice/genAi/GenAiAttributesSlice'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import axios from 'axios'

const GetAlgeniAttributes = () => {
   const dispatch= useDispatch()
   useEffect(()=>{
    getGenAiAttributes()
   },[])
    const getGenAiAttributes=async()=>{
        try{
         const response= await GetAllGenAiAttributes()
        // console.log("genAi attributes",response)
         if(response && response.status==200){
             dispatch(addGenAiAttributes(response.data))
         }
        }catch (err){
               
          if(axios.isAxiosError(err)){
            
            dispatch(addMessage({
                isShow:true,
                mess: err.response?.data.message,
                toastType:"Error"
               }))
        }
        }
    }
  return (
    <div>
        
    </div>
  )
}

export default GetAlgeniAttributes