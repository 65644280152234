
import axios from "axios";
import { MaterialCategoryModel } from "../../../Model/material/MaterialCategoryModel";
import { MaterialBrandModel } from "../../../Model/material/MaterialBrandModel";
import apiClient from "../../interceptor/ApiCilent";

// const serverApi = import.meta.env.SERVER_URL
const serverApi =process.env.REACT_APP_SERVER_URL

const databaserServer="http://localhost:5000"

// getAll material brands
export function GetAllMaterialBrands(page:number,pageSize:number) {
    const data={
        page:page,
        pageSize:pageSize
    }
    
    return axios.post(`${serverApi}/material-library/brands`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}


// update 
export function UpdateBrand(data:MaterialBrandModel){
    return axios.post(`${serverApi}/material-library/brand/update`,data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// add
export function AddBrand(data:MaterialBrandModel){
    return axios.post(`${serverApi}/material-library/brand/add`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// delete
export function DeleteBrand(id:number){
    const data={
        id:id
    }
    return axios.post(`${serverApi}/material-library/brand/delete`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}
// get Brand Name
export function GetBrandNames(){
    
    return axios.get(`${serverApi}/material/brands`)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}
export function GetBrandscategoryIdBased(catId:number){
const   data={catId:catId}
    
    return apiClient.post(`/search/brands`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}
