import React, { useEffect, useState } from "react";
import "./ProjectDetails.scss";
import { Offcanvas } from "react-bootstrap";
import { useSelect } from "@react-three/drei";
import { useSelector } from "react-redux";
import { getUserProject } from "../../../../slice/user/UserProject";
import { getMasterArray } from "../../../../slice/canvas/masterArray/MasterArraySlice";
import { TimelineModel } from "../../../../Model/project/ProjectModel";
import TimeLine from "./TimeLine";
import SegmentDetails from "./SegmentDetails";

// Define interfaces for your data models
interface CatBrandStyleSwatchModel {
  photo: string;
  // ... add other properties as needed
}

interface SelectedGroupModel {
  // ... add properties as needed
}

const ProjectDetails = () => {
  // State declarations
  const [show, setShow] = useState(false);
  const [selectedSwatch, setSelectedSwatch] =
    useState<CatBrandStyleSwatchModel | null>(null);
  const [palletUrl, setPalletUrl] = useState<string>("");
  // const [showTextarea, setShowTextarea] = useState(false);
   
  

  // Path variable declaration
  const path = process.env.REACT_APP_API_URL || ""; // Add your default path

  const handleClose = () => setShow(false);

  const handleShow = (data: CatBrandStyleSwatchModel) => {
    setShow(true);
    setSelectedSwatch(data);
    const allSelected: SelectedGroupModel[] = [];
    const swatchUrl = `${path}/${data.photo}`;
    setPalletUrl(swatchUrl);
  };

  // const handleEditClick = () => {
  //   setShowTextarea((prev) => !prev);
  // };

  const [showTextarea, setShowTextarea] = useState(false);
  const [instructions, setInstructions] = useState(""); // State to hold the instructions
  const [savedInstructions, setSavedInstructions] = useState(""); // State to hold saved instructions

  const handleEditClick = () => {
    setShowTextarea((prev) => !prev);
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInstructions(e.target.value); // Update instructions state
  };

  const handleSaveClick = () => {
    setSavedInstructions(instructions); // Save the instructions
    setShowTextarea(false); // Hide the textarea
  };

  const handleCancelClick = () => {
    setShowTextarea(false); // Hide the textarea
    setInstructions(""); // Clear the textarea
  };

  return (
    <>
      <div className="probar mb-3">
        <div
          className="progress rounded-0"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow={75}
          aria-valuemin={0}
          aria-valuemax={100}>
          <div className="progress-bar" style={{ width: '25%' }}>Progress 25%</div>
        </div>
      </div>

      <div className="pro-details-sec p-3">
      <div className="">
        <div className="seg-pro">
          <h5>Segments</h5>

          <div className="total-seg-sec d-flex flex-wrap align-items-center gap-1">

               <SegmentDetails/>
          </div>
        </div>

        <div className="Instru-sec">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h5>Instructions</h5>
          <span className="text-primary fw-semibold edit-instr-icon" onClick={handleEditClick}>
            <i className="bi bi-pencil-square"></i> Edit
          </span>
        </div>

        {showTextarea && ( // Conditionally render the textarea section
          <div className="seg-progress-details mb-3">
            <div className="mb-2">
              <textarea
                className="form-control"
                rows={3}
                placeholder="Enter your text here..."
                value={instructions} // Bind textarea value to state
                onChange={handleTextareaChange} // Update state on change
              ></textarea>
            </div>
            <div className="d-flex justify-content-between">
              <button className="btn border" onClick={handleCancelClick}>Cancel</button>
              <button className="btn btn-primary" onClick={handleSaveClick}>Save Instructions</button>
            </div>
          </div>
        )}

        {!showTextarea && savedInstructions && ( // Show saved instructions if available
          <div className="instru-text px-3">
            <p>{savedInstructions}</p> 
          </div>
        )}

        {!showTextarea && !savedInstructions && ( // Show default message if no instructions are saved
          <div className="instru-text px-3">
            <p>No specific instructions were added when this job was assigned</p>
          </div>
        )}
      </div>


        <div className="seg-assi-sec">
          <div className="d-flex justify-content-between mb-2">
            <h5>Assignment</h5>
            <span
              className="text-primary fw-semibold edit-icon"
              onClick={() =>
                handleShow({
                  photo: "default-photo.jpg", // Add default or required properties
                  // ... other required properties
                })
              }>
              <i className="bi bi-pencil-square"></i> Assign
            </span>
          </div>
          <div className="px-3">
            <div className="assign-user-details">
              <span className="assign-user">maildeepakrai@gmail.com</span>
              <p className="assign-text">Owner</p>
            </div>

            <div className="assign-user-details">
              <span className="assign-user">manishgupta@gmail.com</span>
              <p className="assign-text">Co-ordinator</p>
            </div>

            <div className="assign-user-details">
              <span className="assign-user">vijendrachoudhary@gmail.com</span>
              <p className="assign-text">Designer</p>
            </div>
          </div>
        </div>

        <div className='timeline-section'>
          <h5>Timeline</h5>
           <TimeLine/>
        </div>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="custom-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-center">Assign Job</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="assign-form">
            <div className="search-box mb-4">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search users..."
                  onChange={(e) => {
                    console.log(e.target.value);
                  }}
                />
                <span className="position-absolute top-50 end-0 translate-middle-y pe-3">
                  <i className="bi bi-search text-muted"></i>
                </span>
              </div>
            </div>

            <div className="mb-3">
              <h5>Labelers</h5>

              <div className="labelerAssignmentOption assign border rounded-1 mb-2">
                <div className="cursor-default d-flex">
                  <div className="avatarCircle user-avatar-circle rounded-circle bg-primary text-white">
                    <p className="m-0"> C </p>
                  </div>

                  <div className="userInfo px-3">
                    <span className="displayName fw-semibold">
                      vijendra jat{" "}
                    </span>
                    <p className="displayEmail ">mailto:vijendrajat693@gmail.com</p>
                  </div>
                </div>
              </div>

              <div className="labelerAssignmentOption assign border rounded-1 mb-2">
                <div className="cursor-default d-flex">
                  <div className="avatarCircle user-avatar-circle rounded-circle bg-primary text-white">
                    <p className="m-0"> C </p>
                  </div>

                  <div className="userInfo px-3">
                    <span className="displayName fw-semibold">
                      vijendra jat{" "}
                    </span>
                    <p className="displayEmail ">mailto:vijendrajat693@gmail.com</p>
                  </div>
                </div>
              </div>

              <div className="labelerAssignmentOption assign border rounded-1 mb-2">
                <div className="cursor-default d-flex">
                  <div className="avatarCircle user-avatar-circle rounded-circle bg-primary text-white">
                    <p className="m-0"> C </p>
                  </div>

                  <div className="userInfo px-3">
                    <span className="displayName fw-semibold">
                      vijendra jat{" "}
                    </span>
                    <p className="displayEmail ">mailto:vijendrajat693@gmail.com</p>
                  </div>
                </div>
              </div>

            </div>

            <div>
              <div className="inviteEmailFormContainer border rounded-1 p-2 mb-3">
                <span className="btn2 add-user-outer d-flex align-items-center justify-content-center ">
                <i className="bi bi-person-add mx-2"></i>
                  Invite 
                </span>
              </div>

              <div className="d-grid gap-2 border-top pt-4 ">
                <button className="btn btn-primary" type="button">
                  Assign Job
                </button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      </div>
    </>
  );
};

export default ProjectDetails;
