import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getRefinedScreenShot, resetRefinedScreenShot } from '../../../../slice/imageDepthSlice/ImageDepthSlice';
import userEvent from '@testing-library/user-event';
import { useDisclosure } from '@chakra-ui/react';
import { addMessage } from '../../../../slice/messageToast/ToastSlice';
import axios from 'axios';

const DownLoadRefinedScreenShot = () => {

 const getRefinedScreenShots=useSelector(getRefinedScreenShot)
 const isApi= useRef(true)
 const dispatch= useDispatch()
 useEffect(()=>{
    if(getRefinedScreenShots &&
        isApi.current
    ){
        isApi.current= false
        downloadImage(getRefinedScreenShots)
        dispatch(addMessage({
            isShow:true,
            mess:"Downloading proceed screenshot",
            toastType:"success"
            
           }))

    }
 },[getRefinedScreenShots])

 const downloadImage = async (imageUrl: string, fileName = "downloaded_image.png") => {
    try {
      const response = await fetch(imageUrl, { mode: "cors" });
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
  
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
  
      // Create an anchor element
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = fileName;
  
      // Append anchor to the body (not visible)
      document.body.appendChild(anchor);
  
      // Trigger the download
      anchor.click();
  
      // Remove the anchor from the DOM
      document.body.removeChild(anchor);
  
      // Revoke the URL to free memory
      URL.revokeObjectURL(url);
  
      dispatch(resetRefinedScreenShot());
      isApi.current = true;
    } catch (err) {
      if(axios.isAxiosError(err)){
            
        dispatch(addMessage({
            isShow:true,
            mess: err.response?.data.message,
            toastType:"Error"
           }))
    }
      //console.error("Error downloading the image:", error);
    }
  };
  
      
      
  return (
    <></>
  )
}

export default DownLoadRefinedScreenShot