import React from "react";
import Header from "../header/Header";
import "./ShareProjectPage.scss";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
// import socialicon1 from "../../../public/img/linkedin.svg"
// import socialicon2 from "../../../public/img/facebook.svg"
// import socialicon3 from "../../../public/img/whatsapp.svg"
// import socialicon4 from "../../../public/img/linkedin.svg"
// import socialicon5 from "../../../public/img/social-icon.svg"

const socialicon1 = process.env.PUBLIC_URL + "/img/linkedin.svg";
const socialicon2 = process.env.PUBLIC_URL + "/img/whatsapp.svg";
const socialicon3 = process.env.PUBLIC_URL + "/img/social-icon.svg";
const socialicon4 = process.env.PUBLIC_URL + "/img/facebook.svg";
const socialicon5 = process.env.PUBLIC_URL + "/img/twitter.svg";

const ShareProjectPage = () => {
  return (
    <div>
      <Header />

      <div className="sharepage-section">
        <div className="container">
          <h3 className="text-center pb-3">
            Dzinly - Instant Virtual Staging And More
          </h3>
          <div className="row">
            <div className="compare-image gen-ai-img col-8">
              <ImgComparisonSlider className="comapare-img rounded-2">
                <figure slot="first" className="before">
                  <img
                    className="w-100 rounded-2"
                    src="https://betadzinly.s3.us-east-2.amazonaws.com/projects/841/image_841_original.jpg"
                    alt="Before"
                  />
                  <figcaption>Before</figcaption>
                </figure>
                <figure slot="second" className="after">
                  <img
                    className="w-100"
                    src="https://betadzinly.s3.us-east-2.amazonaws.com/projects/841/image_841_original.jpg"
                    alt="After"
                  />
                  <figcaption>After</figcaption>
                </figure>
              </ImgComparisonSlider>
            </div>

            <div className="col-4">
              <h6 className="gen-headline">Inputs used to generate the design</h6>
              <div className="gap-3 d-flex btn-gen">
                 <span>Wall</span>
                 <span>Window</span>

              </div>
              <div className="share-img ">
                <h6>Share</h6>
                <div className="d-flex align-items-center justify-content-between">
                    <div  className="d-flex gap-2 social-icon">
                  <img src={socialicon1} alt="LinkedIn"></img>
                  <img src={socialicon2}></img>
                  <img src={socialicon3}></img>
                  <img src={socialicon4}></img>
                  <img src={socialicon5}></img>
                  </div>
               
                  <button className="btn btn-primary border">
                    Copy URL
                  </button>
                </div>
              </div>
              <div className="d-grid">
                <span className="py-4">
                  Are you interested in redesigning your space?
                </span>
                <button className="btn btn-primary ">Visit Dzinly Ai</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareProjectPage;
