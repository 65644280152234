import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Input, InputRef, Table, TableColumnsType } from 'antd';
import Search from 'antd/es/transfer/search';
import { MaterialCategoryModel } from '../../../Model/material/MaterialCategoryModel';
import { getMaterialCategorys, addEditcategory } from '../../../slice/materialSlice/MaterialCategorySlice';
import { getLoading, stopLoading } from '../../../slice/loading/LoadingSlice';
import DelteCategoryApi from '../../admin/AdminMaterial/category/api/Delete CategoryApi';
import CategoryCreateForm from './CategoryCreateFrom';
import Loading from '../../loading/Loading';
import "./CategoryTable.css";
import DataTable from 'react-data-table-component';

const CategoryTable: React.FC = () => {
  const [allCategory, setAllCategory] = useState<MaterialCategoryModel[]>([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getLoadings = useSelector(getLoading);
  const getMaterialCategory = useSelector(getMaterialCategorys) as MaterialCategoryModel[];
  const [isEditProjectForm, setIsProEditForm] = useState(false);
  const navigate = useNavigate();
  const searchInput = useRef<InputRef>(null);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (getMaterialCategory && getMaterialCategory.length > 0) {
      setAllCategory(getMaterialCategory);
    }
  }, [getMaterialCategory]);

  useEffect(() => {
    setIsLoading(getLoadings);
  }, [getLoadings]);

  const handleEditCategory = (record: MaterialCategoryModel) => {
    dispatch(addEditcategory(record));
    navigate(`/dashboard/swatch-categories/${record.id}/edit`);
  };

  const handleDelete = (record: MaterialCategoryModel) => {
    setIsDelete(true);
    setDeleteId(record.id ?? 0);
  };

  const handleResetDelete = (mess: string) => {
    setIsDelete(false);
    setDeleteId(0);
    dispatch(stopLoading());
    alert(mess === "success" ? "Category deleted successfully" : "Error in deleting category");
  };

 const columns = [
    {
      name: "Id",
       selector: (row: MaterialCategoryModel) => row.id??0, 
      sortable: true,
    },
    {
      name: "Title",
      selector: (row: MaterialCategoryModel) => row.title??"",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row: MaterialCategoryModel) => row.description??""
    },
    {
      name: "Slug",
      selector: (row: MaterialCategoryModel) => row.slug??""
    },
    {
      name: "Sort Order",
      selector: (row: MaterialCategoryModel) => row.sort_order??0
    },
    {
      name: "Status",
      selector: (row: MaterialCategoryModel) => row.status??""
    },
    {
      name: "Action",
      cell: (row: MaterialCategoryModel) => (
        <>
<div className="d-flex action">
                  <div className="action-icon-table" 
                  onClick={() => handleEditCategory(row)}
                  >
                    <i className="bi bi-pencil-square mx-0"></i>
                  </div>
                  <div className="action-icon-table">
                    <i className="bi bi-copy mx-0"></i>
                  </div>
                  <div className="action-icon-table">
                    <i className="bi bi-box-arrow-up-right mx-0"></i>
                  </div>
                  <div className="action-icon-table trash-icon-media"
                   onClick={() => handleDelete(row)}
                   >
                    <i className="bi bi-trash3 mx-0"></i>
                  </div>
                </div>
                </>)
      }, 
    
  ];

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <>

      <div className='card mx-3'>
        <div className='card-header bg-white p-2 d-flex justify-content-between align-items-center'>
          <input
            type="search"
            className="form-control w-auto"
            placeholder="Search"
          />
          <button type='button' className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#exampleModal">Create new entry</button>
        </div>
        
        <div className='card-body p-0'>
          {isLoading && <Loading />}
          <DataTable 
            columns={columns} 
            
            data={allCategory}
            pagination
            fixedHeader
          />
          {/* <Card className="mb-5 card-table-category">
            {allCategory.length === 0 ? (
              <Loading />
            ) : (
              <Table
                columns={columns}
                dataSource={allCategory.map((item) => ({ ...item, key: item.id }))}
                pagination={{
                  position: ['topRight', 'bottomRight'], // Positions pagination at the top and bottom
                  defaultPageSize: 200,
                  showTotal: (total) => <span className="ant-pagination-total-text">Total <span className='total-page'><i>{total}</i></span> items</span>,
                  showSizeChanger: true, // Show page size changer
                  pageSizeOptions: ['100', '200', '300', '400', '500', '600', '1000'], // Page size options
                }}
                onChange={handleTableChange}
              />
            )}
          </Card> */}
          {isDelete && deleteId !== 0 && (
            <DelteCategoryApi deleteId={deleteId} resetDelete={handleResetDelete} />
          )}

        </div>
      </div>
      <div>
        <div className=" brand-title">
          <p>{allCategory.length} entries Found</p>
        </div>
      </div>



    </>
  );
};

export default CategoryTable;
