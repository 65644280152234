import React, { useEffect, useState } from 'react'
import SignUpForm from './SignUpForm'
import { userModel } from '../../../Model/user/UserModel'
import SignUpCallApi from './SignUpCallApi'
import { Prev } from 'react-bootstrap/esm/PageItem'
import AuthApi from '../Login/AuthApi'
import { getUserLogin } from '../../../slice/user/UserSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getLoading, stopLoading } from '../../../slice/loading/LoadingSlice'
import { useNavigate } from 'react-router-dom'
import Loading from '../../loading/Loading'
import Header from '../../header/Header'

const SignUpHome = () => {

    const dispatch= useDispatch()
  const navigator= useNavigate()
    const[isApiCall, setIsApiCall]= useState<boolean>(false)
    const[isSignUp, setIsSignUp]= useState<boolean>(false)
    const[isLogin, setIsLogin]= useState<boolean>(false)
    const[userData, setIsUserData]= useState<userModel|null>()
    const [loginForm, setLoginForm] = useState({
        email: '',
        password: ''
      })

      const getLoadings= useSelector(getLoading)
      const [isLoading, setisLoading]= useState<boolean>(false)
      useEffect(()=>{
        console.log("getLoadings",getLoadings)
        if(getLoadings){
          setisLoading(true)
        }
      },[getLoadings])

      
    useEffect(()=>{
        setIsSignUp(true)
    },[])
    const handleCreateAccount=(data:userModel)=>{
        setIsUserData(data)
        setIsApiCall(true)
    }

    const handleResetSignUp=()=>{
       setIsApiCall(false)
      
      setIsSignUp(false)
      setIsLogin(true)
      setLoginForm({...Prev,
        email:userData?.email??"",
        password:userData?.password??""
      })
      setIsUserData(null)
    }

    const handleResetAuth = () => {
        setIsLogin(false)
        setLoginForm({
          email: '',
          password: ''
        })
      }

      const getUserLogins= useSelector(getUserLogin) as userModel[]
      // console.log("getUserLogins",getUserLogins)
      useEffect(()=>{
        if(getUserLogins && getUserLogins.length>0  ){
          
          if(getUserLogins[0].role){
            dispatch(stopLoading())
            navigator(`/${getUserLogins[0].role}/project`)
          }
        }
      },[getUserLogins])
  return (
    <>

<Header/>
{isLoading && <Loading/>}

    {/*  sign up form */}
   { isSignUp &&
   <SignUpForm
    registerAccount={handleCreateAccount}
    />}

    {/* call Api to update data */}

   {isApiCall &&  
   userData &&
    <SignUpCallApi
    userData={userData}
    resetSignUp={handleResetSignUp}
    
    />}
    {/* login */}
    {isLogin && (
                  <AuthApi loginForm={loginForm}
                   resetAuth={handleResetAuth} />
                )}

    </>
  )
}

export default SignUpHome