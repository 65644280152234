import React from 'react'
import AdminStyleTable from '../../../module/admin/AdminMaterial/style/AdminStyleTable'
import AdminCategoryTable from '../../../module/admin/AdminMaterial/category/AdminCategoryTable'

const AdminCategoryTablePage = () => {

  console.log("hello category")
  return (
    <div  className="mt-5 pt-4">

       <AdminCategoryTable/>
    </div>
  )
}

export default AdminCategoryTablePage