import React, { useState } from "react";
import { userModel } from "../../../Model/user/UserModel";
import { useNavigate } from "react-router-dom";
import "./SignUpForm.scss";
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Card,
  IconButton,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
const AssetImage = process.env.REACT_APP_ASSERT_Image
const logoicon = `${AssetImage}/images/logo-icon.svg`

type Props = {
  registerAccount: (data: userModel) => void;
};

const SignUpForm = ({ registerAccount }: Props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.password ||
      !formData.confirmPassword ||
      !formData.role
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    setErrorMessage("");
    const data: userModel = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      role: formData.role,
    };
    setFormData({
      name: "",
      email: "",
      password: "",
      role: "",
      confirmPassword: "",
    });
    registerAccount(data);
  };

  const handleLogin = () => {
    navigate("/auth/login");
  };

  return (
    
    <div className="container-fluid h-100 g-0" style={{ marginTop: 20 }}>
      <div className="row h-100 g-0">
        <div className="col-12 col-md-8 h-100">
          {/* <Shimmer_Images/>  */}
          <img
            className="loginimg"
            src={`${process.env.PUBLIC_URL}/img/login-img.jpg`}
            alt="loginimg"
          />
        </div>
        <div className="col-12 col-md-4">
          <div className="d-flex align-items-center justify-content-center h-100 create-account">
            <Card className="login-box">
              <div className="Container container-form login-details-from">
                <form onSubmit={handleSubmit}>

                <img
                    width={40}
                    className='logoicon'
                    src={logoicon}
                    alt='logoicon'
                  />
                <h4 className='mt-3 text-center mb-4'>Create Account</h4>
                  <FormControl className="form-box email-from mb-3">
                    <FormLabel className="form-label form-email">
                      Name
                    </FormLabel>

                    <input
                      className="Input form-control"
                      type="text"
                      placeholder="Name"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </FormControl>

                  <FormControl className="form-box email-from mb-3">
                    <FormLabel className="form-label form-email">
                      Email
                    </FormLabel>

                    <Input
                       className="Input form-control"
                      type="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </FormControl>

                  <FormControl className="login-fromlabel mb-3" isRequired>
                    <FormLabel className="form-label">Password</FormLabel>

                    <Input
                      className="Input form-control"
                      type="password"
                      placeholder="Password"
                      value={formData.password}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                    />
                  </FormControl>

                  <FormControl className="login-fromlabel mb-3" isRequired>
                    <FormLabel className="form-label">
                      Confirm Password
                    </FormLabel>

                    <Input
                      className="Input form-control"
                      type="password"
                      placeholder="Confirm Password"
                      value={formData.confirmPassword}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          confirmPassword: e.target.value,
                        });
                        if (formData.password !== e.target.value) {
                          setErrorMessage("Passwords do not match.");
                        } else {
                          setErrorMessage("");
                        }
                      }}
                    />
                  </FormControl>

                  <FormControl className="login-fromlabel mb-3">
                  <FormLabel className="form-label">
                  Role
                    </FormLabel>
                  <select
                    className="form-select"
                    value={formData.role}
                    onChange={(e) =>
                      setFormData({ ...formData, role: e.target.value })
                    }>
                    <option value="">Select a role</option>
                    <option value="admin">Admin</option>
                    <option value="customer">Customer</option>
                    <option value="designer">Designer</option>
                    <option value="manufacturer">Manufacturer</option>
                  </select>
                    </FormControl>


                    <div className="d-flex justify-content-center">
                <button
                  className=' mx-2 btn btn-primary rounded-pill Button form-btn sub-button px-4'
                  type="button"
                  onClick={handleSubmit}>
                  Submit
                </button>
                <button  className='btn btn-transprant  border rounded-pill Button form-btn sub-button px-4' type="button">
                  Cancel
                </button>
                </div>
                <p className="text-center mt-2">
                  If you already have an account,{" "}
                  <span onClick={handleLogin}>sign in</span>
                </p>

                </form>
              </div>
            </Card>

            {/* <div className="sign-up-form">
              <h2>Create Account</h2>
              <div>
                <label>
                  Name
                  <input
                    className="form-input"
                    type="text"
                    placeholder="Name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </label>

                <label>
                  Email
                  <input
                    className="form-input"
                    type="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </label>
                <label>
                  Password
                  <input
                    className="form-input"
                    type="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                </label>

                <label>
                  Confirm Password
                  <input
                    className="form-input"
                    type="password"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        confirmPassword: e.target.value,
                      });
                      if (formData.password !== e.target.value) {
                        setErrorMessage("Passwords do not match.");
                      } else {
                        setErrorMessage("");
                      }
                    }}
                  />
                </label>
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
                <label>
                  Role
                  <select
                    className="form-select"
                    value={formData.role}
                    onChange={(e) =>
                      setFormData({ ...formData, role: e.target.value })
                    }>
                    <option value="">Select a role</option>
                    <option value="admin">Admin</option>
                    <option value="customer">Customer</option>
                    <option value="designer">Designer</option>
                    <option value="manufacturer">Manufacturer</option>
                  </select>
                </label>

              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default SignUpForm;
