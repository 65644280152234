import React, { useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux';

import { GetJobsAll } from '../../../../api/jobs/JobApi';
import { addAllJobData, addJobDocument, getAllJobData } from '../../../../slice/Admin/JobSlice/JobSlice';

type Props={
  page:number,
  limit:number,
  resetAllJobs:()=>void
}
const GetAllJobs = ({page,limit,resetAllJobs}:Props) => {
   const dispatch= useDispatch();
   const getAllJobDatas= useSelector(getAllJobData)
   const isApi= useRef(true)
   useEffect(()=>{
    if(page &&
      limit &&
      isApi.current
    ){
      isApi.current= false
        getJobs(page, limit)
    }
   },[page])

    const getJobs=async(page:number, limit:number)=>{

        try{
            
      const response = await GetJobsAll(page, limit);
      console.log("get all jobs", response)
      if(response && response.status===200){
          if(response.data.jobs.length>0){
            dispatch(addAllJobData({
              job:response.data.jobs,
              response:"get job data"
             }))
          }else{
            dispatch(addAllJobData({
              job:response.data.jobs,
              response:"No jobs"
             }))
          }
          dispatch(addJobDocument({
            totalDocuments:response.data.totalJobs,
            totalPages:response.data.totalPages ,
             currentPage:response.data.currentPage,
             pageSize:response.data.pageSize
          }))
          isApi.current= true
          resetAllJobs()
      }
        }catch(err){
            alert("Error in getting job ")
          isApi.current= true
          resetAllJobs()
        }
    }
  return (
    <>
        
    </>
  )
}

export default GetAllJobs