import React, { useEffect, useRef } from 'react'
import { GalleryModel, GenAiImageModel } from '../../../../Model/genAiAttributes/GenAiImages'
import { addGenAiImages } from '../../../../api/genAi/GenAiAPi'
import { getProjectGenAiImage, resetProjectGenAiImagesValue, updateStartUploadGenAi } from '../../../../slice/genAi/GenAiAttributesSlice'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'

type Props={

}
const CallApiToUpload = () => {
    const isApi= useRef(true)
    const getProjectGenAiImages= useSelector(getProjectGenAiImage)

    const dispatch= useDispatch()

    useEffect(()=>{
        
        if(getProjectGenAiImages && 
            getProjectGenAiImages.gallery && 
            getProjectGenAiImages.gallery.length>0 &&
           isApi.current
        ){
           console.log("under ",getProjectGenAiImages)
            
            let newGallaryItem:GalleryModel[]=[]
            getProjectGenAiImages.gallery.map(item=>{
                
              if(item.isnew){
                const gal={
                    image_name:item.image_name ,
                    image_path:item.image_path,
                    prompt:item.prompt , 
                }
                newGallaryItem.push(gal)
              }
            })
             //console.log("newGallaryItem",newGallaryItem)
                if(newGallaryItem.length>0){
                   const genAi={
                    job_id:getProjectGenAiImages.job_id ,
                    project_id:getProjectGenAiImages.project_id,
                    gallery:newGallaryItem
                   }
            isApi.current= false

                   addGenAiImagesDB(genAi)
                }
              
            }
    },[getProjectGenAiImages])

    
    const addGenAiImagesDB=async(data:GenAiImageModel)=>{
        
        try{
      const response= await addGenAiImages(data)
         if(response && response.status==200){
            console.log("gem Ai imges Db", response)
            isApi.current= true
            dispatch(updateStartUploadGenAi(false))
            dispatch(resetProjectGenAiImagesValue())
            
         }
        }catch(err){
            //console.log("Error in updating genAi images")
            if(axios.isAxiosError(err)){
            
              dispatch(addMessage({
                  isShow:true,
                  mess: err.response?.data.message,
                  toastType:"Error"
                 }))
          } 
            isApi.current= true
            dispatch(updateStartUploadGenAi(false))
          

        }
    }
  return (
    <></>
  )
}

export default CallApiToUpload