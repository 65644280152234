import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobModel } from "../../../Model/Job/JobModel";
import { BrandDocModel } from "../../../Model/material/MaterialBrandModel";


interface JobStates {
    isJobSearch:boolean
    jobResponse:string,
    jobs:JobModel[],
    jobDocumnet:BrandDocModel
   
    
}

const initialState:JobStates = {
   jobs:[],
   jobResponse:"",
   isJobSearch:false,
   jobDocumnet:{}
};
const JobSlice = createSlice({
    name: "jobs",
    initialState,
    reducers: {
        resetJobSlice:(state)=>{
            return initialState
        }

        ,
        addJobSearch:(state,action)=>{
            state.isJobSearch= action.payload
        },
        addAllJobData:(state,action)=>{
            const {job, response}=action.payload
            state.jobs=job
            state.jobResponse=response
        },
        addJobDocument:(state,action)=>{
            const {totalDocuments,totalPages , currentPage,pageSize}=action.payload
            state.jobDocumnet.totalDocuments=totalDocuments
            state.jobDocumnet.totalPages=totalPages
            state.jobDocumnet.currentPage=currentPage
            state.jobDocumnet.pageSize=pageSize
        }
        , 
        
        resetJobs:(state)=>{
            state.jobs=[]
        },
        

    }
}
)

export const {
    resetJobSlice,
    addAllJobData,
    resetJobs,
    addJobDocument,
    addJobSearch
}=JobSlice.actions;
export const getAllJobData=  (state:{jobs:JobStates})=>state.jobs.jobs
export const getIsSearchJob=  (state:{jobs:JobStates})=>state.jobs.isJobSearch
export const getJobResponse=  (state:{jobs:JobStates})=>state.jobs.jobResponse
export default JobSlice.reducer