import React from 'react'
import AdminUser from '../../module/admin/AdminUser/AdminUser'

const UserTablePage = () => {
  return (
    <>
      <AdminUser/>
    </>
  )
}

export default UserTablePage
