import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getIsFilter, getSearchBrandIds, getSearchCatId, updateIsFilter } from '../../../../../slice/materialSlice/SearchMaterial';
import CategorySearch from './categorySearch/CategorySearch';
import { getGroupName } from '../../../../../slice/toolActive/ToolActiveSlice';
import { getAllSegment } from '../../../../../slice/segment/SegmentSlice';
import { getCategoryName } from '../../../../../slice/materialSlice/MaterialCategorySlice';
import { NameModel } from '../../../../../Model/material/MaterialModel';
import SearchBrand from '../../../../brandPage/swatch/searchSwatchs/searchBrand/SearchBrand';
import BrandSearch from './searchBrand/BrandSearch';
import SearchStyleBrandBased from '../../../../brandPage/swatch/searchSwatchs/searchStyle/SearchStyleBrandBased';
import StyleSearch from './searchStyle/StyleSearch';

const SearchSection = () => {
    const [showOffcanvas, setShowOffcanvas] = useState(false); 
    const [allSegCategory, setAllSegCategory]= useState<NameModel[]>([])
    const [isCategoryOpen, setIsCategoryOpen] = useState(false); 
    const [isBrandOpen, setIsBrandOpen] = useState(false); 
    const [isStyleOpen, setIsStyleOpen] = useState(false); 
    const [isSearchBrand, setIsSearchBrand] = useState(false); 
    const [isSearchStyle, setIsSearchStyle] = useState(false); 
    const isCatOpen= useRef(true)
    const isBrdOpen= useRef(true)

     const dispatch= useDispatch()
     const getIsFilters= useSelector(getIsFilter)
     const getGroupNames= useSelector(getGroupName)
     const getCategoryNames= useSelector(getCategoryName )
     
     const getSearchBrandIdss= useSelector(getSearchBrandIds )
     
     const getSearchCatIds = useSelector(getSearchCatId)
     const getAllSegments= useSelector(getAllSegment)
    
        console.log("getSearchBrandIdss",getSearchBrandIdss)
     useEffect(()=>{ 
      if(getIsFilters){
        setShowOffcanvas(getIsFilters)
      }else{
        setShowOffcanvas(false)
      }

     },[getIsFilters])


     const handleCloseFilter=()=>{
      dispatch(updateIsFilter(false))
     }


   
      
   
      useEffect(()=>{
       if(getAllSegments && 
         getAllSegments.length>0 &&
         getGroupNames &&
         getCategoryNames && getCategoryNames.length>0
       ){
    
          const getSelectedsegment= getAllSegments.find(item=>
             item.name==getGroupNames
          )
          if(getSelectedsegment &&
              getSelectedsegment.categories &&
              getSelectedsegment.categories.length>0
   
             ){
                let selectedCat:NameModel[]=[]
                 const allcat=  getSelectedsegment.categories;
                 if (Array.isArray(allcat)) {
                   allcat.forEach(category => {
                    const cat= getCategoryNames.find(item=>item.title==category) 
                  
                     selectedCat.push(cat??{})
                   });
               } 
               if(selectedCat.length>0){
                 setAllSegCategory(selectedCat)
               }
              
       }
     }
   },[getAllSegments,getGroupNames,getCategoryNames])


   // open category Accordian
  
  //  useEffect(()=>{
  //      if(isCatOpen.current &&
  //       allSegCategory &&
  //       allSegCategory.length>0
  //      ){
  //       console.log("allSegCategory",allSegCategory)
  //       isCatOpen.current= false
  //       setIsCategoryOpen(true)
  //      }
  //  },[allSegCategory])


   // search brand
      //  useEffect(() => {
      //    if(getSearchCatIds!=0 ){
      //     setIsSearchBrand(true)
      //     isBrdOpen.current= false
      //     setIsCategoryOpen(false)
      //     setIsBrandOpen(true)
      //    }
       
        
      //  }, [getSearchCatIds])
     
      // open search brand start
      const handleSearchBrand=()=>
        setIsSearchBrand(true)

      // close brand search
      const handleResetSearchBrand=()=>{
        setIsSearchBrand(false)
      }


      // start searcb style
      useEffect(() => {
        if(getSearchBrandIdss && 
          getSearchBrandIdss.length>0){
            setIsSearchStyle(true)
          }
      
        
      }, [getSearchBrandIdss])
      
      const handleResetStyle=()=>{
        setIsSearchStyle(false)
      }
       
  return (
    <>
     <div className={`offcanvas offcanvas-start ${showOffcanvas ? 'show' : ''}`} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">Filters</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
           onClick={handleCloseFilter}
          ></button>
        </div>
        <div className="offcanvas-body p-0">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button bg-white collapsed" type="button"
                 data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isCategoryOpen}
                 aria-controls="flush-collapseOne"
                 onClick={() => setIsCategoryOpen(!isCategoryOpen)}
                 >
                  Category
                </button>
              </h2>
              <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  {/* category accordian */}
                   <CategorySearch
                     allSegCategory={allSegCategory}
                     startSearchBrand={handleSearchBrand}
                   />
                  </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button bg-white collapsed" type="button" 
                data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                 aria-expanded={isBrandOpen} aria-controls="flush-collapseTwo"
                 onClick={() => setIsBrandOpen(!isBrandOpen)}
                 >
                  Brand
                </button>
              </h2>
              <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">

                      <BrandSearch/>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button bg-white collapsed" type="button" 
                data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" 
                aria-expanded={isStyleOpen} aria-controls="flush-collapseThree"
                onClick={() => setIsStyleOpen(!isStyleOpen)}
                >
                  Style
                </button>
              </h2>
              <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                   <StyleSearch/>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* search brand based on category */}

     {isSearchBrand &&
      <SearchBrand
     catId={getSearchCatIds}
     resetSearch={handleResetSearchBrand}
      />}

      {/* search style */}

    {isSearchStyle &&
    getSearchBrandIdss &&
     getSearchBrandIdss.length>0 &&
     < SearchStyleBrandBased
    brandId={getSearchBrandIdss}
    resetSearchStyle={handleResetStyle}
    />}
    </>
  )
}

export default SearchSection