import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSearchStyleName } from '../../../../../../slice/materialSlice/MaterialStyleSlice'


const s3path= process.env.REACT_APP_S3BucketMaterial

const StyleSearch = () => {


    const dispatch= useDispatch()
    const path =`${s3path}/liv/materials`;
   const getSearchStyleNames= useSelector(getSearchStyleName)
    const handleOpenStyle=()=>{
       // dispatch(updateIsStyleModal(true))
    }
  return (
    <>
     <div className="row g-2">
     {getSearchStyleNames && 
     getSearchStyleNames.length==0?(
      <span>No style</span>
     ): (
      getSearchStyleNames.map((item, index)=>{
        return(
          <div className="col-4" key={`${item.id}-${index}`}>
          <div className="palletsbox ">
            <div>
            <img src={`${path}/${item?.photo}`} alt="swatch" 
               // onClick={() => handleSwatch(item)}
                />
             
          
            
            <svg
              className="checkicon md"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              ></circle>
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              ></path>
            </svg>
            </div>
          
    
            
            <span
              role="button"
              className="pallets-info"
             // onClick={() => handleShow(item)} // Show Offcanvas
            >
              <i className="bi bi-info"></i>
            </span>
          </div>
          
        </div>
        )
      })
     
     )
      
     }
     </div>
    </>
  )
}

export default StyleSearch