import React from 'react'

import ReAnnotationCanvas from './reAnnotationRight/ReAnnotationCanvas'

import ReAnnoatationLeft from './reAnnoatationLeft/ReAnnoatationLeft'
import RefreshCanvas from '../../../Refresh/RefreshCanvas'
import UpdateJobHome from '../../ImageView/updateJob/UpdateJobHome'



const ReAnnotationCanvasHome = () => {
  return (
<>
<div className='editor-wrapper'>
            <div className="editor-toolbar-left">
                <div className="canvas-left-cat h-100">
                    {/* <LeftSections/> */}
                    <ReAnnoatationLeft/>
                </div>
            </div>
            <div
             className="editor-canvas"
            >
                <ReAnnotationCanvas/>
            </div>
        </div>
        <RefreshCanvas/>

{/* update the job after change Annotation [into Db] */}
        <UpdateJobHome/>
</>
  )
}

export default ReAnnotationCanvasHome