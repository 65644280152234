import axios from 'axios'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { updateBase64 } from '../../../slice/userJobSlice/UserJobSlice'
import { GetBase64Image } from '../../../api/modelApi/ModelApi'
import { startLoading, stopLoading } from '../../../slice/loading/LoadingSlice'
import { addApiMessage, addMessage } from '../../../slice/messageToast/ToastSlice'
import { error } from 'console'
const backendUrl =process.env.REACT_APP_BACKEND_URL
 const urlpath= process.env.REACT_APP_Project_Image
type Props={
    url:string, 
    projectId:number
    resetCallApi:(data:boolean)=> void
}
const GetBase64FromApi = ({url,projectId,resetCallApi}:Props) => {
    const isApi= useRef(true)
    const dispatch= useDispatch()

    useEffect(()=>{
        if(url && 
            isApi.current && 
            projectId){
                isApi.current=false
                dispatch(addApiMessage("Fetching image"))
            callAIModel(url,projectId)  
        }
    },[url,projectId])
    const callAIModel=  async (url:string, projectId:number)=>{
     const imagepath=`${urlpath}/${projectId}/${url}`
    try{
   const response= await GetBase64Image(imagepath);
     console.log("base 64 response",response)
     if(response && response.status==200){
      
      dispatch(updateBase64({
        base64:response.data.img_base64}))
      isApi.current=true
      resetCallApi(true)
      dispatch(stopLoading())
     }
     
    }catch(err:unknown){
        // alert("Error in model Api")
       // console.log("base 64 error", err)
        dispatch(addMessage({
          isShow:true,
          mess:"Error in model Api",
           toastType:"Error"
         }))
        dispatch(stopLoading())
        resetCallApi(false)
    }
}
  return (
    <div>
        
    </div>
  )
}

export default GetBase64FromApi

// dispatch(updateBase64(modelData.data.Base64))